import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import "bootstrap/dist/css/bootstrap.min.css";

// Authentication routes
import SignIn from "./pages/authentication/Signin";
import Signup from "./pages/authentication/Signup";
import ForgotPassword from "./pages/authentication/ForgotPassword";
import Otp from "./pages/authentication/Otp";
import AddNewCompany from "./pages/admin/AddNewCompany";

// Dashboards
import AdminDashboard from "./pages/AdminDashboard";
import ResourceDashboard from "./pages/ResourceDashboard";

// Admin Components
import SideDrawer from "./components/ui/SideDrawer";

// Admin Routes
import TimeSpent from "./pages/admin/TimeSpent";
import Performance from "./pages/admin/Performance";
import ShowResources from "./pages/admin/ShowResources";
import AddMatter from "./pages/admin/AddMatter";
import EditMatter from "./pages/admin/EditMatter";
import ShowMatters from "./pages/admin/ShowMatter";
import ShowClient from "./pages/admin/ShowClient";
import WriteOff from "./pages/admin/WriteOff";
import Invoice from "./pages/admin/Invoice";
import Leave from "./pages/admin/Leave";
import TimespentTracker from "./pages/admin/TimespentTracker";
import Ope from "./pages/admin/Ope";
import Opex from "./pages/admin/Opex";
import Financial from "./pages/admin/Financial";

// Resource Routes
import AddTime from "./pages/resource/AddTime";
import ExportExcelResource from "./pages/resource/ExportExcel";
import Calendar from "./pages/resource/Calendar";
import ResourceOPE from "./pages/resource/ResourceOPE";
import ResourceLeave from "./pages/resource/ResourceLeave";
import "./index.css";

//Other Routes
import Protected from "./components/Protected";
import NotFound from "./components/NotFound";

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<SignIn />} />
          <Route path="/admin/signup" element={<Signup />} />
          <Route path="/otp" element={<Otp />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />

          {/* Admin Routes with Layout */}
          <Route
            path="/adminDashboard"
            element={
              <Protected allowedRoles={["admin"]}>
                <SideDrawer>
                  <AdminDashboard />
                </SideDrawer>
              </Protected>
            }
          />

          <Route
            path="/admin/addnewcompany"
            element={
              <Protected allowedRoles={["admin"]}>
                <SideDrawer>
                  <AddNewCompany />
                </SideDrawer>
              </Protected>
            }
          />
          <Route
            path="/admin/reports/timespent"
            element={
              <Protected allowedRoles={["admin"]}>
                <SideDrawer>
                  <TimeSpent />
                </SideDrawer>
              </Protected>
            }
          />
          <Route
            path="/admin/reports/performance"
            element={
              <Protected allowedRoles={["admin"]}>
                <SideDrawer>
                  <Performance />
                </SideDrawer>
              </Protected>
            }
          />
          <Route
            path="/admin/reports/financial"
            element={
              <Protected allowedRoles={["admin"]}>
                <SideDrawer>
                  <Financial />
                </SideDrawer>
              </Protected>
            }
          />
          <Route
            path="/admin/reports/invoice"
            element={
              <Protected allowedRoles={["admin"]}>
                <SideDrawer>
                  <Invoice />
                </SideDrawer>
              </Protected>
            }
          />
          <Route
            path="/admin/reports/writeOff"
            element={
              <Protected allowedRoles={["admin"]}>
                <SideDrawer>
                  <WriteOff />
                </SideDrawer>
              </Protected>
            }
          />
          <Route
            path="/admin/reports/tstracker"
            element={
              <Protected allowedRoles={["admin"]}>
                <SideDrawer>
                  <TimespentTracker />
                </SideDrawer>
              </Protected>
            }
          />
          <Route
            path="/admin/reports/leave"
            element={
              <Protected allowedRoles={["admin"]}>
                <SideDrawer>
                  <Leave />
                </SideDrawer>
              </Protected>
            }
          />
          <Route
            path="/admin/reports/opex"
            element={
              <Protected allowedRoles={["admin"]}>
                <SideDrawer>
                  <Opex />
                </SideDrawer>
              </Protected>
            }
          />
          <Route
            path="/admin/reports/ope"
            element={
              <Protected allowedRoles={["admin"]}>
                <SideDrawer>
                  <Ope />
                </SideDrawer>
              </Protected>
            }
          />
          <Route
            path="/admin/allresources"
            element={
              <Protected allowedRoles={["admin"]}>
                <SideDrawer>
                  <ShowResources />
                </SideDrawer>
              </Protected>
            }
          />
          <Route
            path="/admin/addmatter"
            element={
              <Protected allowedRoles={["admin"]}>
                <SideDrawer>
                  <AddMatter />
                </SideDrawer>
              </Protected>
            }
          />
          <Route
            path="/admin/editmatter"
            element={
              <Protected allowedRoles={["admin"]}>
                <SideDrawer>
                  <EditMatter />
                </SideDrawer>
              </Protected>
            }
          />
          <Route
            path="/admin/showmatters"
            element={
              <Protected allowedRoles={["admin"]}>
                <SideDrawer>
                  <ShowMatters />
                </SideDrawer>
              </Protected>
            }
          />
          <Route
            path="/admin/showclient"
            element={
              <Protected allowedRoles={["admin"]}>
                <SideDrawer>
                  <ShowClient />
                </SideDrawer>
              </Protected>
            }
          />
          <Route
            path="/admin/showclient"
            element={
              <Protected allowedRoles={["admin"]}>
                <SideDrawer>
                  <ShowClient />
                </SideDrawer>
              </Protected>
            }
          />

          {/* Resource Routes */}
          <Route
            path="/resourceDashboard"
            element={
              <Protected allowedRoles={["employee"]}>
                {/* <SideDrawer> */}
                  <ResourceDashboard />
                {/* </SideDrawer> */}
              </Protected>
            }
          />
          <Route
            path="/resource/addtime"
            element={
              <Protected allowedRoles={["employee"]}>
                {/* <SideDrawer> */}
                  <AddTime />
                {/* </SideDrawer> */}
              </Protected>
            }
          />
          <Route
            path="/resource/calendar"
            element={
              <Protected allowedRoles={["employee"]}>
                {/* <SideDrawer> */}
                  <Calendar />
                {/* </SideDrawer> */}
              </Protected>
            }
          />

          <Route
            path="/resource/exportexcel"
            element={
              <Protected allowedRoles={["employee"]}>
                {/* <SideDrawer> */}
                  <ExportExcelResource />
                {/* </SideDrawer> */}
              </Protected>
            }
          />
          <Route
            path="/resource/ope"
            element={
              <Protected allowedRoles={["employee"]}>
                {/* <SideDrawer> */}
                  <ResourceOPE />
                {/* </SideDrawer> */}
              </Protected>
            }
          />
          <Route
            path="/resource/leaves"
            element={
              <Protected allowedRoles={["employee"]}>
                {/* <SideDrawer> */}
                  <ResourceLeave />
                {/* </SideDrawer> */}
              </Protected>
            }
          />

          {/* 404 Not Found Route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </Provider>
  );
};

export default App;
