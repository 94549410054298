import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  filterTimeByUserId,
  getAllEmployeesByAdminId,
  getAllMatters,
} from "../../actions";
import "jspdf-autotable";
import formatDate, {
  formatMonthDate,
  API,
  errorAlert,
  formatLetterDate,
  successAlert,
  billedDesignations,
} from "../../utils/services";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import { Download } from "@mui/icons-material";
import axiosInstance from "../../helpers/axios";
import jsPDF from "jspdf";
import NoDataFound from "../../components/NoDataFound";
import { ToastContainer } from "react-toastify";
import TableSkeletonLoader from "../../components/ui/TableSkeletonLoader";
import CustomTooltip from "../../components/ui/CustomTootltip";
import CustomDateRangePicker from "../../components/ui/CustomDateRangePicker";

const TimespentTracker = () => {
  const dispatch = useDispatch();

  //redux states
  const auth = useSelector((state) => state.auth);
  const Matter = useSelector((state) => state.matter);
  const adminEmployees = useSelector((state) => state.adminEmployees);
  const { employees } = adminEmployees;

  //States
  const [matterId, setMatterId] = useState(""); //matter id
  // const [lastSentTSDate, setLastSentTSDate] = useState("");
  // const [existingDate, setExistingDate] = useState(null);

  const [selectedLawyer, setSelectedLawyer] = useState("");
  const [startSelectedDate, setStartSelectedDate] = useState(() => {
    const today = new Date();
    const pastDate = new Date(today.setDate(today.getDate() - 7));
    return formatDate(pastDate);
  });
  const [endSelectedDate, setEndSelectedDate] = useState(
    formatDate(new Date())
  );
  const [needFin, setNeedFin] = useState(false);
  const [filteredWorkHour, setFilteredWorkHour] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showComment, setShowComment] = useState(true);
  const [companyData, setCompanyData] = useState({
    companyName: "",
    companyEmail: "",
    companyAddress: "",
    companyLogo: null,
  });
  const [ope, setOPE] = useState(0);

  //constants
  let lastInvoiceRaisedDate;
  let sendMail;
  let currency;

  const adminId = auth.user._id;
  const link1Text = companyData.companyAccountsEmail;
  const link1URL = `mailto:${companyData.companyAccountsEmail}`;
  const link2Text = companyData.companyWebsite;
  const link2URL = companyData.companyWebsite;
  const formattedStartDate = formatLetterDate(startSelectedDate);
  const formattedEndDate = formatLetterDate(endSelectedDate);

  //employees name filteration
  const uniqueNames = useMemo(() => {
    return employees
      .filter(
        (item) =>
          item &&
          billedDesignations.includes(item.designation) &&
          item.status === "active"
      )
      .map((item) => item.fullName);
  }, [employees]);

  const filteredNames = [
    ...new Set(
      filteredWorkHour && filteredWorkHour.map((item) => item?.user?.fullName)
    ),
  ];

  const commonNames = uniqueNames
    .filter((name) => filteredNames.includes(name))
    .sort();

  //matter names filteration
  const matterNames = filteredWorkHour.map((entry) => entry.matter.name);
  // console.log("matterNames", Matter);

  const billableMatters = Matter.matters
    .filter(
      (item) =>
        (item.pricePerHour?.$numberDecimal !== "0" ||
          item.resourceSpecificPrice?.length > 0) &&
        item.status === "enable"
    )
    .map((m) => ({
      id: m._id,
      name: m.name,
      lastSentTSDate: m.lastSentTSDate,
    }));

  function findCommonElements() {
    const commonElements = [];
    for (let i = 0; i < billableMatters.length; i++) {
      if (matterNames.includes(billableMatters[i].name)) {
        commonElements.push(billableMatters[i]);
      }
    }
    return commonElements;
  }

  const uniqueMatterNames = findCommonElements();
  // console.log("uniqueMatterNames", uniqueMatterNames, uniqueMatterNames.length);

  // Handle data loading
  useEffect(() => {
    // Simulate loading data with a delay
    const loadData = async () => {
      setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 1500)); // Simulating API call delay
      setLoading(false);
    };
    dispatch(getAllEmployeesByAdminId());
    fetchCompanyDetails();
    loadData();
  }, []);

  //useEffects
  useEffect(() => {
    dispatch(getAllMatters(auth.user._id));
    dispatch(filterTimeByUserId());
    dispatch(getAllEmployeesByAdminId());
  }, [auth.user._id, dispatch]);

  //GET - company details
  const fetchCompanyDetails = async () => {
    try {
      const response = await axiosInstance.get(
        `${API}/company/details/${adminId}`
      );

      // Set other company details as well
      setCompanyData({
        ...companyData,
        companyName: response.data.companyName || "",
        companyEmail: response.data.companyEmail || "",
        companyAddress: response.data.companyAddress || "",
        companyLogo: response.data.companyLogo || "",
        companyWebsite: response.data.companyWebsite || "",
        companyAccountsEmail: response.data.companyAccountsEmail || "",
      });
    } catch (error) {
      errorAlert("Error in fetching company details!!");
    }
  };

  //GET - TS data
  const filterBetweenDates = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${API}/workHour/filterWorkHourByUserId`,
        {
          params: {
            user: selectedLawyer,
            fromDate: startSelectedDate,
            toDate: endSelectedDate,
            matterId,
            adminId,
            needFin,
            sendMail,
          },
        }
      );
      setFilteredWorkHour(response.data.workHours);
    } catch (error) {
      errorAlert("Error fetching work hours!");
    } finally {
      setLoading(false);
    }
  };

  // -------------Mutiple TS code
  const handleClick = () => {
    generateTimeSpentForMultipleMatters();
  };

  const doubleUsedTableColumns = [
    "Associate Name",
    "Total Time",
    "Billed Time",
    "Hourly Rate",
    "Total Amount",
  ];

  // console.log("uniqueMatterNames", uniqueMatterNames);
  // console.log("Matters", Matter.matters);

  const generateTimeSpentForMultipleMatters = () => {
    uniqueMatterNames.forEach((uniqueMatter) => {
      console.log("uniqueMatter", uniqueMatter);

      let doubleUsedTableData = [];
      let teamTotalHours = 0;
      let teamTotalBilledHours = 0;
      let teamTotalBilledAmount = 0;
      let teamTotalUnbilledAmount = 0;
      let persontotalHours = 0;

      const matterCurr = Matter.matters.find((m) => m.id === uniqueMatter.id);
      if (matterCurr) {
        currency = matterCurr.currency === "USD" ? "$" : "Rs. ";
        // console.log("currency", matterCurr);
      } else {
        currency = "";
        // console.log("Matter not found.");
      }

      const doc = new jsPDF();

      const pageWidth = doc.internal.pageSize.getWidth();
      const rightMargin = 15;
      const logoDesiredHeight = 20;
      const logoYPosition = 10;
      const logoXPosition = 13;

      function addHeader() {
        // Set the font style, size, and position of the header text
        const fontSize = 8;

        doc.setFont("helvetica", "normal");
        doc.setFontSize(fontSize);

        // Add the image to the header
        // doc.addImage(companyData.companyLogo, "PNG", 15, 10, 70, 9);
        doc.addImage(
          companyData.companyLogo,
          "PNG",
          logoXPosition,
          logoYPosition,
          0,
          logoDesiredHeight
        );

        // right header
        doc.setTextColor(46, 115, 176);

        const link2Width = doc.getTextWidth(link2Text);
        const link2X = pageWidth - link2Width - rightMargin;
        doc.textWithLink(link2Text, link2X, 19, {
          url: link2URL,
          underline: true,
        });

        // Set link color to blue and add underline
        const link1Width = doc.getTextWidth(link1Text);
        const link1X = pageWidth - link1Width - rightMargin;
        doc.textWithLink(link1Text, link1X, 22, {
          url: link1URL,
          underline: true,
        });

        doc.setTextColor(0);

        // Calculate text width and position to align it right
        const addressWidth = doc.getTextWidth(companyData.companyAddress);
        const addressX = pageWidth - addressWidth - rightMargin;
        doc.text(companyData.companyAddress, addressX, 26);

        // Reset text color to black
      }

      const Timespent = `/assets/Timespent.jpg`;

      doc.addImage(Timespent, "JPEG", 0, 0, 210, 297);
      doc.addImage(
        companyData.companyLogo,
        "PNG",
        logoXPosition,
        logoYPosition,
        0,
        logoDesiredHeight
      );

      // console.log("uniqueMatter", uniqueMatter);

      const clientName = Matter.matters.find(
        (entry) => entry?.name === uniqueMatter.name
      ).client.name;

      const uniqueMatterName = uniqueMatter.name;

      console.log("clientName", clientName);

      const fontSize = 20;
      doc.setFontSize(fontSize);

      // center alignment width calculations
      const clientNameLength = (210 - doc.getTextWidth(clientName)) / 2;
      const matterNameLength = (210 - doc.getTextWidth(uniqueMatterName)) / 2;

      const dateRangeLength =
        (210 -
          doc.getTextWidth(`${formattedStartDate} - ${formattedEndDate}`)) /
        2;

      //client name
      doc.setFont("helvetica", "bold");
      doc.setTextColor(136, 106, 71);
      doc.setFontSize(20);
      doc.text(clientName, clientNameLength, 120);

      //matter name
      doc.setFontSize(20);
      doc.setFont("helvetica", "normal");
      doc.text(uniqueMatterName, matterNameLength, 130);

      //horizontal line
      doc.setLineWidth(0.3);
      doc.setDrawColor(136, 106, 71);
      doc.line(40, 145, 180, 145);

      //date range
      doc.setFont("helvetica", "italic");
      doc.setTextColor(0, 0, 0); // Black color
      doc.setFontSize(15);
      doc.text(
        `${formattedStartDate} - ${formattedEndDate}`,
        dateRangeLength + 10,
        160
      );

      // ----------------2nd Page --------------------

      doc.addPage();
      addHeader();
      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");

      doc.text("Dear Sir / Ma'am", 15, 40);
      doc.setFont("helvetica", "bold");
      doc.setTextColor(136, 106, 71);
      doc.text(
        `${clientName} - ${uniqueMatterName} From Period ${formatMonthDate(
          startSelectedDate
        )} To ${formatMonthDate(endSelectedDate)}`,
        14,
        50
      );
      doc.setTextColor(0, 0, 0);
      doc.setFont("helvetica", "normal");
      doc.text(
        "Please find enclosed our memorandum of time-spent on the given matter for the said period.",
        15,
        60
      );

      //---------------------------------Table 1 Duplicate Table Page 2----------------------------
      //Table 1 Page 2 Data
      // ----------------------------------Main Table declaration ------------------------

      // Filtering the data with matter, dates and resource name
      if (commonNames.length > 0) {
        commonNames.forEach((name) => {
          let personWorkHours = 0;
          let personWorkHoursUnbilled = 0;

          if (filteredWorkHour.length > 0) {
            filteredWorkHour
              .filter(
                (result) =>
                  result?.user?.fullName === name &&
                  result.role !== "OPE" &&
                  result.workDate >= startSelectedDate &&
                  result.matter.name === uniqueMatterName
              )
              .forEach((result) => {
                const workHour = parseFloat(result.workHour);
                personWorkHours += workHour;
              });

            filteredWorkHour
              .filter(
                (result) =>
                  result.workDate &&
                  result?.user?.fullName === name &&
                  result.role !== "OPE" &&
                  result.matter.name === uniqueMatterName
              )
              .forEach((result) => {
                const workHour = parseFloat(result.workHour);
                personWorkHoursUnbilled += workHour;
              });
          }

          persontotalHours = parseFloat(personWorkHours);

          const newMatter = Matter.matters.find(
            (matter) => matter.name.trim() === uniqueMatterName.trim()
          );

          let price;
          if (newMatter) {
            if (newMatter.pricePerHour) {
              price = newMatter.pricePerHour.$numberDecimal;
            } else if (newMatter.resourceSpecificPrice.length !== 0) {
              const employeeEntry = newMatter.resourceSpecificPrice.find(
                (entry) => entry.includes(name)
              );

              if (employeeEntry) {
                const [, hours] = employeeEntry.split(":");
                price = hours.trim();
              }
            } else {
              price = 0;
            }
          }

          if (price) {
            let personTotalBilledAmount = price * personWorkHours;
            let personTotalUnBilledAmount = price * personWorkHoursUnbilled;
            teamTotalHours += personWorkHours;
            teamTotalBilledHours += personWorkHours;
            teamTotalBilledAmount += personTotalBilledAmount;
            teamTotalUnbilledAmount += personTotalUnBilledAmount;

            if (persontotalHours > 0) {
              const data1 = [
                name,
                persontotalHours.toFixed(2),
                personWorkHours.toFixed(2),
                currency +
                  price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                currency +
                  personTotalBilledAmount
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              ];
              doubleUsedTableData.push(data1);
            }
          }
        });
      }

      // // Add footer row
      const footerRow = [
        {
          content: "Total Hours",
          styles: {
            fillColor: [97, 68, 58],
            textColor: [255, 255, 255],
            fontStyle: "bold",
          },
        },
        {
          content: teamTotalHours.toFixed(2),
          styles: {
            fillColor: [97, 68, 58],
            textColor: [255, 255, 255],
            fontStyle: "bold",
          },
        },
        {
          content: teamTotalBilledHours.toFixed(2),
          styles: {
            fillColor: [97, 68, 58],
            textColor: [255, 255, 255],
            fontStyle: "bold",
          },
        },
        {
          content: "",
          styles: {
            fillColor: [97, 68, 58],
            textColor: [255, 255, 255],
            fontStyle: "bold",
          },
        },
        {
          content:
            currency +
            teamTotalBilledAmount
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          styles: {
            fillColor: [97, 68, 58],
            textColor: [255, 255, 255],
            fontStyle: "bold",
          },
        },
      ];

      doubleUsedTableData.push(footerRow);

      const startY = 70; // Starting Y-coordinate for the table

      doc.autoTable(doubleUsedTableColumns, doubleUsedTableData, {
        startY,
        theme: "grid",
        tableLineColor: [0, 0, 0],
        tableLineWidth: 0.1,

        didParseCell: function (data) {
          if (data.row.index === 0 && data.row.section === "head") {
            // Header row styles
            data.cell.styles.fillColor = [136, 106, 71];
            data.cell.styles.textColor = [255, 255, 255];
            data.cell.styles.halign = "left"; // Align header text to the left
            data.cell.styles.fontStyle = "bold";
            data.cell.styles.fontSize = 9;
          } else if (data.row.section === "body") {
            // Body row styles
            if (data.column.index !== 0) {
              // Right-align all columns except the first column
              data.cell.styles.halign = "right";
            }
          } else if (data.row.index === doubleUsedTableData.length - 1) {
            // Footer row align
            data.cell.styles.halign = "right"; // Align footer text to the right
            data.cell.styles.fillColor = [226, 213, 203];
            data.cell.styles.textColor = [255, 255, 255];
            data.cell.styles.fontStyle = "bold";
          } else if (
            data.row.index === doubleUsedTableData.length - 1 &&
            data.row.section === "foot"
          ) {
            // Footer row styles
            data.cell.styles.fillColor = [226, 213, 203];
            data.cell.styles.textColor = [255, 255, 255];
          }

          // Set padding for table data cells
          if (
            data.row.section === "body" &&
            data.column.index === 0 &&
            data.row.index !== -1 &&
            data.row.index !== doubleUsedTableData.length - 1
          ) {
            data.cell.styles.fontSize = 9;
            data.cell.styles.fontStyle = "arial";
            data.cell.styles.textColor = [0, 0, 0];
          }
          if (
            data.row.section === "body" &&
            data.column.index === 1 &&
            data.row.index !== -1 &&
            data.row.index !== doubleUsedTableData.length - 1
          ) {
            data.cell.styles.fontSize = 9;
            data.cell.styles.fontStyle = "arial";
            data.cell.styles.textColor = [0, 0, 0];
          }
          if (
            data.row.section === "body" &&
            data.column.index === 2 &&
            data.row.index !== -1 &&
            data.row.index !== doubleUsedTableData.length - 1
          ) {
            data.cell.styles.fontSize = 9;
            data.cell.styles.fontStyle = "arial";
            data.cell.styles.textColor = [0, 0, 0];
          }
          if (
            data.row.section === "body" &&
            data.column.index === 3 &&
            data.row.index !== -1 &&
            data.row.index !== doubleUsedTableData.length - 1
          ) {
            data.cell.styles.fontSize = 9;
            data.cell.styles.fontStyle = "arial";
            data.cell.styles.textColor = [0, 0, 0];
          }
          if (
            data.row.section === "body" &&
            data.column.index === 4 &&
            data.row.index !== -1 &&
            data.row.index !== doubleUsedTableData.length - 1
          ) {
            data.cell.styles.fontSize = 9;
            data.cell.styles.fontStyle = "arial";
            data.cell.styles.textColor = [0, 0, 0];
          }
        },
      });

      // Calculate the table height
      const tableHeight = doc.autoTable.previous.finalY - startY;

      doc.text(
        "Summary of payable on this matter is provided below ",
        15,
        startY + tableHeight + 10
      );

      doc.setFont("helvetica", "bold");
      doc.setTextColor(136, 106, 71);
      doc.text(
        "Total Amount for the above period",
        15,
        startY + tableHeight + 20
      );
      doc.text(
        currency +
          (parseFloat(ope) + parseFloat(teamTotalBilledAmount))
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        doc.internal.pageSize.getWidth() -
          doc.getTextWidth(
            currency +
              (parseFloat(ope) + parseFloat(teamTotalBilledAmount))
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          ) -
          15,
        startY + tableHeight + 20
      );
      doc.text(
        `Unbilled amount since last invoice (${
          lastInvoiceRaisedDate !== undefined
            ? formatLetterDate(lastInvoiceRaisedDate)
            : "no invoices sent yet"
        })`,
        15,
        startY + tableHeight + 28
      );

      doc.text(
        currency +
          (parseFloat(ope) + parseFloat(teamTotalUnbilledAmount))
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        doc.internal.pageSize.getWidth() -
          doc.getTextWidth(
            currency +
              (parseFloat(ope) + parseFloat(teamTotalUnbilledAmount))
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          ) -
          15,
        startY + tableHeight + 28
      );

      doc.setTextColor(0, 0, 0);
      doc.setFont("helvetica", "normal");

      doc.text(
        "Please do let us know if any changes are required. Please note that this is only a statement of timespent for ",
        15,
        startY + tableHeight + 43
      );
      doc.text(
        "your reference and not an invoice. The invoice shall be raised subsequently in the manner agreed with you.",
        15,
        startY + tableHeight + 48
      );

      doc.text("Yours Sincerely,", 15, startY + tableHeight + 58);
      doc.setFont("helvetica", "bold");
      doc.text(auth.user.fullName, 15, startY + tableHeight + 63);
      doc.setFont("helvetica", "normal");

      // --------------------------------------3rd Page----------------------------------------------------
      doc.addPage();
      addHeader();

      let startY1 = 35; // Starting y-coordinate for each table

      commonNames.forEach((name, index) => {
        let tableRows = [];

        const personData = filteredWorkHour
          .filter(
            (result) =>
              result.user?.fullName === name &&
              result.role !== "OPE" &&
              result.workDate >= startSelectedDate &&
              result.matter.name === uniqueMatterName
          )
          .sort((a, b) => new Date(a.workDate) - new Date(b.workDate)); // Sort the data by workDate in ascending order

        const billedTime = personData.reduce(
          (total, result) => total + parseFloat(result.workHour),
          0
        );

        const headerRow = [
          {
            content: name,
            colSpan: 2,
            styles: {
              cellWidth: "auto", // Set cell width to auto to allow content alignment
              halign: "left",
              fillColor: [136, 106, 71],
              textColor: [255, 255, 255],
              fontStyle: "bold", // Set the font style to bold
            },
          },
          {
            content: "Total Time",
            styles: {
              cellWidth: "auto", // Set cell width to auto to allow content alignment
              halign: "left",
              fillColor: [136, 106, 71],
              textColor: [255, 255, 255],
              fontStyle: "bold", // Set the font style to bold
            },
          },

          {
            content: "Billed Time",
            styles: {
              cellWidth: "auto", // Set cell width to auto to allow content alignment
              halign: "left",
              fillColor: [136, 106, 71],
              textColor: [255, 255, 255],
              fontStyle: "bold", // Set the font style to bold
            },
          },
        ];

        if (personData.length > 0) {
          tableRows.push(headerRow); // Initialize tableRows with the header row

          let commentsText = " ";

          // Add individual data rows for the person
          personData.forEach((result) => {
            commentsText = result.comments || " ";

            const data = [
              formatMonthDate(result.workDate),
              showComment === true && commentsText !== " "
                ? `${result.workDescription}

      -----------------------------------------------------------------------------------------------------------
      ||      ${commentsText}  ||`
                : result.workDescription,
              parseFloat(result.workHour).toFixed(2),
              parseFloat(result.workHour).toFixed(2),
            ];

            if (data.length > 0) {
              tableRows.push(data);
            }
          });

          // Add footer row with total time
          const footerRow = [
            {
              content: "Total Time",
              colSpan: 2,
              styles: {
                halign: "left",
                fillColor: [97, 68, 58],
                textColor: [255, 255, 255],
                fontStyle: "bold",
              },
            },
            {
              content: billedTime.toFixed(2),
              styles: {
                halign: "right",
                fillColor: [97, 68, 58],
                textColor: [255, 255, 255],
                fontStyle: "bold",
              },
            },
            {
              content: billedTime.toFixed(2),
              styles: {
                halign: "right",
                fillColor: [97, 68, 58],
                textColor: [255, 255, 255],
                fontStyle: "bold",
              },
            },
          ];
          tableRows.push(footerRow);

          // Remove empty rows and columns from tableRows
          const filteredTableRows = tableRows.filter((row) =>
            row.some((cell) => cell !== "")
          );

          // Generate table for the current person

          doc.autoTable({
            body: filteredTableRows,
            startY: startY1,
            theme: "grid",
            tableLineColor: [0, 0, 0],
            tableLineWidth: 0.1,
            didParseCell: function (data) {
              if (data.row.index === 0 && data.row.section === "head") {
                // Header row styles
                data.cell.styles.fillColor = [136, 106, 71];
                data.cell.styles.textColor = [255, 255, 255];
                data.cell.styles.halign = "left";
                data.cell.styles.valign = "left"; // Align the text vertically at the center
                data.cell.styles.fontStyle = "bold"; // Set the font style to bold
              } else if (
                data.row.index === filteredTableRows.length - 1 &&
                data.row.section === "body"
              ) {
                // Footer row styles
                data.cell.styles.fillColor = [97, 68, 58];
                data.cell.styles.textColor = [255, 255, 255];
                // data.cell.styles.halign = "right";
                data.cell.styles.fontStyle = "bold";
              } else if (data.row.section === "body") {
                // Body row styles
                if (data.column.index !== 1) {
                  // Right-align all columns except the first column
                  data.cell.styles.halign = "left";
                }
              }
              // Set padding for table data cells
              if (
                data.row.section === "body" &&
                data.column.index === 0 &&
                data.row.index !== 0 &&
                data.row.index !== tableRows.length - 1
              ) {
                data.cell.styles.cellPadding = {
                  top: 3,
                  right: 2,
                  bottom: 3,
                  left: 3,
                };
                data.cell.styles.fontSize = 9;
                data.cell.styles.halign = "left";
                data.cell.styles.cellWidth = 20;
                data.cell.styles.textColor = [0, 0, 0];
              }
              if (
                data.row.section === "body" &&
                data.column.index === 1 &&
                data.row.index !== 0 &&
                data.row.index !== tableRows.length - 1
              ) {
                data.cell.styles.cellPadding = {
                  top: 3,
                  right: 4,
                  bottom: 3,
                  left: 4,
                };
                data.cell.styles.fontSize = 9;
                data.cell.styles.fontStyle = "times";
                data.cell.styles.halign = "left";
                data.cell.styles.cellWidth = 122; // Set the width to 100 units
                data.cell.styles.textColor = [0, 0, 0];
              }

              if (data.row.section === "body" && data.column.index === 1) {
                // Check if the cell contains the separator line indicating comments
                const cellText = data.cell.text;
                const hasComments = cellText.includes(
                  "-----------------------------------------------------------------------------------------------------------"
                );

                if (hasComments) {
                  data.cell.styles.fillColor = [215, 230, 230];
                }
              }

              if (
                data.row.section === "body" &&
                data.column.index === 2 &&
                data.row.index !== 0 &&
                data.row.index !== tableRows.length - 1
              ) {
                data.cell.styles.cellPadding = {
                  top: 3,
                  right: 2,
                  bottom: 3,
                  left: 2,
                };
                data.cell.styles.halign = "right";
                data.cell.styles.fontSize = 9;
                data.cell.styles.cellWidth = 20;
                data.cell.styles.textColor = [0, 0, 0];
              }
              if (
                data.row.section === "body" &&
                data.column.index === 3 &&
                data.row.index !== 0 &&
                data.row.index !== tableRows.length - 1
              ) {
                data.cell.styles.cellPadding = {
                  top: 3,
                  right: 2,
                  bottom: 3,
                  left: 2,
                };
                data.cell.styles.halign = "right";
                data.cell.styles.fontSize = 9;
                data.cell.styles.cellWidth = 20;
                data.cell.styles.textColor = [0, 0, 0];
              }
            },
          });
          startY1 = doc.lastAutoTable.finalY + 10; // Update startY for the next table
        }
      });

      doc.save(
        `${clientName} - ${uniqueMatterName} From Period ${formatMonthDate(
          startSelectedDate
        )} To ${formatMonthDate(endSelectedDate)}.pdf`
      );
    });
  };

  const handleAddTsDate = async (matterId, date) => {
    try {
      // console.log("Payload", matterId, date);
      const response = await axiosInstance.put(`${API}/updateLastSentTSDate`, {
        id: matterId,
        lastSentTSDate: date,
      });

      const data = await response.data;
      dispatch(getAllMatters(auth.user._id));
      data && successAlert("Date Added!!");

      // console.log("Updated matter:", data);
    } catch (error) {
      console.error("Error updating lastSentTSDate:", error);
    }
  };

  // states and functions for date range picker
  const [selectedDateRange, setSelectedDateRange] = useState({
    start: "",
    end: "",
  });

  // Function to receive selected dates from DateRangePickerTst
  const handleDateRangeChange = (startDate, endDate) => {
    setSelectedDateRange({ start: startDate, end: endDate });
    setStartSelectedDate(startDate);
    setEndSelectedDate(endDate);
    console.log(startDate, endDate);
  };

  useEffect(() => {
    if (startSelectedDate && endSelectedDate) {
      filterBetweenDates();
    }
  }, [startSelectedDate, endSelectedDate]);

  return (
    <>
      <ToastContainer />

      <Paper elevation={0} className="rounded-full">
        <Grid container spacing={1} alignItems="center" marginBottom="1rem">
          {/* Main Heading  */}
          <Grid item container spacing={1} xs={12} sm={7} md={3}>
            <Grid item>
              <Typography variant="h5">Timespent Tracker</Typography>
            </Grid>
          </Grid>

          {/* Date Range Picker */}
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            display="flex"
            alignItems="center"
            gap={1}
          >
            <CustomTooltip text={"Select Dates"}>
              <CustomDateRangePicker
                startDate={selectedDateRange.start}
                endDate={selectedDateRange.end}
                onDateRangeChange={handleDateRangeChange}
                iconSize={30}
              />
            </CustomTooltip>

            <Typography>
              {formatMonthDate(startSelectedDate)} -{" "}
              {formatMonthDate(endSelectedDate)}
            </Typography>
          </Grid>

          {/* Search Icon Button */}
          {/* <Grid item xs={4} sm={4} md={0.4}>
            <CustomTooltip text="Search workhours">
              <IconButton
                onClick={() => filterBetweenDates()}
                className=" text-black hover:bg-gray-800"
                style={{
                  padding: 5,
                  borderRadius: "50%",
                }}
              >
                <Search style={{ fontSize: "20px" }} /> 
              </IconButton>
            </CustomTooltip>
          </Grid> */}

          {/* Download Icon Button */}
          <Grid item xs={4} sm={4} md={1}>
            <CustomTooltip text="Download All Reports">
              <IconButton
                onClick={handleClick}
                className=" text-black hover:bg-gray-800"
                style={{
                  padding: 5,
                  // color: "white",
                  // backgroundColor: "black",
                  borderRadius: "50%",
                }}
              >
                <Download style={{ fontSize: "20px" }} />
              </IconButton>
            </CustomTooltip>
          </Grid>
          <Grid item xs={4} sm={4} md={6}>
            <Typography variant="body2">
              *Download All Timespent Button will download first top 10 matters
              pdf only
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      {/* Main Table  */}
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: "75vh" }}>
          <Box className="flex justify-between mx-10">
            <Typography variant="body2" align="center" marginY={2} gutterBottom>
              MATTERWISE TIMESPENT TRACKER ({formatMonthDate(startSelectedDate)}{" "}
              - {formatMonthDate(endSelectedDate)})
            </Typography>

            <Typography variant="body2" align="center" marginY={2} gutterBottom>
              Total Matters : {uniqueMatterNames.length}
            </Typography>
          </Box>

          <Table aria-label="reusable table">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>Sr No.</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Matter</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Period</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Last Sent TS Date
                </TableCell>
              </TableRow>
            </TableHead>

            {loading ? (
              <TableSkeletonLoader rows={6} colSpan={11} />
            ) : uniqueMatterNames.length === 0 ? (
              <TableRow>
                <TableCell colSpan={13}>
                  <NoDataFound />
                </TableCell>
              </TableRow>
            ) : (
              uniqueMatterNames.map((matter, index) => (
                <TableRow key={index} role="checkbox" tabIndex={-1}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{matter.name}</TableCell>
                  <TableCell>
                    {formatMonthDate(startSelectedDate)} -{" "}
                    {formatMonthDate(endSelectedDate)}
                  </TableCell>
                  <TableCell>
                    <input
                      type="date"
                      value={
                        matter.lastSentTSDate
                          ? matter.lastSentTSDate.split("T")[0]
                          : ""
                      }
                      min={startSelectedDate}
                      max={endSelectedDate}
                      onChange={(e) =>
                        handleAddTsDate(matter.id, e.target.value)
                      }
                    />
                  </TableCell>
                </TableRow>
              ))
            )}
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default TimespentTracker;
