import React, { useState } from "react";
import {
  IconButton,
  Popover,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CustomTooltip from "./CustomTootltip";

const PricesPopover = ({ prices }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  // Open the popover on click
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Close the popover
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Determine if the popover is open
  const popoverOpen = Boolean(anchorEl);

  return (
    <div>
      <CustomTooltip text="View Prices">
        <IconButton
          onClick={handleClick} // Trigger popover on click
          aria-controls={popoverOpen ? "prices-popover" : undefined}
          aria-haspopup="true"
          aria-expanded={popoverOpen ? "true" : undefined}
        >
          <InfoIcon />
        </IconButton>
      </CustomTooltip>
      <Popover
        id="prices-popover"
        anchorEl={anchorEl}
        open={popoverOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            width: "200px",
            height: "400px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
          },
          onMouseEnter: () => clearTimeout(),
          onMouseLeave: handleClose,
        }}
      >
        <List dense>
          {prices && prices.length > 0 ? (
            prices.map((priceString, index) => {
              const [name, price] = priceString.split(": ");
              return (
                <ListItem key={index} disableGutters>
                  <ListItemText
                    primary={`${name}: ${price}`}
                    primaryTypographyProps={{ fontWeight: "normal" }}
                  />
                </ListItem>
              );
            })
          ) : (
            <ListItem>
              <ListItemText primary="No pricing details available." />
            </ListItem>
          )}
        </List>
      </Popover>
    </div>
  );
};

export default PricesPopover;
