import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import {
  Box,
  Button,
  CssBaseline,
  Grid,
  Paper,
  Snackbar,
  TablePagination,
  Typography,
} from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { getWorkHourByUserId } from "../../actions";
import formatDate, {
  API,
  customStyles,
  errorAlert,
  formatMonthDate,
  successAlert,
} from "../../utils/services";
import { Add, Remove } from "@mui/icons-material";
import axiosInstance from "../../helpers/axios";
import { useMattersOptions } from "../../helpers/hooks/useMattersOptions";
import Header from "../../components/ui/Header";
import { COLORS } from "../../constants/theme";
import OpeCard from "../../components/ui/OpeCard";
import CustomDateRangePicker from "../../components/ui/CustomDateRangePicker";
import CustomTooltip from "../../components/ui/CustomTootltip";
import NoDataFound from "../../components/NoDataFound";
import CustomButton from "../../components/ui/CustomButton";
import useResponsive from "../../constants/useResponsive";

const validationSchema = Yup.object().shape({
  selectedDate: Yup.string().required("Date is required"),
  matter: Yup.string().required("Matter is required"),
  opeDescription: Yup.string().required("Description is required"),
  amount: Yup.number().required("Amount is required"),
  currency: Yup.string().required("Currency is required"),
});

const ResourceOPE = () => {
  const dispatch = useDispatch();
  const { filteredMatters } = useMattersOptions();
  const { isSm } = useResponsive();

  const auth = useSelector((state) => state.auth);
  const time = useSelector((state) => state.time);
  const {
    userWorkHour,
    // workHourLoading,
    totalCount,
  } = time;
  const role = "OPE";

  const [editingOPE, setEditingOPE] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [startSelectedDate, setStartSelectedDate] = useState(() => {
    const today = new Date();
    const pastDate = new Date(today.setDate(today.getDate() - 30));
    return formatDate(pastDate);
  });
  const [endSelectedDate, setEndSelectedDate] = useState(
    formatDate(new Date())
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [isFormVisible, setIsFormVisible] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    dispatch(
      getWorkHourByUserId(
        startSelectedDate,
        endSelectedDate,
        searchQuery,
        role,
        page,
        rowsPerPage
      )
    );
  }, [
    dispatch,
    startSelectedDate,
    endSelectedDate,
    searchQuery,
    role,
    page,
    rowsPerPage,
  ]);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      selectedDate: formatDate(new Date()),
      matter: "",
      client: "",
      selectedMatterName: "",
      opeDescription: "",
      amount: "",
      currency: "INR",
    },
  });

  useEffect(() => {
    const entry = editingOPE;
    if (entry) {
      setValue("selectedDate", formatDate(new Date(entry.workDate)));
      setValue("matter", entry.matter._id);
      setValue("selectedMatterName", entry.matter.name);
      setValue("opeDescription", entry.workDescription);
      setValue("amount", entry.amount.$numberDecimal);
      setValue("currency", entry.currency);
    }
  }, [editingOPE, setValue]);

  const onSubmit = async (data) => {
    // console.log("Submitting form with data:", data); // Debug log

    if (
      data.selectedDate &&
      data.matter &&
      data.opeDescription &&
      data.amount &&
      data.currency
    ) {
      const opeData = {
        admin: auth?.user?.company,
        user: auth?.user?._id,
        workDate: data.selectedDate,
        matter: data.matter,
        workDescription: data.opeDescription,
        amount: data.amount,
        currency: data.currency,
        category: "billable",
        status: "pending",
        opeBills: selectedImages,
      };

      try {
        // console.log("Onsubmit called with OPE data:", opeData); // Debug log

        let response;
        if (editingOPE) {
          response = await axiosInstance.put(
            `${API}/ope/update/${editingOPE._id}`,
            opeData
          );
        } else {
          response = await axiosInstance.post(`${API}/OPE/createNew`, opeData);
        }

        if (response.data) {
          successAlert(
            editingOPE ? "OPE Updated Successfully!" : "OPE Added Successfully!"
          );
          handleSearch();
          setEditingOPE(null);
          reset();
          setSelectedImages([]);
        }
      } catch (error) {
        console.error("Error:", error);
        errorAlert("Something went wrong", error);
      }
    } else {
      errorAlert("All Fields are Required!");
    }
  };

  const handleEdit = (entry) => {
    if (!isFormVisible) {
      setIsFormVisible((prev) => !prev);
    }
    setEditingOPE(entry);
  };

  const handleFileUpload = async (e) => {
    if (e.target.files.length > 0) {
      const files = e.target.files;
      const uploadPromises = [];

      // console.log("File Upload called!!"); // Debug log

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("adminId", auth.user.company);
        formData.append("employeeName", auth.user.fullName);
        formData.append("employeeId", auth.user._id);

        uploadPromises.push(
          fetch(`${API}/uploadOpe`, {
            method: "POST",
            body: formData,
          })
        );
      }

      try {
        setUploading(true);
        const uploadResponses = await Promise.all(uploadPromises);
        const uploadData = await Promise.all(
          uploadResponses.map((res) => res.json())
        );
        const uploadedFileUrls = uploadData.map((data) => data.url);
        // console.log("Uploaded urls", uploadedFileUrls); // Debug log
        setSelectedImages([...selectedImages, ...uploadedFileUrls]);

        // Reset the file input field
        e.target.value = "";
      } catch (e) {
        console.error(e);
      } finally {
        setUploading(false);
      }
    }
  };

  const handleRemoveImage = async (event, index) => {
    // Create a copy of the current selected images
    const updatedSelectedImages = [...selectedImages];
    const imageToRemove = updatedSelectedImages[index];

    try {
      // Optimistic UI Update: Remove the image from the state immediately
      setSelectedImages((prevImages) =>
        prevImages.filter((_, imgIndex) => imgIndex !== index)
      );

      // Send a request to the backend to delete the image
      const result = await axiosInstance.delete(`${API}/deleteOpeBill`, {
        data: { filePath: imageToRemove },
      });

      if (result.status !== 200) {
        throw new Error("Failed to delete image on the server");
      }

      console.log("Image deleted successfully from the server.");
    } catch (error) {
      console.error("Error deleting image:", error);
      alert("Failed to delete image. Please try again.");

      // Restore the image in the state if deletion fails
      setSelectedImages(updatedSelectedImages);
    }
  };

  const handleSearch = () => {
    dispatch(
      getWorkHourByUserId(
        startSelectedDate,
        endSelectedDate,
        searchQuery,
        role,
        page,
        rowsPerPage
      )
    );
  };

  const toggleFormVisibility = () => {
    setIsFormVisible((prev) => !prev);
    // if (isFormVisible) resetForm();
  };

  const [selectedDateRange, setSelectedDateRange] = useState({
    start: "",
    end: "",
  });

  // Function to receive selected dates from DateRangePickerTst
  const handleDateRangeChange = (startDate, endDate) => {
    setSelectedDateRange({ start: startDate, end: endDate });
    setStartSelectedDate(startDate);
    setEndSelectedDate(endDate);
    console.log(startDate, endDate);
  };

  // State to keep track of the deleted item
  const [deletedItem, setDeletedItem] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // Delete handler
  const handleSoftDelete = async (entryId) => {
    try {
      const response = await fetch(`${API}/workhour/softdelete/${entryId}`, {
        method: "DELETE",
      });
      const data = await response.json();

      console.log("Soft delete response:", data);

      if (response.ok) {
        setDeletedItem({ entryId, ...data });
        // Show the undo alert
        // undoAlert("Workhour deleted.", handleUndo);
        setSnackbarOpen(true);
        console.log("Deleted Item State:", { entryId, ...data }); // Debug the deleted item state
        handleSearch();
      } else {
        errorAlert("Failed to delete workhour.");
      }
    } catch (error) {
      errorAlert("An error occurred while deleting workhour.");
    }
  };

  // Undo handler
  const handleUndo = async () => {
    try {
      if (deletedItem) {
        const response = await fetch(
          `${API}/workhour/restore/${deletedItem.entryId}`,
          {
            method: "POST",
          }
        );

        await response.json();

        if (response.ok) {
          // Remove the item from temporary state
          setDeletedItem(null);
          successAlert("Workhour restored successfully.");
          handleSearch();
        } else {
          errorAlert("Failed to restore workhour.");
        }
      }
    } catch (error) {
      errorAlert("An error occurred while restoring workhour.");
    }
  };

  return (
    <>
      <Header />
      <ToastContainer />
      <CssBaseline />

      {/* Snackbar for Undo Action */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }} // Change the position here
        open={snackbarOpen}
        autoHideDuration={10000} // Hide after 10 seconds if no action is taken
        onClose={() => setSnackbarOpen(false)}
        message="Workhour deleted"
        action={
          <Button color="secondary" size="small" onClick={handleUndo}>
            UNDO
          </Button>
        }
      />

      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        paddingX={isSm ? 2 : 5}
        marginTop={2}
      >
        {/* Heading and Add/Remove Button aligned to the left */}
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          marginBottom={3}
        >
          <Box display="flex" alignItems="center" gap={2}>
            <Typography variant="h5">Expenses</Typography>
            <CustomTooltip text={isFormVisible ? "Close Form" : "Add expense"}>
              <CustomButton
                style={{
                  borderRadius: "50%",
                  minWidth: "30px", // Minimum width
                  width: "40px", // Fixed width
                  height: "35px", // Fixed height
                  flexShrink: 0, // Prevents the button from stretching
                  transition:
                    "transform 0.3s ease-in-out, background-color 0.3s ease",
                  "&:hover": {
                    backgroundColor: COLORS.primary,
                    transform: "scale(1.1)",
                  },
                }}
                onClick={toggleFormVisibility}
              >
                {isFormVisible ? <Remove /> : <Add />}
              </CustomButton>
            </CustomTooltip>
          </Box>
        </Grid>

        {/* Search Bar and Calendar Icon aligned to the center */}
        <Grid
          item
          xs={12}
          sm={6}
          md={5}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {/* Search Bar */}
          <Grid item xs={10} sm={9} md={8}>
            <Select
              options={filteredMatters}
              value={filteredMatters.find(
                (option) => option.value === searchQuery
              )}
              onChange={(option) => setSearchQuery(option.value)}
              isSearchable
              placeholder="Search Matter"
              styles={customStyles}
            />
          </Grid>

          {/* Date Range Picker */}
          <Grid
            item
            xs={2}
            sm={2}
            md={4}
            display="flex"
            alignItems="center"
            justifyContent={isSm ? "center" : "flex-end"}
          >
            <CustomTooltip text="Select Dates">
              <CustomDateRangePicker
                startDate={selectedDateRange.start}
                endDate={selectedDateRange.end}
                onDateRangeChange={handleDateRangeChange}
                iconSize={30}
                iconColor={COLORS.primary}
              />
            </CustomTooltip>

            <Typography>
              {formatMonthDate(startSelectedDate)} -{" "}
              {formatMonthDate(endSelectedDate)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        display="flex"
        justifyContent="center"
        spacing={2}
        paddingX={isSm ? 2 : 5}
        paddingY={2}
      >
        {isFormVisible && (
          <Grid item xs={12} sm={12} md={4}>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
              <Grid container columnSpacing={2} marginBottom={1}>
                <Grid item xs={12} sm={6} md={6}>
                  <input
                    type="date"
                    className={`formInput ${
                      errors.selectedDate ? `formInputError` : ""
                    }`}
                    {...register("selectedDate")}
                  />
                  {errors.selectedDate && (
                    <p className="errorMessage">
                      {errors.selectedDate.message}
                    </p>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="matter"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={filteredMatters}
                        value={filteredMatters.find(
                          (option) => option.value === field.value
                        )}
                        onChange={(option) => {
                          setValue("matter", option.value);
                          setValue(
                            "selectedMatterName",
                            option.label.split("|")[0]
                          );
                          setValue("client", option.label.split("|")[1]);
                          trigger("matter");
                        }}
                        isSearchable
                        placeholder="Search Matter"
                        className={` ${errors.matter ? "formInputError" : ""}`}
                        styles={{
                          ...customStyles, // Spread the global styles
                          control: (base, state) => ({
                            ...customStyles.control(base, state), // Apply global control styles
                            borderColor: errors.matter
                              ? "red"
                              : base.borderColor, // Conditionally add error style
                            "&:hover": {
                              borderColor: errors.matter ? "red" : "#aaaaaa", // Change hover color based on error
                            },
                          }),
                        }}
                      />
                    )}
                  />
                  {errors.matter && (
                    <p className="errorMessage">{errors.matter.message}</p>
                  )}
                </Grid>
              </Grid>

              <Grid container columnSpacing={2} marginBottom={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <input
                    placeholder="Amount"
                    type="number"
                    className={`formInput ${
                      errors.amount ? `formInputError` : ""
                    }`}
                    {...register("amount")}
                  />
                  {errors.amount && (
                    <p className="errorMessage">{errors.amount.message}</p>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <select
                    className={`formInput ${
                      errors.currency ? `formInputError` : ""
                    }`}
                    {...register("currency")}
                  >
                    <option value="USD">USD</option>
                    <option value="INR">INR</option>
                  </select>
                  {errors.currency && (
                    <p className="errorMessage">{errors.currency.message}</p>
                  )}
                </Grid>
              </Grid>

              <Grid container marginBottom={2} flexDirection="column">
                <textarea
                  placeholder="Bill description"
                  style={{ height: "100px" }}
                  className={`formInput ${
                    errors.opeDescription ? "formInputError" : ""
                  }`}
                  {...register("opeDescription")}
                />
                {errors.opeDescription && (
                  <p className="errorMessage">
                    {errors.opeDescription.message}
                  </p>
                )}
              </Grid>

              <Grid container marginBottom={2} flexDirection="column">
                <input
                  type="file"
                  accept="image/jpeg, image/png, image/webp, image/heif, image/htm"
                  onChange={handleFileUpload}
                  disabled={uploading}
                  multiple
                  placeholder="Upload Bills"
                />
                {selectedImages.map((file, index) => (
                  <div key={index}>
                    <img
                      src={file}
                      alt={index + 1}
                      style={{ maxWidth: "100px", height: "100px" }}
                    />
                    <button
                      type="button"
                      onClick={(event) => handleRemoveImage(event, index)}
                    >
                      Remove
                    </button>
                  </div>
                ))}
                {uploading && <p>Uploading...</p>}
              </Grid>

              <Grid
                container
                flexDirection="row"
                justifyContent="center"
                alignItems="baseline"
                gap={2}
              >
                <CustomButton type="submit" style={{ width: "50%" }}>
                  {editingOPE ? "Update" : "Submit"}
                </CustomButton>
                <CustomButton
                  variant="text"
                  style={{ width: "30%" }}
                  onClick={() => {
                    reset();
                    setEditingOPE(null);
                    setSelectedImages([]);
                  }}
                >
                  Reset
                </CustomButton>
              </Grid>
            </Box>
          </Grid>
        )}

        <Grid
          item
          xs={12}
          sm={12}
          md={isFormVisible ? 8 : 12}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{ maxHeight: "70vh", overflow: "hidden" }}
        >
          <Paper
            elevation={0}
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            {/* Cards Section with scrollable area */}
            <Box sx={{ flex: 1, overflowY: "auto" }}>
              {" "}
              {/* Scrollable content container */}
              {
                // workHourLoading ? (
                //   <SkeletonRows rows={6} colSpan={11} />
                // ) :
                userWorkHour.length === 0 ? (
                  <NoDataFound message="Oops! No expenses found." />
                ) : (
                  userWorkHour.map((time, index) => (
                    <OpeCard
                      key={time._id}
                      time={time}
                      isFormVisible={isFormVisible}
                      handleEdit={handleEdit}
                      handleDelete={handleSoftDelete}
                    />
                  ))
                )
              }
            </Box>

            {/* Table Pagination */}
            <Box>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default ResourceOPE;
