import React, { useEffect, useRef, useState } from "react";
import { Card, Grid, Typography, IconButton, TextField } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BiDuplicate } from "react-icons/bi";
import CustomTooltip from "./CustomTootltip";
import useResponsive from "../../constants/useResponsive";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import formatDate, { formatMonthDate } from "../../utils/services";

const WorkHourCard = ({
  time,
  handleSave,
  handleDelete,
  handleDuplicate,
  showDuplicate = true,
  showDelete = true,
  matters,
  handleEdit,
  isShowingPending,
  handleApprove,
  showApprove = false,
  showLawyerName = false,
}) => {
  const cardRef = useRef(null);
  const { isSm } = useResponsive();
  const [isEditing, setIsEditing] = useState(false);

  // Use react-hook-form for form control
  const { control, handleSubmit, setValue, trigger } = useForm({
    defaultValues: {
      workDate: time.workDate,
      matter: time.matter?._id, // Using the matter's ID
      workDescription: time.workDescription,
      workHour: time.workHour,
    },
  });

  const handleSaveClick = (data) => {
    const updatedTime = {
      ...time,
      ...data,
    };
    handleSave(updatedTime);
    setIsEditing(false);
  };

  // Handle the matter change and trigger the update
  const handleMatterChange = async (option) => {
    try {
      // Update the form values with the selected matter and other details
      setValue("matter", option.value);
      setValue("selectedMatterName", option.label.split("|")[0]);
      setValue("client", option.label.split("|")[1]);
      trigger("matter"); // Trigger validation for the matter field
    } catch (error) {
      console.error("Error fetching matter details:", error);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        setIsEditing(false);
      }
    }

    if (isEditing) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isEditing]);

  useEffect(() => {
    if (isEditing) {
      setValue("workDate", formatDate(new Date(time.workDate)));
      setValue("matter", time.matter?._id);
      setValue("workDescription", time.workDescription);
      setValue("comments", time.comments);
      setValue("workHour", time.workHour);
    }
  }, [isEditing, time, setValue]);

  return (
    <CustomTooltip
      text={
        showLawyerName
          ? "Click to duplicate this entry"
          : isShowingPending
          ? "Click to edit and approve this entry"
          : "Click to edit this entry"
      }
      arrow
    >
      <Card
        ref={cardRef}
        elevation={0}
        sx={{
          padding: isSm ? 1 : 2,
          marginBottom: 2,
          display: "flex",
          alignItems: "center",
          border: "1px solid #e0e0e0",
          borderRadius: "10px",
          width: "100%",
          position: "relative",
          overflow: "visible",
          wordBreak: "break-word",
          boxSizing: "border-box",
        }}
        onClick={() => {
          setIsEditing(true);
          if (!showLawyerName) {
            handleEdit(time);
          }
        }}
      >
        <Grid container alignItems="center" spacing={2.5}>
          {isEditing ? (
            <>
              {/* Date Picker Input */}
              <Grid item xs={isSm ? 3 : 1.5}>
                <Controller
                  name="workDate"
                  control={control}
                  render={({ field }) => (
                    <TextField {...field} type="date" fullWidth />
                  )}
                />
              </Grid>

              {/* Matter Select Dropdown */}
              <Grid item xs={isSm ? 9 : 2.5}>
                <Controller
                  name="matter"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={matters}
                      value={matters.find((m) => m.value === field.value)}
                      onChange={handleMatterChange}
                      placeholder="Select Matter"
                      isSearchable
                      fullWidth
                    />
                  )}
                />
              </Grid>

              {/* Work Hour Input */}
              <Grid item xs={isSm ? 3 : 1}>
                <Controller
                  name="workHour"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="number"
                      fullWidth
                      placeholder="Hours"
                    />
                  )}
                />
              </Grid>

              {/* AI Work Description Input */}
              <Grid item xs={isSm ? 12 : 4}>
                <Controller
                  name="workDescription"
                  control={control}
                  marginBottom={1}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      multiline
                      minRows={1}
                      maxRows={10}
                      placeholder="Work Description"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={isSm ? 12 : 2}>
                <Controller
                  name="comments"
                  control={control}
                  marginBottom={1}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      multiline
                      minRows={1}
                      maxRows={10}
                      placeholder="Comments for the reviewer"
                    />
                  )}
                />
              </Grid>

              {/* Action Buttons: Save/Cancel */}
              <Grid item xs={isSm ? 12 : 1}>
                <CustomTooltip
                  text={
                    showLawyerName
                      ? "Duplicate"
                      : isShowingPending
                      ? "Approve"
                      : "Save"
                  }
                >
                  <IconButton
                    style={{ color: "green" }}
                    onClick={handleSubmit(handleSaveClick)}
                  >
                    <Check style={{ fontSize: "20px" }} />
                  </IconButton>
                </CustomTooltip>

                <CustomTooltip text="Cancel">
                  <IconButton
                    style={{ color: "red" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsEditing(false);
                    }}
                  >
                    <Close style={{ fontSize: "20px" }} />
                  </IconButton>
                </CustomTooltip>
              </Grid>
            </>
          ) : (
            <>
              {/* Display Mode */}
              <Grid item xs={isSm ? 3 : 1}>
                <Typography>{formatMonthDate(time.workDate)}</Typography>
              </Grid>
              <Grid item xs={isSm ? 9 : 2.5}>
                {/* Fix: Render matter's name, not the object */}
                <Typography>{time.matter?.name || "Unknown Matter"}</Typography>
              </Grid>
              <Grid item xs={isSm ? 12 : 6}>
                <Typography>{time.workDescription}</Typography>
              </Grid>

              {showLawyerName && time.user?.fullName && (
                <Grid item xs={isSm ? 4 : 1.5}>
                  <Typography variant="body2">{time.user?.fullName}</Typography>
                </Grid>
              )}

              <Grid item xs={isSm ? 3 : 0.8}>
                <Typography>{time.workHour} h</Typography>
              </Grid>

              {/* Approve Button */}
              {showApprove && isShowingPending && (
                <Grid item xs={isSm ? 2 : 0.6}>
                  <CustomTooltip text="Approve">
                    <IconButton
                      style={{ color: "green" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleApprove(time._id);
                      }}
                    >
                      <Check style={{ fontSize: "20px" }} />
                    </IconButton>
                  </CustomTooltip>
                </Grid>
              )}

              {/* Add Time Duplicate Action */}
              {showDuplicate && !isShowingPending && !showLawyerName && (
                <Grid item xs={isSm ? 4 : 0.6}>
                  <CustomTooltip text="Duplicate">
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDuplicate(time);
                      }}
                    >
                      <BiDuplicate size={15} />
                    </IconButton>
                  </CustomTooltip>
                </Grid>
              )}

              {/* Delete Button */}
              {showDelete && (
                <Grid item xs={isSm ? 3 : 0.6}>
                  <CustomTooltip text="Delete">
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(time._id);
                      }}
                    >
                      <RiDeleteBin6Line size={16} />
                    </IconButton>
                  </CustomTooltip>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Card>
    </CustomTooltip>
  );
};

export default WorkHourCard;
