import React from "react";
import { Tooltip as MuiTooltip } from "@mui/material"; // Import the Tooltip component from MUI

const CustomTooltip = ({ text, children, placement = "top", arrow = true, ...props }) => {
  return (
    <MuiTooltip title={text} placement={placement} arrow={arrow} {...props}>
      {/* Use span only if needed to avoid layout issues */}
      <span>{children}</span>
    </MuiTooltip>
  );
};

export default CustomTooltip;
