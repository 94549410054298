import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RxDotFilled } from "react-icons/rx";
import formatDate, {
  errorAlert,
  successAlert,
  API,
  holidays,
  customStyles,
} from "../../utils/services.js";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { format } from "date-fns";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Select from "react-select";
import "./Calendar.css";
import axiosInstance from "../../helpers/axios.js";
import CustomButton from "../../components/ui/CustomButton.js";
import { useMattersOptions } from "../../helpers/hooks/useMattersOptions.js";
import NoDataFound from "../../components/NoDataFound.js";
import Header from "../../components/ui/Header.js";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";
import { COLORS } from "../../constants/theme.js";
// import TableSkeletonLoader from "../../components/ui/TableSkeletonLoader.js";
import useResponsive from "../../constants/useResponsive.js";
import InfoPopup from "../../components/ui/CalendarInfoPopup.js";
import CustomTooltip from "../../components/ui/CustomTootltip.js";
import InfoIcon from "@mui/icons-material/Info";
import { PlusCircleFilled } from "@ant-design/icons";

const getMonthStartEndDates = (date) => {
  const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return { startDate, endDate };
};

const getWeekendDates = (startDate, endDate) => {
  const weekendDates = [];
  const currentDate = new Date(startDate);
  const formatDateInReverse = (date) => {
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  while (currentDate <= endDate) {
    const day = currentDate.getDay();
    if (day === 0 || day === 6) {
      weekendDates.push(formatDateInReverse(currentDate));
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return weekendDates;
};

const calculateTotals = (searchResult, writeOffHours, selectedDate) => {
  let total = 0;
  let totalBillable = 0;
  let totalWriteoff = 0;

  const matterGroups = {};

  // console.log("Edit data", searchResult, writeOffHours, selectedDate);

  searchResult.forEach((item) => {
    const workHour = parseFloat(item.workHour) || 0;
    total += workHour;

    if (
      item.matter?.pricePerHour?.$numberDecimal !== "0" &&
      item.matter?.resourceSpecificPrice?.[0] !== ""
    ) {
      totalBillable += workHour;
    }

    totalWriteoff = writeOffHours
      .filter((item) => item.workDate === selectedDate)
      .reduce((total, item) => total + parseFloat(item.workHour), 0);

    const matterName = item.matter?.name || "Uncategorized";
    if (!matterGroups[matterName]) {
      matterGroups[matterName] = {
        entries: [],
        total: 0,
      };
    }
    matterGroups[matterName].entries.push(item);
    matterGroups[matterName].total += workHour;
  });

  return {
    total: parseFloat(total).toFixed(2),
    totalBillable: parseFloat(totalBillable).toFixed(2),
    totalWriteoff: parseFloat(totalWriteoff).toFixed(2),
    matterGroups,
  };
};

const MyCalendar = () => {
  const { filteredMatters } = useMattersOptions();
  const auth = useSelector((state) => state.auth);
  const { isSm } = useResponsive();

  // states
  const [searchResult, setSearchResult] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [leaves, setLeaves] = useState([]);
  const [writeOffHours, setWriteOffHours] = useState([]);
  // const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [userWorkHour, setUserWorkHour] = useState([]);
  const [editingTime, setEditingTime] = useState(null);
  const [date, setDate] = useState(new Date());
  const [modalMatter, setModalMatter] = useState("");
  const [modalClient, setModalClient] = useState("");
  const [modalSelectedMatterName, setModalSelectedMatterName] = useState("");
  const [workDescription, setWorkDescription] = useState("");
  const [comments, setComments] = useState("");
  const [workHour, setWorkHour] = useState("0");
  const [workMin, setWorkMin] = useState("30");
  const [entryId, setEntryId] = useState("");
  const [activeStartDate, setActiveStartDate] = useState(new Date());
  const [lastSentTSDate, setLastSentTSDate] = useState(null);

  const totalWorkHour = useMemo(
    () => userWorkHour.concat(writeOffHours || ""),
    [userWorkHour, writeOffHours]
  );

  const { startDate, endDate } = useMemo(
    () => getMonthStartEndDates(activeStartDate),
    [activeStartDate]
  );

  const formattedStartDate = useMemo(() => formatDate(startDate), [startDate]);
  const formattedEndDate = useMemo(() => formatDate(endDate), [endDate]);

  const weekendDates = useMemo(
    () => getWeekendDates(startDate, endDate),
    [startDate, endDate]
  );

  const fetchData = useCallback(async () => {
    // setLoading(true);
    try {
      const workHoursResponse = await axiosInstance.get(
        `${API}/workHour/getWorkHourByUserId`,
        {
          params: {
            fromDate: formattedStartDate,
            toDate: formattedEndDate,
            limit: 0,
            page: 0,
          },
        }
      );
      const writeOffResponse = await axiosInstance.get(
        `${API}/writeoffs/getWriteoffHoursByResource`,
        {
          params: {
            fromDate: formattedStartDate,
            toDate: formattedEndDate,
            limit: 0,
            page: 0,
          },
        }
      );
      const leavesResponse = await axiosInstance.get(
        `${API}/leaves/my-leaves`,
        {
          params: {
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            limit: 0,
            page: 0,
          },
        }
      );

      setUserWorkHour(
        workHoursResponse.data.workHours.filter((e) => e.reviewed === true)
      );
      setWriteOffHours(writeOffResponse.data.writeoffHours);
      setLeaves(leavesResponse.data.leavesWithDays);
      // setLoading(false);
    } catch (error) {
      // console.log(error);
      // setLoading(false);
      errorAlert(error.response.data.error);
    }
  }, [formattedStartDate, formattedEndDate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSearch = useCallback(
    (text) => {
      if (!text) {
        setSearchResult([]);
        return;
      }

      const searchedData = userWorkHour.filter((query) =>
        query.workDate.includes(text)
      );

      // const searchedData2 = writeOffHours.filter((query) =>
      //   query.workDate.includes(text)
      // );

      setSearchResult(searchedData);
      // setSearchWriteoffResult(searchedData2);
    },
    [userWorkHour]
  );

  useEffect(() => {
    handleSearch(selectedDate);
  }, [userWorkHour, selectedDate, handleSearch]);

  useEffect(() => {
    setSelectedDate(formatDate(new Date()));
  }, []);

  const { total, totalBillable, totalWriteoff, matterGroups } = calculateTotals(
    searchResult,
    writeOffHours,
    selectedDate
  );

  const todayLeaves = leaves.filter(
    (leave) =>
      leave.leaveStartDate <= selectedDate && selectedDate <= leave.leaveEndDate
  );

  const handleDateClick = (date) => {
    setSelectedDate(formatDate(date));
  };

  const getMarkedDates = (appointments, leaves, holidays, weekendDates) => {
    const markedDates = {};
    const totalWorkHoursPerDate = appointments.reduce((result, entry) => {
      const { workDate, workHour } = entry;
      const parsedWorkHour = parseFloat(workHour);
      if (parsedWorkHour >= 0) {
        if (result[workDate]) {
          result[workDate] += parsedWorkHour;
        } else {
          result[workDate] = parsedWorkHour;
        }
      }
      return result;
    }, {});

    Object.keys(totalWorkHoursPerDate).forEach((date) => {
      let formattedDate;
      try {
        formattedDate = formatDate(date);
      } catch (error) {
        console.error("date error", error);
        return;
      }

      const workHours = totalWorkHoursPerDate[date].toFixed(2);
      let dotColor;
      if (workHours >= 9) {
        dotColor = "green";
      } else if (workHours >= 3.5) {
        dotColor = "orange";
      } else if (workHours > 0) {
        dotColor = "red";
      }

      markedDates[formattedDate] = { marked: true, dotColor };
    });

    weekendDates.forEach((weekendDate) => {
      const formattedWeekendDate = format(new Date(weekendDate), "yyyy-MM-dd");
      if (totalWorkHoursPerDate[formattedWeekendDate]) {
        markedDates[formattedWeekendDate] = {
          marked: true,
          dotColor: "green",
        };
      } else {
        markedDates[formattedWeekendDate] = {
          marked: true,
          dotColor: "white",
        };
      }
    });

    leaves.forEach((leave) => {
      const startDate = new Date(leave.leaveStartDate);
      const endDate = new Date(leave.leaveEndDate);
      while (startDate <= endDate) {
        const formattedDate = format(startDate, "yyyy-MM-dd");
        markedDates[formattedDate] = { marked: true, dotColor: "green" };
        startDate.setDate(startDate.getDate() + 1);
      }
    });

    holidays.forEach((holiday) => {
      markedDates[holiday] = { marked: true, dotColor: "skyblue" };
    });

    return markedDates;
  };

  const markedDates = useMemo(
    () => getMarkedDates(totalWorkHour, leaves, holidays, weekendDates),
    [totalWorkHour, leaves, weekendDates]
  );

  const renderDot = useCallback(
    ({ date, view }) => {
      if (view === "month") {
        const utcDate = new Date(
          date.getTime() - date.getTimezoneOffset() * 60000
        );
        const dateString = utcDate.toISOString().split("T")[0];
        const isFutureDate = date > new Date();
        const isLeaveApplied = leaves.some(
          (leave) =>
            leave.leaveStartDate <= dateString &&
            dateString <= leave.leaveEndDate
        );
        const isFutureHoliday =
          holidays.some((holiday) => new Date(holiday) > new Date()) &&
          holidays.includes(dateString);

        let dotColor;

        if (isLeaveApplied) {
          dotColor = "green";
        } else if (isFutureDate && !isFutureHoliday) {
          dotColor = "white";
        } else {
          const dotInfo = markedDates[dateString];
          dotColor = dotInfo?.dotColor || "red";
        }

        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {<RxDotFilled size={25} style={{ color: dotColor }} />}
          </div>
        );
      }

      return null;
    },
    [leaves, markedDates]
  );

  const openEditModal = (time, type) => {
    setShowEditModal(true);
    if (type === "add") {
      setEditingTime(null);
      setDate(selectedDate);
      setWorkDescription("");
      setModalMatter("");
      setModalSelectedMatterName("");
      setComments("");
      setWorkHour(0);
      setWorkMin(30);
    } else {
      setEditingTime(time);
      setDate(time.workDate);
      setEntryId(time._id);
      setWorkDescription(time.workDescription);
      setModalMatter(time.matter);
      setModalSelectedMatterName(time.matter.name);
      setComments(time.comments);
      const hour = time.workHour.split(".");
      setWorkHour(hour[0]);
      setWorkMin(Math.floor((hour[1] * 6) / 10));
    }
  };

  const closeEditModal = () => {
    setShowEditModal(false);
    setDate(new Date());
    setWorkDescription("");
    setModalMatter("");
    setModalSelectedMatterName("");
    setModalClient("");
    setComments("");
    setWorkHour(0);
    setWorkMin(30);
    setEntryId("");
  };

  const isValidObjField = () => {
    return !(
      date === "" ||
      modalMatter === "" ||
      workDescription === "" ||
      workHour === "00" ||
      workMin === "00"
    );
  };

  const isValidForm = () => {
    if (!isValidObjField()) return errorAlert("All Fields are Required!");
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const time = {
      admin: auth.user.company,
      user: auth.user._id,
      workDate: date,
      matter: modalMatter,
      workDescription,
      comments,
      workHour: `${workHour}.${workMin * (10 / 6)}`,
      category: "billable",
      reviewed: true,
    };

    if (isValidForm()) {
      if (editingTime) {
        try {
          const response = await axiosInstance.put(
            `${API}/workHour/update/${entryId}`,
            time
          );
          if (response.data) {
            successAlert("Time Updated Successfully!");
            setUserWorkHour((prevUserWorkHour) => {
              const updatedWorkHour = prevUserWorkHour.map((entry) =>
                entry._id === entryId ? response.data : entry
              );
              return updatedWorkHour;
            });
            closeEditModal();
            fetchData();
          }
        } catch (error) {
          console.error("Error updating time:", error);
          errorAlert("Error updating time");
        }
      } else {
        try {
          const response = await axiosInstance.post(
            `${API}/workHour/createNew`,
            time
          );
          if (response.data) {
            successAlert("Great !! Time Added Successfully!");
            closeEditModal();
            fetchData();
          }
        } catch (error) {
          console.error("Error adding time:", error);
          errorAlert("Error adding time");
        }
      }
    }
  };

  const tileClassName = ({ date }) => {
    if (date.getDay() === 0 || date.getDay() === 6) {
      return "react-calendar__tile--weekend";
    }
    return "";
  };

  const handleMatterChange = async (option) => {
    try {
      // console.log("Matter id", option);
      const response = await axiosInstance.get(
        `${API}/matter/getMatterByMatterId/${option}`
      );
      // const matterDetails = response.data;
      // console.log("Matter details", matterDetails);
      setLastSentTSDate(response.data.matterDetails.lastSentTSDate);

      // console.log("lastSentTSDate", response.data.matterDetails.lastSentTSDate);
    } catch (error) {
      console.error("Error fetching matter details:", error);
    }
  };

  // Function to handle the info icon click and open the popup
  const [openInfo, setOpenInfo] = React.useState(false);

  const handleInfoClick = () => {
    setOpenInfo(true);
  };

  const handleInfoClose = () => {
    setOpenInfo(false);
  };

  // Add event listener to close modal on outside click
  useEffect(() => {
    if (showEditModal) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }

    // Cleanup event listener
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showEditModal]);

  // Close modal when clicking outside of it
  const handleOutsideClick = (e) => {
    if (e.target.className === "modal") {
      setShowEditModal(false);
    }
  };

  // State to keep track of the deleted item
  const [deletedItem, setDeletedItem] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // Delete handler
  const handleSoftDelete = async (entryId) => {
    try {
      const response = await fetch(`${API}/workhour/softdelete/${entryId}`, {
        method: "DELETE",
      });
      const data = await response.json();

      console.log("Soft delete response:", data);

      if (response.ok) {
        setDeletedItem({ entryId, ...data });
        // Show the undo alert
        // undoAlert("Workhour deleted.", handleUndo);
        setSnackbarOpen(true);
        console.log("Deleted Item State:", { entryId, ...data }); // Debug the deleted item state
        fetchData();
      } else {
        errorAlert("Failed to delete workhour.");
      }
    } catch (error) {
      errorAlert("An error occurred while deleting workhour.");
    }
  };

  // Undo handler
  const handleUndo = async () => {
    try {
      if (deletedItem) {
        const response = await fetch(
          `${API}/workhour/restore/${deletedItem.entryId}`,
          {
            method: "POST",
          }
        );
        await response.json();

        if (response.ok) {
          // Remove the item from temporary state
          setDeletedItem(null);
          successAlert("Workhour restored successfully.");
          fetchData();
        } else {
          errorAlert("Failed to restore workhour.");
        }
      }
    } catch (error) {
      errorAlert("An error occurred while restoring workhour.");
    }
  };

  return (
    <>
      <Header />
      <ToastContainer />

      {/* Snackbar for Undo Action */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }} // Change the position here
        open={snackbarOpen}
        autoHideDuration={10000} // Hide after 10 seconds if no action is taken
        onClose={() => setSnackbarOpen(false)}
        message="Workhour deleted"
        action={
          <Button color="secondary" size="small" onClick={handleUndo}>
            UNDO
          </Button>
        }
      />

      {showEditModal && (
        <div className="modal">
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <Typography variant="h5" mb={2} align="center">
              {editingTime ? "Edit Time" : "Add Time"}
            </Typography>

            <form className="flex flex-col gap-3">
              <div className="flex align-middle gap-4 w-full">
                <div style={{ width: "50%" }}>
                  <div style={{ width: "100%" }}>
                    <input
                      type="date"
                      value={date}
                      min={lastSentTSDate || ""}
                      onChange={(e) => setDate(e.target.value)}
                    />
                  </div>
                </div>

                <div style={{ width: "50%" }}>
                  <div style={{ width: "100%" }}>
                    {modalSelectedMatterName}
                    <Select
                      options={filteredMatters}
                      value={filteredMatters.find(
                        (option) =>
                          option.label ===
                          `${modalSelectedMatterName} | ${modalClient}`
                      )}
                      onChange={(e) => {
                        setModalMatter(e.value);
                        setModalSelectedMatterName(e.label.split("|")[0]);
                        setModalClient(e.label.split("|")[1]);
                        handleMatterChange(e.value);
                      }}
                      isSearchable
                      placeholder="Select Matter"
                      styles={customStyles}
                    />
                  </div>
                </div>
              </div>

              <div className="flex gap-4 w-full">
                <div style={{ width: "50%" }}>
                  <select
                    value={workHour}
                    name="workHour"
                    onChange={(event) => {
                      setWorkHour(event.target.value);
                    }}
                  >
                    <option value="">Select Work Hour *</option>
                    {Array.from({ length: 19 }, (_, i) => (
                      <option key={i} value={i}>
                        {i}
                      </option>
                    ))}
                  </select>
                </div>

                <div style={{ width: "50%" }}>
                  <select
                    value={workMin}
                    name="workMin"
                    onChange={(event) => {
                      setWorkMin(event.target.value);
                    }}
                  >
                    <option value="">Select Work Minutes *</option>
                    {Array.from({ length: 10 }, (_, i) => (
                      <option key={i * 6} value={i * 6}>
                        {i * 6}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div>
                <textarea
                  style={{ height: 80, width: "100%" }}
                  type="text"
                  placeholder="Work description"
                  value={workDescription}
                  onChange={(e) => setWorkDescription(e.target.value)}
                />
              </div>

              <div>
                <input
                  type="text"
                  placeholder="Comments for the reviewer"
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                />
              </div>

              <div className="flex justify-center align-middle gap-3">
                <CustomButton onClick={handleSubmit}>Submit</CustomButton>
                <CustomButton
                  variant="outlined"
                  style={{ width: "30%" }}
                  onClick={closeEditModal}
                >
                  Cancel
                </CustomButton>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* HEADING AND BUTTONS  */}
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        paddingX={isSm ? 2 : 5}
        sx={{
          marginTop: 2,
        }}
        gap={2}
      >
        {/* Heading with Info Icon */}
        <Grid item>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="h5">Explore Your Calendar</Typography>
            <CustomTooltip title="More Info">
              <IconButton onClick={handleInfoClick}>
                <InfoIcon />
              </IconButton>
            </CustomTooltip>
          </Box>
        </Grid>

        {/* InfoPopup Component */}
        <InfoPopup open={openInfo} handleClose={handleInfoClose} />

        {/* Buttons on the Right */}
        <Grid item>
          <Box display="flex" gap={2}>
            {/* Add Time Button */}
            <CustomButton
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: COLORS.primary,
                color: "white",
                fontWeight: 600,
                padding: "10px 15px",
                borderRadius: "30px",
                width: "auto",
              }}
              onClick={() => openEditModal(null, "add")}
            >
              Add Time
              <PlusCircleFilled
                style={{ fontSize: "20px", marginLeft: "10px" }}
              />
            </CustomButton>

            {/* Leave Button */}
            {todayLeaves.length !== 0 && (
              <CustomButton
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: "green",
                  color: "white",
                  fontWeight: 600,
                  padding: "10px 15px",
                  borderRadius: "30px",
                  width: "auto",
                }}
              >
                Leave Applied!!
              </CustomButton>
            )}
          </Box>
        </Grid>
      </Grid>

      {/* CALENDAR AND ENTIRES CONTAINER */}
      <Grid container spacing={2} paddingX={isSm ? 2 : 5} paddingY={2}>
        {/* LEFT CALENDAR */}
        <Grid item xs={12} sm={12} md={3.5} xl={2.4}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            display="flex"
            justifyContent="center"
          >
            <Calendar
              onClickDay={handleDateClick}
              onActiveStartDateChange={({ activeStartDate }) => {
                setActiveStartDate(activeStartDate);
              }}
              tileContent={renderDot}
              tileClassName={tileClassName}
            />
          </Grid>
        </Grid>

        {/* RIGHT ENTIRES CONTAINER */}
        <Grid item xs={12} sm={12} md={8.5} xl={9.6}>
          <Paper className="entries-container" style={{ borderRadius: 2 }}>
            <div id="entries-heading-data-container">
              {parseFloat(total + totalWriteoff) !== 0 && (
                <Grid
                  container
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={6} md={2.5}>
                    <div id="button-design">
                      <Typography variant="body2">
                        Total : {parseFloat(total + totalWriteoff).toFixed(1)}{" "}
                        hrs
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <div id="button-design">
                      <Typography variant="body2">
                        Billable : {parseFloat(totalBillable).toFixed(1)} hrs
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={3.5}>
                    <div id="button-design">
                      <Typography variant="body2">
                        Non-Billable :{" "}
                        {parseFloat(total - totalBillable).toFixed(1)} hrs
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <div id="button-design">
                      <Typography variant="body2">
                        Discount : {parseFloat(totalWriteoff).toFixed(1)} hrs
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              )}
            </div>

            {searchResult.length === 0 && (
              <NoDataFound message="Oops!!....No WorkHours found." />
            )}

            {searchResult?.length !== 0 && (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <b>Matter</b>
                      </TableCell>
                      <TableCell>
                        <b>Narration</b>
                      </TableCell>
                      <TableCell>
                        <b>WorkHours</b>
                      </TableCell>
                      <TableCell>
                        <b>Edit</b>
                      </TableCell>
                      <TableCell>
                        <b>Delete</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {
                      // loading ? (
                      //   <TableSkeletonLoader rows={5} colSpan={11} />
                      // ) : (
                      Object.keys(matterGroups).map((matterName) => (
                        <React.Fragment key={matterName}>
                          {matterGroups[matterName].entries.map((entry) => (
                            <TableRow key={entry._id}>
                              <TableCell>{entry.matter?.name}</TableCell>
                              <TableCell>{entry.workDescription}</TableCell>
                              <TableCell>
                                {entry.workHour
                                  ? entry.workHour
                                  : entry.amount?.$numberDecimal}
                              </TableCell>
                              <TableCell>
                                <CiEdit
                                  style={{ cursor: "pointer" }}
                                  onClick={() => openEditModal(entry, "edit")}
                                />
                              </TableCell>
                              <TableCell>
                                <RiDeleteBin6Line
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleSoftDelete(entry._id)}
                                />
                              </TableCell>
                            </TableRow>
                          ))}

                          <TableRow>
                            <TableCell className=" bg-[#f8f8f8]" colSpan={2}>
                              <strong>Total for {matterName}</strong>
                            </TableCell>
                            <TableCell className=" bg-[#f8f8f8]">
                              <strong>
                                {parseFloat(
                                  matterGroups[matterName].total
                                ).toFixed(2)}
                              </strong>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            )}

            {todayLeaves.length !== 0 && <hr />}
            {todayLeaves.length !== 0 && (
              <TableContainer>
                {/* Centering Leave Details */}
                <Typography
                  variant="h5"
                  align="center"
                  marginY={2}
                  gutterBottom
                >
                  Leave Details
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <b>Leave Type</b>
                      </TableCell>
                      <TableCell>
                        <b>Leave Days</b>
                      </TableCell>
                      <TableCell>
                        <b>Leave Status</b>
                      </TableCell>
                      <TableCell className="table-cell">
                        <b>Narration</b>
                      </TableCell>
                      {/* <TableCell>
                        <b>Edit</b>
                      </TableCell>
                      <TableCell>
                        <b>Delete</b>
                      </TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {todayLeaves.map((leave) => (
                      <TableRow key={leave._id}>
                        <TableCell>
                          {" "}
                          {leave.leaveType === "workFromHome"
                            ? "Work From Home"
                            : leave.leaveType === "quarterDay"
                            ? "Quarter Day"
                            : leave.leaveType === "halfDay"
                            ? "Half Day"
                            : "Leave"}
                        </TableCell>
                        <TableCell>{leave.leaveDays}</TableCell>
                        <TableCell
                          style={{
                            color:
                              leave.leaveStatus === "approved"
                                ? "green"
                                : leave.leaveStatus === "pending"
                                ? "orange"
                                : "red",
                            fontWeight: "bold",
                            padding: "10px",
                          }}
                        >
                          {leave.leaveStatus === "pending"
                            ? "Pending"
                            : leave.leaveStatus === "approved"
                            ? "Approved"
                            : "Rejected"}
                        </TableCell>
                        <TableCell className="table-cell">
                          {leave.leaveDescription}
                        </TableCell>
                        {/* <TableCell>
                          <CiEdit
                            style={{ cursor: "pointer" }}
                            onClick={() => openLeaveModal(leave, "edit")}
                          />
                        </TableCell>
                        <TableCell>
                          <RiDeleteBin6Line
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDeleteLeave(leave._id)}
                          />
                        </TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default MyCalendar;
