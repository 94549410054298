import React, { useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Grid,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Download } from "@mui/icons-material";
import CustomTooltip from "./ui/CustomTootltip";
import CustomButton from "./ui/CustomButton";
import NoDataFound from "./NoDataFound";
import PricesPopover from "./ui/PricesPopover";
import { RiDeleteBin6Line } from "react-icons/ri";
import { CiEdit } from "react-icons/ci";

const ReusableTable = ({
  componentName,
  columns,
  data,
  rowsPerPage,
  page,
  onAdd,
  onEdit,
  onDelete,
  loading,
  totalCount,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const getFilteredData = () => {
    let filteredData = data;

    if (searchQuery) {
      switch (componentName.toLowerCase()) {
        case "clients":
          filteredData = data.filter((item) =>
            item.name.toLowerCase().includes(searchQuery.toLowerCase())
          );
          break;
        case "resources":
          filteredData = data.filter(
            (item) =>
              item.fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
              item.designation.toLowerCase().includes(searchQuery.toLowerCase())
          );
          break;
        default:
          filteredData = data.filter(
            (item) =>
              item.matter.toLowerCase().includes(searchQuery.toLowerCase()) ||
              item.client.toLowerCase().includes(searchQuery.toLowerCase())
          );
          break;
      }
    }

    return filteredData;
  };

  const filteredData = getFilteredData();

  return (
    <Paper sx={{ width: "91vw", overflow: "hidden" }}>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100, 500]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <TableContainer sx={{ maxHeight: "75vh" }}>
        <Table stickyHeader aria-label="reusable table">
          <TableHead>
            <TableRow>
              <TableCell
                style={{ backgroundColor: "white" }}
                colSpan={columns.length + 1}
              >
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid
                    item
                    sm={4}
                    md={4}
                    lg={4}
                    display="flex"
                    gap={2}
                    alignItems="center"
                  >
                    <Typography variant="h5" align="center">
                      {componentName}
                    </Typography>

                    <CustomTooltip text="Add">
                      <CustomButton
                        style={{
                          borderRadius: "50%",
                          minWidth: "5px",
                          height: "30px",
                          marginBottom: "7px",
                          transition:
                            "transform 0.3s ease-in-out, background-color 0.3s ease",
                          "&:hover": {
                            backgroundColor: "#886a47",
                            transform: "scale(1.1)",
                          },
                        }}
                        onClick={() => onAdd(null)}
                      >
                        <Add />
                      </CustomButton>
                    </CustomTooltip>
                  </Grid>
                  <Grid item sm={6} md={8} lg={8} alignSelf="flex-end">
                    <TextField
                      fullWidth
                      label={
                        componentName.toLowerCase() === "resources"
                          ? "Search for Resources and Designation"
                          : componentName.toLowerCase() === "matters"
                          ? "Search by Matters and Client Name"
                          : componentName.toLowerCase() === "clients"
                          ? "Search by Client"
                          : "Search"
                      }
                      variant="outlined"
                      size="small"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      InputProps={{
                        style: { borderRadius: 25 },
                      }}
                      sx={{ backgroundColor: "white", borderRadius: 2 }}
                    />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {loading ? (
              // Render skeleton loaders
              Array.from(new Array(5)).map((_, index) => (
                <TableRow key={index}>
                  <TableCell colSpan={columns.length}>
                    <Skeleton
                      variant="rectangular"
                      height={30}
                      animation="wave"
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : filteredData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length + 2}>
                  <NoDataFound />
                </TableCell>
              </TableRow>
            ) : (
              filteredData.map((row, index) => (
                <TableRow
                  role="checkbox"
                  tabIndex={-1}
                  key={row.key}
                  sx={{
                    backgroundColor: "white", // Remove row background coloring based on status
                    fontFamily: "Nunito Sans, sans-serif !important",
                  }}
                >
                  {columns.map((column) => {
                    const value = row[column.id];

                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ fontSize: "12px" }}
                      >
                        {column.id === "price" ? (
                          Array.isArray(value) ? (
                            // Show PricesPopover if value is an array
                            <PricesPopover prices={value} />
                          ) : (
                            // Show fixed price directly
                            value
                          )
                        ) : column.id === "status" ? (
                          // Conditionally style the status text
                          <span
                            style={{
                              color:
                                value === "active" || value === "enable"
                                  ? "green"
                                  : value === "inActive" || value === "disable"
                                  ? "red"
                                  : "", // Green for active or enable, red for inactive or disable
                              fontWeight: "bold",
                            }}
                          >
                            {value}
                          </span>
                        ) : column.id === "edit" ? (
                          <IconButton onClick={() => onEdit(row)}>
                            <CiEdit />
                          </IconButton>
                        ) : column.id === "delete" ? (
                          <IconButton onClick={() => onDelete(row.id)}>
                            <RiDeleteBin6Line />
                          </IconButton>
                        ) : column.id === "engagementLetter" ? (
                          value?.length !== 0 && (
                            <a
                              href={value}
                              download
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton>
                                <Download />
                              </IconButton>
                            </a>
                          )
                        ) : column.format && typeof value === "number" ? (
                          column.format(value)
                        ) : (
                          value
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ReusableTable;

// import React, { useState } from "react";
// import {
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TablePagination,
//   TableRow,
//   IconButton,
//   Grid,
//   Skeleton,
//   TextField,
//   Typography,
// } from "@mui/material";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
// import { Add, Download } from "@mui/icons-material";
// import CustomTooltip from "./ui/CustomTootltip";
// import CustomButton from "./ui/CustomButton";
// import NoDataFound from "./NoDataFound";

// const ReusableTable = ({
//   componentName,
//   columns,
//   data,
//   rowsPerPage,
//   page,
//   onAdd,
//   onEdit,
//   onDelete,
//   loading,
//   totalCount,
//   handleChangePage,
//   handleChangeRowsPerPage,
// }) => {
//   const [searchQuery, setSearchQuery] = useState("");

//   const handleSearchChange = (event) => {
//     setSearchQuery(event.target.value);
//   };

//   const getFilteredData = () => {
//     let filteredData = data;

//     if (searchQuery) {
//       switch (componentName.toLowerCase()) {
//         case "clients":
//           filteredData = data.filter((item) =>
//             item.name.toLowerCase().includes(searchQuery.toLowerCase())
//           );
//           break;
//         case "resources":
//           filteredData = data.filter(
//             (item) =>
//               item.fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
//               item.designation.toLowerCase().includes(searchQuery.toLowerCase())
//           );
//           break;
//         default:
//           filteredData = data.filter(
//             (item) =>
//               item.matter.toLowerCase().includes(searchQuery.toLowerCase()) ||
//               item.client.toLowerCase().includes(searchQuery.toLowerCase())
//           );
//           break;
//       }
//     }

//     return filteredData;
//   };

//   const filteredData = getFilteredData();

//   return (
//     <Paper sx={{ width: "91vw", overflow: "hidden" }}>
//       <TablePagination
//         rowsPerPageOptions={[10, 25, 50, 100, 500]}
//         component="div"
//         count={totalCount}
//         rowsPerPage={rowsPerPage}
//         page={page}
//         onPageChange={handleChangePage}
//         onRowsPerPageChange={handleChangeRowsPerPage}
//       />
//       <TableContainer sx={{ maxHeight: "73vh" }}>
//         <Table stickyHeader aria-label="reusable table">
//           <TableHead>
//             <TableRow>
//               <TableCell colSpan={columns.length + 1}>
//                 <Grid
//                   container
//                   alignItems="center"
//                   justifyContent="space-between"
//                 >
//                   <Grid
//                     item
//                     sm={4}
//                     md={4}
//                     lg={4}
//                     display="flex"
//                     gap={2}
//                     alignItems="center"
//                   >
//                     <Typography variant="h5" align="center">
//                       {componentName}
//                     </Typography>

//                     <CustomTooltip text="Add">
//                       <CustomButton
//                         style={{
//                           borderRadius: "50%",
//                           minWidth: "5px",
//                           height: "30px",
//                           marginBottom: "7px",
//                           transition:
//                             "transform 0.3s ease-in-out, background-color 0.3s ease",
//                           "&:hover": {
//                             backgroundColor: "#886a47",
//                             transform: "scale(1.1)",
//                           },
//                         }}
//                         onClick={() => onAdd(null)}
//                       >
//                         <Add />
//                       </CustomButton>
//                     </CustomTooltip>
//                   </Grid>
//                   <Grid item sm={6} md={8} lg={8} alignSelf="flex-end">
//                     <TextField
//                       fullWidth
//                       label={
//                         componentName.toLowerCase() === "resources"
//                           ? "Search for Resources and Designation"
//                           : componentName.toLowerCase() === "matters"
//                           ? "Search by Matters and Client Name"
//                           : componentName.toLowerCase() === "clients"
//                           ? "Search by Client"
//                           : "Search"
//                       }
//                       variant="outlined"
//                       size="small"
//                       value={searchQuery}
//                       onChange={handleSearchChange}
//                       InputProps={{
//                         style: { borderRadius: 25 },
//                       }}
//                       sx={{ backgroundColor: "white", borderRadius: 2 }}
//                     />
//                   </Grid>
//                 </Grid>
//               </TableCell>
//             </TableRow>
//             <TableRow>
//               {columns.map((column) => (
//                 <TableCell
//                   key={column.id}
//                   align={column.align}
//                   sx={{
//                     fontWeight: "bold",
//                   }}
//                 >
//                   {column.label}
//                 </TableCell>
//               ))}
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {loading ? (
//               // Render skeleton loaders
//               Array.from(new Array(5)).map((_, index) => (
//                 <TableRow key={index}>
//                   <TableCell colSpan={columns.length}>
//                     <Skeleton
//                       variant="rectangular"
//                       height={30}
//                       animation="wave"
//                     />
//                   </TableCell>
//                 </TableRow>
//               ))
//             ) : filteredData.length === 0 ? (
//               <TableRow>
//                 <TableCell colSpan={columns.length + 2}>
//                   <NoDataFound />
//                 </TableCell>
//               </TableRow>
//             ) : (
//               filteredData.map((row, index) => (
//                 <TableRow
//                   role="checkbox"
//                   tabIndex={-1}
//                   key={row.key}
//                   sx={{
//                     backgroundColor: "white",
//                     ...(row.status === "active"
//                       ? { backgroundColor: "#e8f5e9" }
//                       : {}),
//                     ...(row.status === "inActive"
//                       ? { backgroundColor: "#ffebee" }
//                       : {}),
//                     fontFamily: "Nunito Sans, sans-serif !important",
//                   }}
//                 >
//                   {columns.map((column) => {
//                     const value = row[column.id];

//                     return (
//                       <TableCell
//                         key={column.id}
//                         align={column.align}
//                         style={{ fontSize: "12px" }}
//                       >
//                         {column.id === "price" ? (
//                           Array.isArray(value) ? (
//                             value.map((priceString, index) => {
//                               const [name, price] = priceString.split(": ");
//                               return (
//                                 <div
//                                   key={index}
//                                   style={{ marginBottom: "0.3em" }}
//                                 >
//                                   <strong>{name}:</strong> {price}
//                                 </div>
//                               );
//                             })
//                           ) : (
//                             value
//                           ) // This will render the value as is, only when it's not an array
//                         ) : column.id === "edit" ? (
//                           <IconButton onClick={() => onEdit(row)}>
//                             <EditIcon />
//                           </IconButton>
//                         ) : column.id === "delete" ? (
//                           <IconButton onClick={() => onDelete(row.id)}>
//                             <DeleteIcon />
//                           </IconButton>
//                         ) : column.id === "engagementLetter" ? (
//                           value?.length !== 0 && (
//                             <a
//                               href={value}
//                               download
//                               target="_blank"
//                               rel="noopener noreferrer"
//                             >
//                               <IconButton>
//                                 <Download />
//                               </IconButton>
//                             </a>
//                           )
//                         ) : column.format && typeof value === "number" ? (
//                           column.format(value)
//                         ) : (
//                           value
//                         )}
//                       </TableCell>
//                     );
//                   })}
//                 </TableRow>
//               ))
//             )}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </Paper>
//   );
// };

// export default ReusableTable;
