import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  TablePagination,
  Typography,
} from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { getWorkHourByUserId } from "../../actions";
import CustomButton from "../../components/ui/CustomButton";
import formatDate, {
  API,
  // ConfirmationAlert,
  customStyles,
  errorAlert,
  formatMonthDate,
  successAlert,
} from "../../utils/services";
import { Add, Remove } from "@mui/icons-material"; // Added Add and Close icons
import axiosInstance from "../../helpers/axios";
// import SkeletonRows from "../../components/ui/CardSkeletonLoader.js";
import { useMattersOptions } from "../../helpers/hooks/useMattersOptions";
import NoDataFound from "../../components/NoDataFound";
import { HiSparkles } from "react-icons/hi2";
import Header from "../../components/ui/Header";
import CustomTooltip from "../../components/ui/CustomTootltip";
import CustomDateRangePicker from "../../components/ui/CustomDateRangePicker";
import { COLORS } from "../../constants/theme";
import WorkHourCard from "../../components/ui/WorkHourCard";
import useResponsive from "../../constants/useResponsive.js";
import { IoCheckmarkCircle } from "react-icons/io5";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TfiReload } from "react-icons/tfi";

// Validation Schema
const validationSchema = Yup.object().shape({
  selectedDate: Yup.string().required("Date is required"),
  matter: Yup.string().required("Matter is required"),
  workDescription: Yup.string().required("Work Description is required"),
  workHour: Yup.string().required("Work Hour is required"),
  workMin: Yup.string().required("Work Minute is required"),
});

const AddTime = () => {
  const dispatch = useDispatch();
  const { isSm } = useResponsive();
  const { filteredMatters } = useMattersOptions();

  const auth = useSelector((state) => state.auth);
  const time = useSelector((state) => state.time);
  const {
    userWorkHour,
    // workHourLoading,
    totalCount,
  } = time;

  const [editingEntry, setEditingEntry] = useState(null);
  const [duplicatingEntry, setDuplicatingEntry] = useState(null);
  const [isTableFullWidth, setIsTableFullWidth] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [startSelectedDate, setStartSelectedDate] = useState(() => {
    const today = new Date();
    const pastDate = new Date(today.setDate(today.getDate() - 7));
    return formatDate(pastDate);
  });
  const [endSelectedDate, setEndSelectedDate] = useState(
    formatDate(new Date())
  );
  const [isShowingPending, setIsShowingPending] = useState(false); // false for Fulfilled, true for Pending
  const [lastSentTSDate, setLastSentTSDate] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(true);

  // Pagination states
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  // AI suggestion state
  const [aiSuggestion, setAiSuggestion] = useState("");

  // Pagination functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    dispatch(
      getWorkHourByUserId(
        startSelectedDate,
        endSelectedDate,
        searchQuery,
        "",
        page,
        rowsPerPage
      )
    );
  }, [
    dispatch,
    auth.user._id,
    auth.user.company,
    page,
    rowsPerPage,
    startSelectedDate,
    endSelectedDate,
    searchQuery,
  ]);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      selectedDate: formatDate(new Date()),
      // matter: "",
      // client: "",
      // selectedMatterName: "",
      workDescription: "",
      comments: "",
      workHour: "",
      workMin: "",
    },
  });

  useEffect(() => {
    const entry = editingEntry || duplicatingEntry;
    if (entry) {
      setValue("selectedDate", formatDate(new Date(entry.workDate)));
      setValue("matter", entry.matter._id);
      setValue("selectedMatterName", entry.matter.name);
      setValue("workDescription", entry.workDescription);
      setValue("comments", entry.comments);

      // Split workHour into hour and minute
      let [hour, min] = entry.workHour.split(".");

      // Ensure hour is set
      setValue("workHour", hour);

      // If min is undefined, set it to "0"; otherwise, check its length
      if (!min) {
        min = "00"; // Default to "00" if no minutes are specified
      } else if (min.length === 1) {
        // If the minute has only one digit, append "0"
        min = min + "0";
      }

      // Convert the corrected min to the nearest 6-minute increment
      const minuteValue = Math.floor((parseFloat(min) * 6) / 10);

      // Set the minute value in the form
      setValue("workMin", minuteValue);
    }
  }, [editingEntry, duplicatingEntry, setValue]);

  // Function to fetch AI suggestion
  const getAiSuggestion = async (workDescription, isDescriptive = false) => {
    try {
      const response = await axiosInstance.post(`${API}/ai/suggest`, {
        workDescription,
        isDescriptive, // Pass the descriptive flag to the backend
      });
      return response.data.aiSuggestedDescription;
    } catch (error) {
      console.error("Error fetching AI suggestion:", error);
      errorAlert("Failed to get AI suggestion. Please try again.");
      return "";
    }
  };

  // Normal submit handler
  const onSubmit = async (data) => {
    if (
      data.selectedDate &&
      data.matter &&
      data.workDescription &&
      data.workHour !== "00" &&
      data.workMin !== "00"
    ) {
      const timeData = {
        admin: auth?.user?.company,
        user: auth?.user?._id,
        workDate: data.selectedDate,
        matter: data.matter,
        workDescription: data.workDescription,
        comments: data.comments,
        workHour: `${data.workHour}.${data.workMin * (10 / 6)}`,
        category: "billable",
        reviewed: true,
      };

      if (editingEntry) {
        // Update the entry
        try {
          const response = await axiosInstance.put(
            `${API}/workHour/update/${editingEntry._id}`,
            timeData
          );

          if (response.data) {
            successAlert("Time Updated Successfully!");
            fetchWorkHours();
            resetForm();
          }
        } catch (error) {
          console.error("Error updating time:", error);
          errorAlert("Error updating time");
        }
      } else {
        // Add or duplicate an entry
        try {
          const response = await axiosInstance.post(
            `${API}/workHour/createNew`,
            timeData
          );

          if (response.data) {
            successAlert("Great !! Time Added Successfully!");
            fetchWorkHours();
            resetForm();
          }
        } catch (error) {
          console.error("Error adding time:", error);
          errorAlert("Error adding time");
        }
      }
    } else {
      errorAlert("All Fields are Required!");
    }
  };

  // Submit with AI suggestion
  const onSubmitWithAiSuggestion = async (data) => {
    if (
      data.selectedDate &&
      data.matter &&
      aiSuggestion &&
      data.workHour !== "00" &&
      data.workMin !== "00"
    ) {
      const timeData = {
        admin: auth?.user?.company,
        user: auth?.user?._id,
        workDate: data.selectedDate,
        matter: data.matter,
        workDescription: aiSuggestion, // Use the AI suggestion
        comments: data.comments,
        workHour: `${data.workHour}.${data.workMin * (10 / 6)}`,
        category: "billable",
        reviewed: true,
      };

      try {
        if (editingEntry) {
          // Update the existing entry with AI suggestion
          const response = await axiosInstance.put(
            `${API}/workHour/update/${editingEntry._id}`,
            timeData
          );

          if (response.data) {
            successAlert("Time Updated Successfully with AI Suggestion!");
            fetchWorkHours();
            resetForm();
          }
        } else {
          // Create a new entry with AI suggestion
          const response = await axiosInstance.post(
            `${API}/workHour/createNew`,
            timeData
          );

          if (response.data) {
            successAlert("Time Added Successfully with AI Suggestion!");
            fetchWorkHours();
            resetForm();
          }
        }
      } catch (error) {
        console.error(
          "Error adding or updating time with AI suggestion:",
          error
        );
        errorAlert("Error adding or updating time with AI suggestion");
      }
    } else {
      errorAlert("All Fields are Required!");
    }
  };

  // Reset form
  const resetForm = () => {
    reset({
      // selectedDate: formatDate(new Date()), // Reset selectedDate to current date
      // matter: "",
      // client: "",
      // selectedMatterName: "",
      workDescription: "",
      comments: "",
      workHour: "",
      workMin: "",
    });
    setEditingEntry(null);
    setDuplicatingEntry(null);
    setAiSuggestion("");
  };

  const handleEdit = () => {
    // setEditingEntry(entry);
    setDuplicatingEntry(null);
    setAiSuggestion("");
    setIsFormVisible(false);
  };

  const toggleFormVisibility = () => {
    setIsFormVisible((prev) => !prev);
    // if (isFormVisible) resetForm(); // Reset form when hiding
  };

  // Get filtered work hours based on the button selected
  const filteredWorkHours = userWorkHour.filter((entry) => {
    if (isShowingPending) {
      // Treat entries as pending if reviewed is false or the field doesn't exist
      return entry.reviewed === false;
    } else {
      // Show only fulfilled entries (reviewed is explicitly true)
      return entry.reviewed === true || entry.reviewed === undefined;
    }
  });

  const handleMatterChange = async (option) => {
    try {
      const response = await axiosInstance.get(
        `${API}/matter/getMatterByMatterId/${option.value}`
      );

      setLastSentTSDate(response.data?.matterDetails?.lastSentTSDate);

      setValue("matter", option.value);
      setValue("selectedMatterName", option.label.split("|")[0]);
      setValue("client", option.label.split("|")[1]);
      trigger("matter");
    } catch (error) {
      console.error("Error fetching matter details:", error);
    }
  };

  const pendingEntries = userWorkHour.filter(
    (entry) => entry.reviewed === false
  );

  //duplicate entries
  const handleDuplicate = (entry) => {
    if (isTableFullWidth) {
      setIsTableFullWidth((prev) => !prev);
    }
    setDuplicatingEntry(entry);
    setEditingEntry(null);
    setIsFormVisible(true);
  };

  // Approve handler function
  const handleApprove = async (entryId) => {
    try {
      const response = await axiosInstance.put(
        `${API}/workHour/update/${entryId}`,
        {
          reviewed: true,
        }
      );

      if (response.data) {
        successAlert("Time Approved Successfully!");
        fetchWorkHours(); // Refresh the work hours after approval
      }
    } catch (error) {
      console.error("Error approving time:", error);
      errorAlert("Error approving time");
    }
  };

  // Fetch work hours after any action
  const fetchWorkHours = () => {
    dispatch(
      getWorkHourByUserId(
        startSelectedDate,
        endSelectedDate,
        searchQuery,
        "",
        page,
        rowsPerPage
      )
    );
  };

  // states and functions for date range picker
  const [selectedDateRange, setSelectedDateRange] = useState({
    start: "",
    end: "",
  });

  // Function to receive selected dates from DateRangePickerTst
  const handleDateRangeChange = (startDate, endDate) => {
    setSelectedDateRange({ start: startDate, end: endDate });
    setStartSelectedDate(startDate);
    setEndSelectedDate(endDate);
    console.log(startDate, endDate);
  };

  // State to keep track of the deleted item
  const [deletedItem, setDeletedItem] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // Delete handler
  const handleSoftDelete = async (entryId) => {
    try {
      const response = await fetch(`${API}/workhour/softdelete/${entryId}`, {
        method: "DELETE",
      });
      const data = await response.json();

      console.log("Soft delete response:", data);

      if (response.ok) {
        setDeletedItem({ entryId, ...data });
        // Show the undo alert
        // undoAlert("Workhour deleted.", handleUndo);
        setSnackbarOpen(true);
        console.log("Deleted Item State:", { entryId, ...data }); // Debug the deleted item state
        fetchWorkHours();
      } else {
        errorAlert("Failed to delete workhour.");
      }
    } catch (error) {
      errorAlert("An error occurred while deleting workhour.");
    }
  };

  // Undo handler
  const handleUndo = async () => {
    try {
      if (deletedItem) {
        const response = await fetch(
          `${API}/workhour/restore/${deletedItem.entryId}`,
          {
            method: "POST",
          }
        );
        await response.json();

        if (response.ok) {
          // Remove the item from temporary state
          setDeletedItem(null);
          successAlert("Workhour restored successfully.");
          fetchWorkHours();
        } else {
          errorAlert("Failed to restore workhour.");
        }
      }
    } catch (error) {
      errorAlert("An error occurred while restoring workhour.");
    }
  };

  const [isRetryingAi, setIsRetryingAi] = useState(false); // New state to track retries

  // AI submit handler
  const onSubmitWithAi = async (data) => {
    if (
      data.selectedDate &&
      data.matter &&
      data.workDescription &&
      data.workHour !== "00" &&
      data.workMin !== "00"
    ) {
      const suggestion = await getAiSuggestion(data.workDescription, false);
      setAiSuggestion(suggestion);
      setIsRetryingAi(false); // Reset retry state
    } else {
      errorAlert("All Fields are Required!");
    }
  };

  // Function to retry AI suggestion
  const retryAiSuggestion = async () => {
    setIsRetryingAi(true); // Set retry state to true
    const currentWorkDescription = watch("workDescription"); // Get the current work description from the form
    const newSuggestion = await getAiSuggestion(currentWorkDescription, false); // Fetch a new suggestion
    setAiSuggestion(newSuggestion); // Update the AI suggestion
    setIsRetryingAi(false); // Reset retry state after fetching
  };

  // Handle saving of edited work hour entry
  const handleSave = async (updatedEntry) => {
    try {
      const response = await axiosInstance.put(
        `${API}/workHour/update/${updatedEntry._id}`,
        { ...updatedEntry, reviewed: true }
      );

      if (response.data) {
        successAlert("Time Updated Successfully!");
        fetchWorkHours(); // Refresh the data after saving
      }
    } catch (error) {
      console.error("Error updating work hour:", error);
      errorAlert("Error updating work hour.");
    }
  };

  return (
    <>
      <ToastContainer />
      <Header />

      {/* Snackbar for Undo Action */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }} // Change the position here
        open={snackbarOpen}
        autoHideDuration={10000} // Hide after 10 seconds if no action is taken
        onClose={() => setSnackbarOpen(false)}
        message="Workhour deleted"
        action={
          <Button color="secondary" size="small" onClick={handleUndo}>
            UNDO
          </Button>
        }
      />

      <Grid
        container
        display="flex"
        justifyContent="center"
        paddingX={isSm ? 2 : 5}
        paddingY={2}
        md={12}
      >
        {/* Header with Heading, Add Button, and Middle Section Buttons */}
        <Grid
          container
          alignItems="center"
          justifyContent={isSm ? "center" : "space-between"}
          marginBottom={3}
          md={12}
          flexDirection="row"
          gap={2}
        >
          {/* Left Section: Heading and Add Button */}
          <Grid
            item
            xs={12}
            sm={6}
            md={2}
            display="flex"
            justifyContent={isSm ? "flex-start" : "flex-start"}
          >
            <Box display="flex" alignItems="center" gap={2}>
              <Typography variant="h5">Time</Typography>
              <CustomTooltip text={isFormVisible ? "Close Form" : "Add Time"}>
                <CustomButton
                  style={{
                    borderRadius: "50%",
                    minWidth: "30px", // Minimum width
                    width: "40px", // Fixed width
                    height: "35px", // Fixed height
                    flexShrink: 0, // Prevents the button from stretching
                    transition:
                      "transform 0.3s ease-in-out, background-color 0.3s ease",
                    "&:hover": {
                      backgroundColor: COLORS.primary,
                      transform: "scale(1.1)",
                    },
                  }}
                  onClick={toggleFormVisibility}
                >
                  {isFormVisible ? <Remove /> : <Add />}
                </CustomButton>
              </CustomTooltip>
            </Box>
          </Grid>

          {/* Middle Section: Search Bar and Date Range Picker */}
          <Grid
            item
            xs={12}
            sm={7}
            md={5}
            display="flex"
            justifyContent={isSm ? "center" : "flex-start"}
          >
            <Grid container alignItems="center" flexDirection="row">
              <Grid item xs={10} sm={10} md={8}>
                <Select
                  options={filteredMatters}
                  value={filteredMatters.find(
                    (option) => option.value === searchQuery
                  )}
                  onChange={(option) => setSearchQuery(option.value)}
                  isSearchable
                  placeholder="Select Matter"
                  styles={customStyles}
                />
              </Grid>

              {/* Date Range Picker */}
              <Grid
                item
                xs={2}
                sm={2}
                md={4}
                display="flex"
                alignItems="center"
                justifyContent={isSm ? "center" : "flex-end"}
              >
                <CustomTooltip text="Select Dates">
                  <CustomDateRangePicker
                    startDate={selectedDateRange.start}
                    endDate={selectedDateRange.end}
                    onDateRangeChange={handleDateRangeChange}
                    iconSize={30}
                    iconColor={COLORS.primary}
                  />
                </CustomTooltip>

                <Typography>
                  {formatMonthDate(startSelectedDate)} -{" "}
                  {formatMonthDate(endSelectedDate)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* Right Section: Buttons for Pending and Final Entries */}
          <Grid
            item
            xs={12}
            sm={4}
            md={3}
            display="flex"
            justifyContent={isSm ? "flex-start" : "flex-end"}
          >
            <Box display="flex" alignItems="center" gap={2}>
              <CustomTooltip text="Entered Entries">
                <CustomButton
                  variant={isShowingPending ? "outlined" : "contained"}
                  onClick={() => setIsShowingPending(false)}
                  style={{
                    minWidth: isSm ? "90px" : "120px",
                    padding: isSm ? "6px 12px" : "10px 20px",
                  }}
                >
                  Entered
                </CustomButton>
              </CustomTooltip>

              <CustomTooltip text="New AI entries are added daily by 10 PM">
                <CustomButton
                  variant={!isShowingPending ? "outlined" : "contained"}
                  onClick={() => setIsShowingPending(true)}
                  style={{
                    minWidth: isSm ? "90px" : "120px",
                    padding: isSm ? "6px 12px" : "10px 20px",
                  }}
                >
                  Maybe?{" "}
                  {pendingEntries.length > 0 && (
                    <span
                      style={{
                        padding: "0px 5px",
                        borderRadius: "50%",
                        height: "22px",
                        backgroundColor: isShowingPending
                          ? "white"
                          : COLORS.primary,
                        color: isShowingPending ? COLORS.primary : "white",
                        fontWeight: "bold",
                        fontSize: isSm ? "12px" : "14px",
                        marginLeft: "10px",
                        animation: "bounce 2s infinite",
                      }}
                    >
                      {pendingEntries.length}
                    </span>
                  )}
                </CustomButton>
              </CustomTooltip>
            </Box>
          </Grid>
        </Grid>

        {/* Form Section */}
        {isFormVisible && (
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            marginBottom={2}
            paddingRight={isSm ? 0 : 2}
          >
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
              {/* Matter and Date  */}
              <Grid container spacing={2} marginBottom={1}>
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="matter"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={filteredMatters}
                        value={filteredMatters.find(
                          (option) => option.value === field.value
                        )}
                        onChange={handleMatterChange}
                        isSearchable
                        placeholder="Select Matter"
                        className={` ${errors.matter ? "formInputError" : ""}`}
                        styles={{
                          ...customStyles, // Spread the global styles
                          control: (base, state) => ({
                            ...customStyles.control(base, state), // Apply global control styles
                            borderColor: errors.matter
                              ? "red"
                              : base.borderColor, // Conditionally add error style
                            "&:hover": {
                              borderColor: errors.matter ? "red" : "#aaaaaa", // Change hover color based on error
                            },
                          }),
                        }}
                      />
                    )}
                  />
                  {errors.matter && (
                    <p className="errorMessage">{errors.matter.message}</p>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <input
                    type="date"
                    className={`formInput ${
                      errors.selectedDate ? `formInputError` : ""
                    }`}
                    {...register("selectedDate", { required: true })}
                    min={lastSentTSDate || ""}
                  />
                  {errors.selectedDate && (
                    <p className="errorMessage">
                      {errors.selectedDate.message}
                    </p>
                  )}
                </Grid>
              </Grid>

              {/* Work Dropdowns  */}
              <Grid container columnSpacing={2} marginBottom={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <select
                    style={{ fontSize: "12px" }}
                    className="formInput"
                    {...register("workHour", {
                      required: "Work Hour is required",
                    })}
                  >
                    <option value="">Select Work Hour *</option>
                    {/* Work Hour Options */}
                    {Array.from({ length: 19 }, (_, i) => (
                      <option key={i} value={i}>
                        {i}
                      </option>
                    ))}
                  </select>
                  {errors.workHour && (
                    <p className="errorMessage">{errors.workHour.message}</p>
                  )}
                </Grid>

                {/* Work Minute Dropdown */}
                <Grid item xs={12} sm={6} md={6}>
                  <select
                    style={{ fontSize: "12px" }}
                    className="formInput"
                    {...register("workMin", {
                      required: "Work Minute is required",
                    })}
                  >
                    <option value="">Select Work Minutes *</option>
                    {/* Work Minute Options */}
                    {Array.from({ length: 10 }, (_, i) => (
                      <option key={i * 6} value={i * 6}>
                        {i * 6}
                      </option>
                    ))}
                  </select>
                  {errors.workMin && (
                    <p className="errorMessage">{errors.workMin.message}</p>
                  )}
                </Grid>
              </Grid>

              {/* Comments  */}
              <Grid container marginBottom={1} flexDirection="column">
                <textarea
                  className="formInput"
                  placeholder="Comments for the reviewer (optional)"
                  style={{ height: "50px", fontStyle: "italic" }}
                  {...register("comments")}
                />
              </Grid>

              {/* Work description  */}
              <Grid container flexDirection="column">
                <textarea
                  className={`formInput ${
                    errors.workDescription ? "formInputError" : ""
                  }`}
                  style={{ height: "120px", fontSize: "12px" }}
                  {...register("workDescription")}
                  value={watch("workDescription")}
                  onChange={(e) => {
                    setValue("workDescription", e.target.value);
                    // setIsAiSuggested(false);
                  }}
                  placeholder="Work Description *"
                />
                {errors.workDescription && (
                  <p className="errorMessage">
                    {errors.workDescription.message}
                  </p>
                )}
              </Grid>

              {/* 4 buttons and AI description  */}
              {aiSuggestion && (
                <>
                  <Grid
                    container
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    marginBottom={1}
                  >
                    <Grid item xs={12} sm={2}>
                      <CustomTooltip
                        text={
                          editingEntry ? "Update Original" : "Submit Original"
                        }
                      >
                        <IconButton
                          onClick={handleSubmit(onSubmit)}
                          style={{ color: "green" }}
                        >
                          <IoCheckmarkCircle style={{ fontSize: "25px" }} />
                        </IconButton>
                      </CustomTooltip>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={10}
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <CustomTooltip text="Delete">
                        <IconButton onClick={resetForm}>
                          <RiDeleteBin6Line
                            style={{ fontSize: "20px", cursor: "pointer" }}
                          />
                        </IconButton>
                      </CustomTooltip>
                    </Grid>
                  </Grid>

                  {/* AI suggested description  */}
                  <Grid>
                    <Typography variant="body2">AI Suggested</Typography>
                    <textarea
                      onLoadStart={isRetryingAi}
                      className="formInput"
                      style={{ height: "120px", backgroundColor: "#f0f0f0" }}
                      value={isRetryingAi ? "Revising again..." : aiSuggestion}
                      onChange={(e) => setAiSuggestion(e.target.value)}
                      disabled={isRetryingAi}
                    />
                  </Grid>

                  <Grid
                    container
                    flexDirection="row"
                    // justifyContent="center"
                    alignItems="center"
                    marginBottom={1}
                  >
                    <Grid item xs={12} sm={3}>
                      <CustomTooltip
                        text={
                          editingEntry ? "Update Revised" : "Submit Revised"
                        }
                      >
                        <IconButton
                          style={{
                            color: "green",
                            fontSize: "20px",
                            fontWeight: "bolder",
                          }}
                          onClick={handleSubmit(onSubmitWithAiSuggestion)}
                        >
                          <IoCheckmarkDoneSharp
                            style={{ fontSize: "22px", marginRight: "0px" }}
                          />
                          <HiSparkles size={20} color="black" />
                        </IconButton>
                      </CustomTooltip>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <CustomTooltip text="Try again">
                        <IconButton onClick={retryAiSuggestion}>
                          <TfiReload
                            style={{ fontSize: "18px", cursor: "pointer" }}
                          />
                        </IconButton>
                      </CustomTooltip>
                    </Grid>
                  </Grid>
                </>
              )}

              {/* 3 final buttons  */}
              {!aiSuggestion && (
                <Grid container className="flex justify-between">
                  <Grid item xs={12} sm={2}>
                    <CustomTooltip
                      text={
                        editingEntry
                          ? "Update"
                          : duplicatingEntry
                          ? "Duplicate"
                          : "Submit"
                      }
                    >
                      <IconButton type="submit" style={{ color: "green" }}>
                        <IoCheckmarkCircle style={{ fontSize: "30px" }} />
                      </IconButton>
                    </CustomTooltip>
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <CustomTooltip text="Redraft with AI">
                      <IconButton
                        onClick={handleSubmit(onSubmitWithAi)}
                        style={{ color: "green" }}
                      >
                        <IoCheckmarkDoneSharp
                          style={{ fontSize: "25px", marginRight: "0px" }}
                        />
                        <HiSparkles size={22} color="black" />
                      </IconButton>
                    </CustomTooltip>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={8}
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <CustomTooltip text="Reset">
                      <IconButton
                        onClick={handleSubmit(onSubmitWithAi)}
                        style={{ color: "black" }}
                      >
                        <RiDeleteBin6Line
                          style={{ fontSize: "22px", cursor: "pointer" }}
                          onClick={resetForm}
                        />
                      </IconButton>
                    </CustomTooltip>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Grid>
        )}

        {/* WorkHour Cards Section */}
        <Grid
          item
          xs={12}
          sm={12}
          md={isFormVisible ? 8 : 12}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{ maxHeight: "68vh", overflow: "hidden" }}
          marginTop={isSm ? 4 : 0}
        >
          <Paper
            elevation={0}
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            {/* Scrollable Content */}
            <Box
              sx={{
                flex: 1,
                overflowY: "auto",
                // padding: isSm ? 1 : 2,
              }}
            >
              {
                // workHourLoading ? (
                //   <SkeletonRows rows={6} colSpan={11} />
                // ) :
                filteredWorkHours.length === 0 ? (
                  <NoDataFound message="Oops! No Workhours Found" />
                ) : (
                  filteredWorkHours.map((time) => (
                    <WorkHourCard
                      key={time._id}
                      time={time}
                      handleSave={handleSave}
                      isFormVisible={isFormVisible}
                      isShowingPending={isShowingPending}
                      handleApprove={handleApprove}
                      handleDuplicate={handleDuplicate}
                      handleEdit={handleEdit}
                      handleDelete={handleSoftDelete}
                      showApprove={true}
                      showDuplicate={true}
                      showEdit={true}
                      showDelete={true}
                      showLawyerName={false}
                      matters={filteredMatters}
                    />
                  ))
                )
              }
            </Box>

            {/* Pagination */}
            {filteredWorkHours.length !== 0 && (
              <Box>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{ padding: isSm ? "0 8px" : "0 16px" }}
                />
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AddTime;
