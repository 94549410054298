import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import formatDate, {
  API,
  ConfirmationAlert,
  customStyles,
  errorAlert,
  formatLetterDate,
  formatMonthDate,
  successAlert,
} from "../../utils/services";
import Select from "react-select";
import { BiDuplicate, BiSolidHide } from "react-icons/bi";
import { TbDiscount2Off } from "react-icons/tb";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Button,
  TablePagination,
  Typography,
  IconButton,
  Snackbar,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Discount, Download, Search, Update } from "@mui/icons-material";
import axiosInstance from "../../helpers/axios";
import "./Timespent.css";
import CustomButton from "../../components/ui/CustomButton";
import CustomTooltip from "../../components/ui/CustomTootltip";
import { useTSEmployees } from "../../helpers/hooks/useTSEmployees";
import { useMattersOptions } from "../../helpers/hooks/useMattersOptions";
import { useEmployeeOptions } from "../../helpers/hooks/useEmployeesOptions";
import NoDataFound from "../../components/NoDataFound";
import { unBilledDesignations } from "../../utils/services";
import CustomDateRangePicker from "../../components/ui/CustomDateRangePicker";
import { IoMdEye } from "react-icons/io";
import CustomModal from "../../components/ui/CustomModal";
import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined";
import { RiDeleteBin6Line } from "react-icons/ri";
import { CiEdit } from "react-icons/ci";

const TimeSpent = () => {
  //constants
  const auth = useSelector((state) => state.auth);

  const [filteredWorkHour, setFilteredWorkHour] = useState([]);

  const { matters, filteredMatters } = useMattersOptions();
  const { employees } = useTSEmployees(filteredWorkHour);
  const { filteredEmployees } = useEmployeeOptions();

  // console.log("filteredWorkHour", filteredWorkHour);

  const uniqueNames = employees
    .filter((item) => !unBilledDesignations.includes(item.designation))
    .map((item) => item.fullName);

  // console.log("uniqueNames", uniqueNames);

  const filteredNames = [
    ...new Set(
      filteredWorkHour && filteredWorkHour.map((item) => item?.user?.fullName)
    ),
  ];

  // console.log("filteredNames", filteredNames);

  const commonNames = uniqueNames
    .filter((name) => filteredNames.includes(name))
    .sort();

  // console.log("commonNames", commonNames);

  let sendMail = false;
  const adminId = auth.user._id;

  //states
  const [companyData, setCompanyData] = useState({
    companyName: "",
    companyEmail: "",
    companyAddress: "",
    companyLogo: null,
  });

  const [client, setClient] = useState("");
  const [matter, setMatter] = useState(""); //matter id
  const [selectedMatterName, setSelectedMatterName] = useState("");
  const [selectedLawyer, setSelectedLawyer] = useState("");
  const [startSelectedDate, setStartSelectedDate] = useState(() => {
    const today = new Date();
    const pastDate = new Date(today.setDate(today.getDate() - 2));
    return formatDate(pastDate);
  });
  const [endSelectedDate, setEndSelectedDate] = useState(
    formatDate(new Date())
  );
  const [discountHours, setDiscountHours] = useState("");
  const [showComment, setShowComment] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [workHour, setWorkHour] = useState(0);
  const [workMin, setWorkMin] = useState(30);
  const [workDescription, setWorkDescription] = useState("");
  const [comments, setComments] = useState("");
  const [entryId, setEntryId] = useState("");

  //add and edit time modal states
  const [showModal, setShowModal] = useState(false);
  const [editingTime, setEditingTime] = useState(null);
  const [date, setDate] = useState(formatDate(new Date()));
  const [modalClient, setModalClient] = useState("");
  const [modalMatter, setModalMatter] = useState(""); //matter id
  const [modalSelectedMatterName, setModalSelectedMatterName] = useState("");
  const [modalSelectedLawyerName, setModalSelectedLawyerName] = useState("");
  const [modalSelectedLawyerId, setModalSelectedLawyerId] = useState("");
  const [addressedComments, setAddressedComments] = useState({});

  //pagination states
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const ope = 0;
  const needFin = false;
  const [totalCount, setTotalCount] = useState(0);
  let lastInvoiceRaisedDate;
  if (matter !== "" && invoices.length > 0) {
    const filteredDates = invoices
      .filter(
        (invoice) =>
          invoice.matter._id === matter && invoice.status !== "cancelled"
      )
      .map((entry) => formatDate(entry?.endDate));

    if (filteredDates.length > 0) {
      lastInvoiceRaisedDate = filteredDates.reduce(
        (maxDate, currDate) => (currDate > maxDate ? currDate : maxDate),
        filteredDates[0] // Use the first date as the initial value
      );
    }
  }

  // console.log(lastInvoiceRaisedDate);

  const matterOpenDate = matters.filter((name) => name._id === matter)[0]
    ?.matterOpenDate;

  //multiple selection entries states
  const [selectedEntries, setSelectedEntries] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [newClient, setNewClient] = useState("");
  const [newMatter, setNewMatter] = useState(""); //matter id
  const [newSelectedMatterName, setNewSelectedMatterName] = useState("");

  //formatted date constants
  const formattedStartDate = formatLetterDate(startSelectedDate);
  const formattedEndDate = formatLetterDate(endSelectedDate);

  // states and functions for date range picker
  const [selectedDateRange, setSelectedDateRange] = useState({
    start: "",
    end: "",
  });

  // Function to receive selected dates from DateRangePickerTst
  const handleDateRangeChange = (startDate, endDate) => {
    setSelectedDateRange({ start: startDate, end: endDate });
    setStartSelectedDate(startDate);
    setEndSelectedDate(endDate);
    console.log(startDate, endDate);
  };

  //pagination functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //GET ALL INVOICES - TODO : FETCH ONLY WHEN NEEDED
  const fetchAllInvoices = useCallback(async () => {
    if (invoices && invoices.length > 0) return; // Prevent refetching if already available

    try {
      const { data } = await axiosInstance.get(`${API}/invoices`, {
        params: {
          limit: 0,
          page: 0,
        },
      });
      setInvoices(data.invoices);
    } catch (error) {
      console.error(
        "Error fetching invoices:",
        error.response?.data || error.message
      );
      errorAlert("Error fetching invoices.");
    }
  }, [invoices, setInvoices]);

  const fetchCompanyDetails = useCallback(async () => {
    try {
      const { data } = await axiosInstance.get(
        `${API}/company/details/${adminId}`
      );

      setCompanyData((prevData) => ({
        ...prevData,
        companyName: data.companyName || "",
        companyEmail: data.companyEmail || "",
        companyAddress: data.companyAddress || "",
        companyLogo: data.companyLogo || "",
        companyWebsite: data.companyWebsite || "",
        companyAccountsEmail: data.companyAccountsEmail || "",
      }));
    } catch (error) {
      errorAlert("Error in fetching company details!!");
    }
  }, [adminId, setCompanyData]);

  const filterBetweenDates = useCallback(async () => {
    // Determine the starting date based on conditions
    const inputFromDate = matter
      ? matterOpenDate < lastInvoiceRaisedDate
        ? lastInvoiceRaisedDate
        : matterOpenDate
      : startSelectedDate;

    // Create a new Date object and adjust it based on the matter condition
    const fromDate = new Date(inputFromDate);
    if (matter) {
      fromDate.setDate(
        matterOpenDate < lastInvoiceRaisedDate
          ? fromDate.getDate() + 1
          : fromDate.getDate()
      );
    }

    try {
      // Fetch filtered work hour data
      const { data } = await axiosInstance.get(
        `${API}/workHour/filterWorkHourByUserId`,
        {
          params: {
            user: selectedLawyer,
            fromDate: fromDate.toISOString().split("T")[0], // 'YYYY-MM-DD' format
            toDate: endSelectedDate,
            matterId: matter,
            needFin,
            sendMail,
            adminId,
            role: "",
            limit: rowsPerPage,
            page: page,
          },
        }
      );

      // Set response data
      setFilteredWorkHour(data.workHours);
      setTotalCount(data.totalFilteredWorkHours);
    } catch (error) {
      errorAlert("Error fetching filtered work hours");
    } finally {
      fetchAllInvoices(); // Optionally call the invoice fetching function
    }
  }, [
    matter,
    matterOpenDate,
    lastInvoiceRaisedDate,
    startSelectedDate,
    selectedLawyer,
    endSelectedDate,
    needFin,
    sendMail,
    adminId,
    rowsPerPage,
    page,
    fetchAllInvoices,
  ]);

  // Combined fetching invoices and company details
  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([fetchAllInvoices(), fetchCompanyDetails()]);
    };

    fetchData();
  }, [fetchAllInvoices, fetchCompanyDetails]);

  // Only trigger `filterBetweenDates` based on `page`, `rowsPerPage`, or date range
  useEffect(() => {
    if (startSelectedDate && endSelectedDate) {
      filterBetweenDates();
    }
  }, [
    startSelectedDate,
    endSelectedDate,
    page,
    rowsPerPage,
    filterBetweenDates,
  ]);

  const link1Text = companyData.companyAccountsEmail;
  const link1URL = `mailto:${companyData.companyAccountsEmail}`;
  const link2Text = companyData.companyWebsite;
  const link2URL = companyData.companyWebsite;

  let currency;
  const matterCurr = matters.find((m) => m._id === matter);
  if (matterCurr) {
    currency = matterCurr.currency === "USD" ? "$" : "Rs. ";
  } else {
    currency = "";
    // console.log("Matter not found.");
  }

  const doubleUsedTableColumns = [
    "Associate Name",
    "Total Time",
    "Billed Time",
    "Hourly Rate",
    "Total Amount",
  ];

  const doubleUsedTableData = [];

  let teamTotalHours = 0;
  let teamTotalBilledHours = 0;
  let teamTotalBilledAmount = 0;
  let teamTotalUnbilledAmount = 0;
  let persontotalHours = 0;

  // Filtering the data with matter, dates and resource name
  if (commonNames.length > 0) {
    commonNames.forEach((name) => {
      let personWorkHours = 0;
      let personWorkHoursUnbilled = 0;

      if (filteredWorkHour.length > 0) {
        filteredWorkHour
          .filter(
            (result) =>
              result?.user?.fullName === name &&
              result.workDate >= startSelectedDate
          )
          .forEach((result) => {
            const workHour = parseFloat(result.workHour);
            personWorkHours += workHour;
          });

        filteredWorkHour
          .filter(
            (result) => result.workDate && result?.user?.fullName === name
          )
          .forEach((result) => {
            const workHour = parseFloat(result.workHour);
            personWorkHoursUnbilled += workHour;
          });
      }

      persontotalHours = parseFloat(personWorkHours);

      const newMatter = matters.find(
        (matter) => matter.name.trim() === selectedMatterName.trim()
      );

      let price;
      if (newMatter) {
        if (newMatter.pricePerHour) {
          price = newMatter.pricePerHour.$numberDecimal;
        } else if (newMatter.resourceSpecificPrice.length !== 0) {
          const employeeEntry = newMatter.resourceSpecificPrice.find((entry) =>
            entry.includes(name)
          );

          if (employeeEntry) {
            const [, hours] = employeeEntry.split(":");
            price = hours.trim();
          }
        } else {
          price = 0;
        }
      }

      if (price) {
        let personTotalBilledAmount = price * personWorkHours;
        let personTotalUnBilledAmount = price * personWorkHoursUnbilled;
        teamTotalHours += personWorkHours;
        teamTotalBilledHours += personWorkHours;
        teamTotalBilledAmount += personTotalBilledAmount;
        teamTotalUnbilledAmount += personTotalUnBilledAmount;

        if (persontotalHours > 0) {
          const data1 = [
            name,
            persontotalHours.toFixed(2),
            personWorkHours.toFixed(2),
            currency + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            currency +
              personTotalBilledAmount
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          ];
          doubleUsedTableData.push(data1);
        }
      }
    });
  }

  // // Add footer row
  const footerRow = [
    {
      content: "Total Hours",
      styles: {
        fillColor: [97, 68, 58],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },
    },
    {
      content: teamTotalHours.toFixed(2),
      styles: {
        fillColor: [97, 68, 58],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },
    },
    {
      content: teamTotalBilledHours.toFixed(2),
      styles: {
        fillColor: [97, 68, 58],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },
    },
    {
      content: "",
      styles: {
        fillColor: [97, 68, 58],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },
    },
    {
      content:
        currency +
        teamTotalBilledAmount
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      styles: {
        fillColor: [97, 68, 58],
        textColor: [255, 255, 255],
        fontStyle: "bold",
      },
    },
  ];

  doubleUsedTableData.push(footerRow);

  // Table Calculations End ---------------------------------------------------------

  // Functions to Edit, Discount and Writeoff Hours of Resources
  const handleDiscountHoursChange = (entryId, value) => {
    setDiscountHours((prevState) => ({
      ...prevState,
      [entryId]: value,
    }));
  };

  // const handleWriteoff = (id) => {
  //   toast(
  //     <ConfirmationAlert
  //       onDeleteOff={() => onWriteoffWorkHour(id)}
  //       question={"Are you sure to write off total hours?"}
  //       answer={"Yes, Write Off it"}
  //       icon={<TbDiscount2Off />}
  //     />,
  //     {
  //       position: toast.POSITION.TOP_CENTER,
  //       closeButton: true,
  //       autoClose: false,
  //       draggable: false,
  //     }
  //   );
  // };

  const handleDiscount = (id) => {
    toast(
      <ConfirmationAlert
        onDeleteOff={() => onhandleApplyDiscount(id)}
        question={"Are you sure to discount entered hours?"}
        answer={"Yes, Discount it"}
        icon={<Discount />}
      />,
      {
        position: toast.POSITION.TOP_CENTER,
        closeButton: true,
        autoClose: false,
        draggable: false,
      }
    );
  };

  // const handleDelete = (id) => {
  //   toast(
  //     <ConfirmationAlert
  //       onDeleteOff={() => onDeleteWorkHour(id)}
  //       question={"Are you sure to delete this entry?"}
  //       answer={"Yes, Delete it"}
  //       icon={<Delete />}
  //     />,
  //     {
  //       position: toast.POSITION.TOP_CENTER,
  //       closeButton: true,
  //       autoClose: false,
  //       draggable: false,
  //     }
  //   );
  // };

  // const onWriteoffWorkHour = async (entryId) => {
  //   try {
  //     const response = await axiosInstance.delete(
  //       `${API}/workhour/admin/delete/${entryId}`
  //     );

  //     if (response.data) {
  //       // Update the state after deletion
  //       setFilteredWorkHour((prevFilteredWorkHour) => {
  //         // Remove the deleted entry from the state
  //         const updatedWorkHour = prevFilteredWorkHour.filter(
  //           (entry) => entry._id !== entryId
  //         );
  //         return updatedWorkHour;
  //       });

  //       successAlert("WorkHour Complete WriteOff successfully!");
  //     } else {
  //       // Handle deletion failure
  //       errorAlert("WriteOff entry failed", "Please try again...");
  //     }
  //   } catch (error) {
  //     // Handle other errors
  //     console.error("Error writeoff entry:", error);
  //     errorAlert("Writeoff entry failed", "Please try again...");
  //   }
  // };

  const onhandleApplyDiscount = async (entryId) => {
    const discountHoursForEntry = discountHours[entryId];

    if (discountHoursForEntry !== undefined) {
      try {
        const response = await axiosInstance.put(
          `${API}/workhour/admin/updateWithDiscount`,
          { entryId, updatedWorkHours: discountHoursForEntry }
        );

        // Check if the update was successful
        if (response.data) {
          // Update the state after applying discount
          setFilteredWorkHour((prevFilteredWorkHour) => {
            const updatedWorkHour = prevFilteredWorkHour.map((entry) =>
              entry._id === entryId
                ? { ...entry, workHour: response.data.workHourEntry.workHour }
                : entry
            );
            return updatedWorkHour;
          });

          successAlert(response.data.message);
        } else {
          // Handle update failure
          errorAlert("Applying discount failed", "Please try again...");
        }
      } catch (error) {
        // Handle other errors
        console.error("Error applying discount:", error);
        errorAlert("Applying discount failed", "Please try again...");
      }
    }
  };

  // const onDeleteWorkHour = async (entryId) => {
  //   await axiosInstance
  //     .delete(`${API}/workhour/delete/${entryId}`)
  //     .then((response) => {
  //       // const res = getWorkHourByUserId();
  //       if (response.data) {
  //         successAlert("Entry deleted successfully");
  //         filterBetweenDates();
  //       }
  //     })
  //     .catch((error) => {
  //       errorAlert("Deletion of entry failed", "Please try again...");
  //     });
  // };

  const generateTimeSpent = () => {
    const doc = new jsPDF();

    const pageWidth = doc.internal.pageSize.getWidth();
    const rightMargin = 15;
    const logoDesiredHeight = 20;
    const logoYPosition = 10;
    const logoXPosition = 13;

    function addHeader() {
      // Set the font style, size, and position of the header text
      const fontSize = 8;

      doc.setFont("helvetica", "normal");
      doc.setFontSize(fontSize);

      // Add the image to the header
      // doc.addImage(companyData.companyLogo, "PNG", 15, 10, 70, 9);
      doc.addImage(
        companyData.companyLogo,
        "PNG",
        logoXPosition,
        logoYPosition,
        0,
        logoDesiredHeight
      );

      // right header
      doc.setTextColor(46, 115, 176);

      const link2Width = doc.getTextWidth(link2Text);
      const link2X = pageWidth - link2Width - rightMargin;
      doc.textWithLink(link2Text, link2X, 19, {
        url: link2URL,
        underline: true,
      });

      // Set link color to blue and add underline
      const link1Width = doc.getTextWidth(link1Text);
      const link1X = pageWidth - link1Width - rightMargin;
      doc.textWithLink(link1Text, link1X, 22, {
        url: link1URL,
        underline: true,
      });

      doc.setTextColor(0);

      // Calculate text width and position to align it right
      const addressWidth = doc.getTextWidth(companyData.companyAddress);
      const addressX = pageWidth - addressWidth - rightMargin;
      doc.text(companyData.companyAddress, addressX, 26);

      // Reset text color to black
    }

    const Timespent = "/assets/Timespent.jpg";

    doc.addImage(Timespent, "JPEG", 0, 0, 210, 297);
    doc.addImage(
      companyData.companyLogo,
      "PNG",
      logoXPosition,
      logoYPosition,
      0,
      logoDesiredHeight
    );

    const fontSize = 20;
    doc.setFontSize(fontSize);

    //center alignment width calculations
    const clientNameLength = (210 - doc.getTextWidth(client)) / 2;
    const matterNameLength = (210 - doc.getTextWidth(selectedMatterName)) / 2;

    const dateRangeLength =
      (210 - doc.getTextWidth(`${formattedStartDate} - ${formattedEndDate}`)) /
      2;

    //client name
    doc.setFont("helvetica", "bold");
    doc.setTextColor(136, 106, 71);
    doc.setFontSize(20);
    doc.text(client, clientNameLength, 120);

    //matter name
    doc.setFontSize(20);
    doc.setFont("helvetica", "normal");
    doc.text(selectedMatterName, matterNameLength, 130);

    //horizontal line
    doc.setLineWidth(0.3);
    doc.setDrawColor(136, 106, 71);
    doc.line(40, 145, 180, 145);

    //date range
    doc.setFont("helvetica", "italic");
    doc.setTextColor(0, 0, 0); // Black color
    doc.setFontSize(15);
    doc.text(
      `${formattedStartDate} - ${formattedEndDate}`,
      dateRangeLength + 10,
      160
    );

    // ----------------2nd Page --------------------

    doc.addPage();
    addHeader();
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");

    doc.text("Dear Sir / Ma'am", 15, 40);
    doc.setFont("helvetica", "bold");
    doc.setTextColor(136, 106, 71);
    doc.text(
      `${client} - ${selectedMatterName} From Period ${formatMonthDate(
        startSelectedDate
      )} To ${formatMonthDate(endSelectedDate)}`,
      14,
      50
    );
    doc.setTextColor(0, 0, 0);
    doc.setFont("helvetica", "normal");
    doc.text(
      "Please find enclosed our memorandum of time-spent on the given matter for the said period.",
      15,
      60
    );

    //---------------------------------Table 1 Duplicate Table Page 2----------------------------
    //Table 1 Page 2 Data
    // ----------------------------------Main Table declaration ------------------------

    const startY = 70; // Starting Y-coordinate for the table

    doc.autoTable(doubleUsedTableColumns, doubleUsedTableData, {
      startY,
      theme: "grid",
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.1,

      didParseCell: function (data) {
        if (data.row.index === 0 && data.row.section === "head") {
          // Header row styles
          data.cell.styles.fillColor = [136, 106, 71];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.halign = "left"; // Align header text to the left
          data.cell.styles.fontStyle = "bold";
          data.cell.styles.fontSize = 9;
        } else if (data.row.section === "body") {
          // Body row styles
          if (data.column.index !== 0) {
            // Right-align all columns except the first column
            data.cell.styles.halign = "right";
          }
        } else if (data.row.index === doubleUsedTableData.length - 1) {
          // Footer row align
          data.cell.styles.halign = "right"; // Align footer text to the right
          data.cell.styles.fillColor = [226, 213, 203];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontStyle = "bold";
        } else if (
          data.row.index === doubleUsedTableData.length - 1 &&
          data.row.section === "foot"
        ) {
          // Footer row styles
          data.cell.styles.fillColor = [226, 213, 203];
          data.cell.styles.textColor = [255, 255, 255];
        }

        // Set padding for table data cells
        if (
          data.row.section === "body" &&
          data.column.index === 0 &&
          data.row.index !== -1 &&
          data.row.index !== doubleUsedTableData.length - 1
        ) {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
          data.cell.styles.textColor = [0, 0, 0];
        }
        if (
          data.row.section === "body" &&
          data.column.index === 1 &&
          data.row.index !== -1 &&
          data.row.index !== doubleUsedTableData.length - 1
        ) {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
          data.cell.styles.textColor = [0, 0, 0];
        }
        if (
          data.row.section === "body" &&
          data.column.index === 2 &&
          data.row.index !== -1 &&
          data.row.index !== doubleUsedTableData.length - 1
        ) {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
          data.cell.styles.textColor = [0, 0, 0];
        }
        if (
          data.row.section === "body" &&
          data.column.index === 3 &&
          data.row.index !== -1 &&
          data.row.index !== doubleUsedTableData.length - 1
        ) {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
          data.cell.styles.textColor = [0, 0, 0];
        }
        if (
          data.row.section === "body" &&
          data.column.index === 4 &&
          data.row.index !== -1 &&
          data.row.index !== doubleUsedTableData.length - 1
        ) {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
          data.cell.styles.textColor = [0, 0, 0];
        }
      },
    });

    // Calculate the table height
    const tableHeight = doc.autoTable.previous.finalY - startY;

    doc.text(
      "Summary of payable on this matter is provided below ",
      15,
      startY + tableHeight + 10
    );

    doc.setFont("helvetica", "bold");
    doc.setTextColor(136, 106, 71);
    doc.text(
      "Total Amount for the above period",
      15,
      startY + tableHeight + 20
    );
    doc.text(
      currency +
        (parseFloat(ope) + parseFloat(teamTotalBilledAmount))
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      doc.internal.pageSize.getWidth() -
        doc.getTextWidth(
          currency +
            (parseFloat(ope) + parseFloat(teamTotalBilledAmount))
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        ) -
        15,
      startY + tableHeight + 20
    );
    doc.text(
      `Unbilled amount since last invoice (${
        lastInvoiceRaisedDate !== undefined
          ? formatLetterDate(lastInvoiceRaisedDate)
          : "no invoices sent yet"
      })`,
      15,
      startY + tableHeight + 28
    );

    doc.text(
      currency +
        (parseFloat(ope) + parseFloat(teamTotalUnbilledAmount))
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      doc.internal.pageSize.getWidth() -
        doc.getTextWidth(
          currency +
            (parseFloat(ope) + parseFloat(teamTotalUnbilledAmount))
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        ) -
        15,
      startY + tableHeight + 28
    );

    doc.setTextColor(0, 0, 0);
    doc.setFont("helvetica", "normal");

    doc.text(
      "Please do let us know if any changes are required. Please note that this is only a statement of timespent for ",
      15,
      startY + tableHeight + 43
    );
    doc.text(
      "your reference and not an invoice. The invoice shall be raised subsequently in the manner agreed with you.",
      15,
      startY + tableHeight + 48
    );

    doc.text("Yours Sincerely,", 15, startY + tableHeight + 58);
    doc.setFont("helvetica", "bold");
    doc.text(auth.user.fullName, 15, startY + tableHeight + 63);
    doc.setFont("helvetica", "normal");

    // --------------------------------------3rd Page----------------------------------------------------
    doc.addPage();
    addHeader();

    let startY1 = 35; // Starting y-coordinate for each table

    commonNames.forEach((name, index) => {
      const personData = filteredWorkHour
        .filter(
          (result) =>
            result.user?.fullName === name &&
            result.workDate >= startSelectedDate
        )
        .sort((a, b) => new Date(a.workDate) - new Date(b.workDate)); // Sort the data by workDate in ascending order

      const billedTime = personData.reduce(
        (total, result) => total + parseFloat(result.workHour),
        0
      );

      const headerRow = [
        {
          content: name,
          colSpan: 2,
          styles: {
            cellWidth: "auto", // Set cell width to auto to allow content alignment
            halign: "left",
            fillColor: [136, 106, 71],
            textColor: [255, 255, 255],
            fontStyle: "bold", // Set the font style to bold
          },
        },
        {
          content: "Total Time",
          styles: {
            cellWidth: "auto", // Set cell width to auto to allow content alignment
            halign: "left",
            fillColor: [136, 106, 71],
            textColor: [255, 255, 255],
            fontStyle: "bold", // Set the font style to bold
          },
        },

        {
          content: "Billed Time",
          styles: {
            cellWidth: "auto", // Set cell width to auto to allow content alignment
            halign: "left",
            fillColor: [136, 106, 71],
            textColor: [255, 255, 255],
            fontStyle: "bold", // Set the font style to bold
          },
        },
      ];

      const tableRows = [headerRow]; // Initialize tableRows with the header row
      let commentsText = " ";

      // Add individual data rows for the person
      if (personData.length > 0) {
        personData.forEach((result) => {
          commentsText = result.comments || " ";

          const data = [
            formatMonthDate(result.workDate),
            showComment === true && commentsText !== " "
              ? `${result.workDescription}

-----------------------------------------------------------------------------------------------------------
||      ${commentsText}  ||`
              : result.workDescription,
            parseFloat(result.workHour).toFixed(2),
            parseFloat(result.workHour).toFixed(2),
          ];
          tableRows.push(data);
        });

        // Add footer row with total time
        const footerRow = [
          {
            content: "Total Time",
            colSpan: 2,
            styles: {
              halign: "left",
              fillColor: [97, 68, 58],
              textColor: [255, 255, 255],
              fontStyle: "bold",
            },
          },
          {
            content: billedTime.toFixed(2),
            styles: {
              halign: "right",
              fillColor: [97, 68, 58],
              textColor: [255, 255, 255],
              fontStyle: "bold",
            },
          },
          {
            content: billedTime.toFixed(2),
            styles: {
              halign: "right",
              fillColor: [97, 68, 58],
              textColor: [255, 255, 255],
              fontStyle: "bold",
            },
          },
        ];
        tableRows.push(footerRow);

        // Remove empty rows and columns from tableRows
        const filteredTableRows = tableRows.filter((row) =>
          row.some((cell) => cell !== "")
        );

        // Generate table for the current person

        doc.autoTable({
          body: filteredTableRows,
          startY: startY1,
          theme: "grid",
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0.1,
          didParseCell: function (data) {
            if (data.row.index === 0 && data.row.section === "head") {
              // Header row styles
              data.cell.styles.fillColor = [136, 106, 71];
              data.cell.styles.textColor = [255, 255, 255];
              data.cell.styles.halign = "left";
              data.cell.styles.valign = "left"; // Align the text vertically at the center
              data.cell.styles.fontStyle = "bold"; // Set the font style to bold
            } else if (
              data.row.index === filteredTableRows.length - 1 &&
              data.row.section === "body"
            ) {
              // Footer row styles
              data.cell.styles.fillColor = [97, 68, 58];
              data.cell.styles.textColor = [255, 255, 255];
              // data.cell.styles.halign = "right";
              data.cell.styles.fontStyle = "bold";
            } else if (data.row.section === "body") {
              // Body row styles
              if (data.column.index !== 1) {
                // Right-align all columns except the first column
                data.cell.styles.halign = "left";
              }
            }
            // Set padding for table data cells
            if (
              data.row.section === "body" &&
              data.column.index === 0 &&
              data.row.index !== 0 &&
              data.row.index !== tableRows.length - 1
            ) {
              data.cell.styles.cellPadding = {
                top: 3,
                right: 2,
                bottom: 3,
                left: 3,
              };
              data.cell.styles.fontSize = 9;
              data.cell.styles.halign = "left";
              data.cell.styles.cellWidth = 20;
              data.cell.styles.textColor = [0, 0, 0];
            }
            if (
              data.row.section === "body" &&
              data.column.index === 1 &&
              data.row.index !== 0 &&
              data.row.index !== tableRows.length - 1
            ) {
              data.cell.styles.cellPadding = {
                top: 3,
                right: 4,
                bottom: 3,
                left: 4,
              };
              data.cell.styles.fontSize = 9;
              data.cell.styles.fontStyle = "times";
              data.cell.styles.halign = "left";
              data.cell.styles.cellWidth = 122; // Set the width to 100 units
              data.cell.styles.textColor = [0, 0, 0];
            }

            if (data.row.section === "body" && data.column.index === 1) {
              // Check if the cell contains the separator line indicating comments
              const cellText = data.cell.text;
              const hasComments = cellText.includes(
                "-----------------------------------------------------------------------------------------------------------"
              );

              if (hasComments) {
                data.cell.styles.fillColor = [215, 230, 230];
              }
            }

            if (
              data.row.section === "body" &&
              data.column.index === 2 &&
              data.row.index !== 0 &&
              data.row.index !== tableRows.length - 1
            ) {
              data.cell.styles.cellPadding = {
                top: 3,
                right: 2,
                bottom: 3,
                left: 2,
              };
              data.cell.styles.halign = "right";
              data.cell.styles.fontSize = 9;
              data.cell.styles.cellWidth = 20;
              data.cell.styles.textColor = [0, 0, 0];
            }
            if (
              data.row.section === "body" &&
              data.column.index === 3 &&
              data.row.index !== 0 &&
              data.row.index !== tableRows.length - 1
            ) {
              data.cell.styles.cellPadding = {
                top: 3,
                right: 2,
                bottom: 3,
                left: 2,
              };
              data.cell.styles.halign = "right";
              data.cell.styles.fontSize = 9;
              data.cell.styles.cellWidth = 20;
              data.cell.styles.textColor = [0, 0, 0];
            }
          },
        });
        startY1 = doc.lastAutoTable.finalY + 10; // Update startY for the next table
      }
    });

    doc.save(
      `${client} - ${selectedMatterName} From Period ${formatMonthDate(
        startSelectedDate
      )} To ${formatMonthDate(endSelectedDate)}.pdf`
    );
  };

  const openModal = (time, type) => {
    setShowModal(true);

    if (type === "duplicate") {
      setEditingTime(null);
      setDate(time.workDate);
      setWorkDescription(time.workDescription);
      setModalSelectedLawyerName(time.user.fullName);
      setModalSelectedLawyerId(time.user._id);
      setModalMatter(time.matter);
      setModalSelectedMatterName(time.matter.name);
      setComments(time.comments);
      const hour = time.workHour.split(".");
      setWorkHour(hour[0]);
      setWorkMin(Math.floor((hour[1] * 6) / 10));
    } else if (type === "add") {
      setEditingTime(null);
      setDate(formatDate(new Date()));
      setWorkDescription("");
      setModalSelectedLawyerId("");
      setModalSelectedLawyerName("");
      setModalMatter("");
      setModalSelectedMatterName("");
      setComments("");
      setWorkHour(0);
      setWorkMin(30);
    } else {
      setEditingTime(time);
      setDate(time.workDate);
      setEntryId(time._id);
      setWorkDescription(time.workDescription);
      setModalSelectedLawyerName(time.user.fullName);
      setModalSelectedLawyerId(time.user._id);
      // setUserId(time.user._id);
      setModalMatter(time.matter);
      setModalSelectedMatterName(time.matter.name);
      setComments(time.comments);
      const hour = time.workHour.split(".");
      setWorkHour(hour[0]);
      setWorkMin(Math.floor((hour[1] * 6) / 10));
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setDate(formatDate(new Date()));
    setWorkDescription("");
    // setUserId("");
    setModalSelectedLawyerName("");
    setModalSelectedLawyerId("");
    setModalMatter("");
    setModalSelectedMatterName("");
    setModalClient("");
    setComments("");
    setWorkHour(0);
    setWorkMin(30);
    setEntryId("");
  };

  //Form Validation
  const isValidObjField = () => {
    if (
      date === "" ||
      modalSelectedLawyerId === "" ||
      modalMatter === "" ||
      workDescription === "" ||
      workHour === "00" ||
      workMin === "00"
    ) {
      return false;
    } else {
      return true;
    }
    // return Object.values(obj).every(value => value.trim())
  };

  const isValidForm = () => {
    //all fields must be filled
    if (!isValidObjField()) return errorAlert("All Fields are Required!");

    return true;
  };

  //Add, Duplicate and Edit Time
  const handleSubmit = async (e) => {
    e.preventDefault();

    const time = {
      admin: auth.user._id,
      user: modalSelectedLawyerId,
      workDate: date,
      matter: modalMatter,
      workDescription,
      comments,
      workHour: `${workHour}.${workMin * (10 / 6)}`,
      category: "billable",
      reviewed: "true",
    };

    if (isValidForm()) {
      if (editingTime) {
        const response = await axios.put(
          `${API}/workHour/update/${entryId}`,
          time
        );

        if (response.data) {
          successAlert("Time Updated Successfully!");
          setFilteredWorkHour((prevFilteredWorkHour) => {
            const updatedWorkHour = prevFilteredWorkHour.map((entry) =>
              entry._id === entryId ? response.data : entry
            );
            return updatedWorkHour;
          });
          closeModal();
          filterBetweenDates();
        }
      } else {
        try {
          const response = await axiosInstance.post(
            `${API}/workHour/createNew`,
            time
          );

          if (response.data) {
            successAlert("Great!! Time Added Successfully.");

            // Call filterBetweenDates after adding time
            setFilteredWorkHour((prevFilteredWorkHour) => {
              // Include the new entry in the updated state
              const updatedWorkHour = [...prevFilteredWorkHour, response.data];
              return updatedWorkHour;
            });

            closeModal();
            filterBetweenDates();
          }
        } catch (error) {
          console.error("Error adding time:", error);
          errorAlert("Error adding time");
        }
      }
    }
  };

  //address comment function
  const markCommentAsAddressed = (entryId) => {
    setAddressedComments((prev) => ({
      ...prev,
      [entryId]: !prev[entryId], // Toggle the addressed state
    }));
  };

  //mupltiple select entries functions
  const handleSelectEntry = (id, checked) => {
    if (checked) {
      setSelectedEntries([...selectedEntries, id]);
    } else {
      setSelectedEntries(selectedEntries.filter((entryId) => entryId !== id));
    }
  };

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);

    // If "Select All" checkbox is checked, set all entry IDs as selected; otherwise, clear selection
    setSelectedEntries(
      isChecked
        ? filteredWorkHour
            .filter((entry) => entry.workDate >= startSelectedDate)
            .map((entry) => entry._id)
        : []
    );
  };

  const handleUpdateEntries = () => {
    // Send Axios request to backend to update selected entries with new matter code

    if (newMatter) {
      handleUpdateEntriesAlert();
    } else {
      errorAlert("Select the new matter");
    }
  };

  const handleUpdateEntriesAlert = (id) => {
    toast(
      <ConfirmationAlert
        onDeleteOff={() => onUpdateMultipleEntries(id)}
        question={"Are you sure to update matter of all selected entries?"}
        answer={"Yes, Update Matter"}
        icon={<Update />}
      />,
      {
        position: toast.POSITION.TOP_CENTER,
        closeButton: true,
        autoClose: false,
        draggable: false,
      }
    );
  };

  const onUpdateMultipleEntries = async (entryId) => {
    axiosInstance
      .post("/updateMatterCodeEntries", {
        entries: selectedEntries,
        newMatter,
      })
      .then((response) => {
        // Handle success response
        filterBetweenDates();
      })
      .catch((error) => {
        // Handle error
        console.error("Error updating entries:", error);
      });
  };

  const [singleDeleteSnackbarOpen, setSingleDeleteSnackbarOpen] =
    useState(false);
  const [multipleDeleteSnackbarOpen, setMultipleDeleteSnackbarOpen] =
    useState(false);
  const [singleWriteoffSnackbarOpen, setSingleWriteoffSnackbarOpen] =
    useState(false);
  const [multipleWriteoffSnackbarOpen, setMultipleWriteoffSnackbarOpen] =
    useState(false);

  // Store deleted and write-off items
  const [deletedItem, setDeletedItem] = useState(null); // For single delete
  const [deletedEntries, setDeletedEntries] = useState([]); // For multiple delete
  const [writeoffItem, setWriteoffItem] = useState(null); // For single write-off
  const [writeoffEntries, setWriteoffEntries] = useState([]); // For multiple write-off

  const handleWriteoffSingle = async (entryId) => {
    try {
      const response = await axiosInstance.delete(
        `${API}/workhour/admin/delete/${entryId}`
      );

      if (response.data) {
        successAlert("Entry written off successfully");

        console.log("data", response.data);

        // Store the single write-off entry for undo
        setWriteoffItem(response.data?.createdWriteoff?._id);
        setSingleWriteoffSnackbarOpen(true);

        // Remove the entry from the UI after write-off
        setFilteredWorkHour((prev) =>
          prev.filter((entry) => entry._id !== entryId)
        );
      } else {
        errorAlert("Failed to write off entry.");
      }
    } catch (error) {
      errorAlert("An error occurred while writing off the entry.");
    }
  };

  const handleUndoWriteoffSingle = async () => {
    try {
      if (writeoffItem) {
        const response = await axiosInstance.post(
          `${API}/workhour/restorefromwriteoff/${writeoffItem}`
        );

        if (response.data) {
          successAlert("Entry write-off undone successfully");

          // Restore the entry in the UI
          setFilteredWorkHour((prev) => [...prev, writeoffItem]);
          setWriteoffItem(null);
          setSingleWriteoffSnackbarOpen(false);
          filterBetweenDates();
        } else {
          errorAlert("Failed to restore the write-off.");
        }
      }
    } catch (error) {
      errorAlert("An error occurred while undoing the write-off.");
    }
  };

  const handleWriteoffMultiple = async () => {
    if (selectedEntries.length === 0) {
      errorAlert("No entries selected for write-off");
      return;
    }

    try {
      // Send delete requests for each selected entry
      const writeOffPromises = selectedEntries.map((entryId) =>
        axiosInstance.delete(`${API}/workhour/admin/delete/${entryId}`)
      );

      // Await the responses for all requests
      const responses = await Promise.all(writeOffPromises);

      successAlert("Selected entries written off");

      // Extract the created write-off entries from the responses
      const writeOffData = responses.map(
        (response) => response.data?.createdWriteoff
      );

      // Store the write-off entries for undo
      setWriteoffEntries(writeOffData);

      // Remove the written-off entries from the UI
      setFilteredWorkHour((prev) =>
        prev.filter((entry) => !selectedEntries.includes(entry._id))
      );

      setMultipleWriteoffSnackbarOpen(true);

      // Clear selected entries
      setSelectedEntries([]);
      setSelectAll(false);
    } catch (error) {
      errorAlert("Failed to write off selected entries.");
      console.error("Error during multiple write-off:", error);
    }
  };

  const handleUndoWriteoffMultiple = async () => {
    try {
      const restorePromises = writeoffEntries.map((entry) =>
        axiosInstance.post(`${API}/workhour/restorefromwriteoff/${entry._id}`)
      );

      // Wait for all restore operations to complete
      await Promise.all(restorePromises);

      successAlert("Selected write-offs undone successfully");

      // Restore the write-off entries in the UI
      setFilteredWorkHour((prev) => [...prev, ...writeoffEntries]);

      // Clear write-off entries
      setWriteoffEntries([]);
      setMultipleWriteoffSnackbarOpen(false);
      filterBetweenDates();
    } catch (error) {
      errorAlert("Failed to undo write-off for selected entries.");
      console.error("Error during undoing multiple write-offs:", error);
    }
  };

  const handleSoftDeleteMultiple = async () => {
    if (selectedEntries.length === 0) {
      errorAlert("No entries selected for deletion");
      return;
    }

    try {
      const deletePromises = selectedEntries.map((entryId) => {
        return axiosInstance.delete(`${API}/workhour/softdelete/${entryId}`);
      });

      await Promise.all(deletePromises);

      successAlert("Selected entries soft deleted");

      // Store the deleted entries for undo
      const deletedData = filteredWorkHour.filter((entry) =>
        selectedEntries.includes(entry._id)
      );
      setDeletedEntries(deletedData);

      // Update the UI
      setFilteredWorkHour((prev) =>
        prev.filter((entry) => !selectedEntries.includes(entry._id))
      );
      setMultipleDeleteSnackbarOpen(true);
      setSelectedEntries([]); // Clear selected entries
      setSelectAll(false); // Uncheck the select all checkbox
    } catch (error) {
      errorAlert("Failed to delete selected entries.");
    }
  };

  const handleUndoMultiple = async () => {
    try {
      const restorePromises = deletedEntries.map((entry) => {
        return axiosInstance.post(`${API}/workhour/restore/${entry._id}`);
      });

      await Promise.all(restorePromises);

      successAlert("Selected entries restored successfully");

      // Restore entries in the UI
      setFilteredWorkHour((prev) => [...prev, ...deletedEntries]);
      setDeletedEntries([]);
      setMultipleDeleteSnackbarOpen(false);
    } catch (error) {
      errorAlert("Failed to restore selected entries.");
    }
  };

  const handleSoftDeleteSingle = async (entryId) => {
    try {
      const response = await axiosInstance.delete(
        `${API}/workhour/softdelete/${entryId}`
      );

      if (response.data && response.data.deletedWorkhour) {
        successAlert("Entry soft deleted successfully");

        // Store the single deleted item for undo
        setDeletedItem({ entryId, ...response.data });
        setSingleDeleteSnackbarOpen(true);

        // Update UI after deletion
        setFilteredWorkHour((prev) =>
          prev.filter((entry) => entry._id !== entryId)
        );
      } else {
        errorAlert("Failed to delete entry.");
      }
    } catch (error) {
      errorAlert("An error occurred while deleting the entry.");
    }
  };

  const handleUndoSingle = async () => {
    try {
      if (deletedItem) {
        const response = await axiosInstance.post(
          `${API}/workhour/restore/${deletedItem.entryId}`
        );

        if (response.data) {
          successAlert("Entry restored successfully");
          setFilteredWorkHour((prev) => [...prev, deletedItem]);
          setDeletedItem(null);
          setSingleDeleteSnackbarOpen(false);
          filterBetweenDates();
        } else {
          errorAlert("Failed to restore the entry.");
        }
      }
    } catch (error) {
      errorAlert("An error occurred while restoring the entry.");
    }
  };

  //main table data show function
  const renderRows = () => {
    return commonNames.map((employee) => {
      let totalWorkHours = 0;
      return (
        <React.Fragment key={employee}>
          {filteredWorkHour
            .filter(
              (result) =>
                result?.user?.fullName === employee &&
                result.workDate >= startSelectedDate
            )
            .map((item, index) => {
              totalWorkHours += parseFloat(item.workHour);
              return (
                <TableRow key={item._id} role="checkbox" tabIndex={-1}>
                  <TableCell>
                    <input
                      type="checkbox"
                      className="custom-checkbox"
                      checked={selectedEntries.includes(item._id)}
                      onChange={(e) =>
                        handleSelectEntry(item._id, e.target.checked)
                      }
                    />
                  </TableCell>
                  <TableCell>{formatMonthDate(item.workDate)}</TableCell>
                  <TableCell>{item.user?.fullName}</TableCell>
                  <TableCell>{item.matter?.name}</TableCell>
                  <TableCell>
                    {item.workDescription}
                    {showComment && item.comments && (
                      <>
                        <p
                          style={{
                            marginTop: "10px",
                            padding: "5px",
                            backgroundColor: "yellow",
                            color: "red",
                            textDecoration: addressedComments[item._id]
                              ? "line-through"
                              : "none",
                          }}
                        >
                          {item?.comments ? item.comments : ""}
                        </p>

                        <Button
                          style={{
                            backgroundColor: "lightgrey",
                            color: "red",
                            fontSize: "12px",
                          }}
                          onClick={() => markCommentAsAddressed(item._id)}
                        >
                          Address Comment
                        </Button>
                      </>
                    )}
                  </TableCell>
                  <TableCell>{item.workHour}</TableCell>
                  <TableCell>
                    <input
                      type="text"
                      name="updatedWorkHours"
                      value={discountHours[item._id] || ""}
                      onChange={(e) =>
                        handleDiscountHoursChange(item._id, e.target.value)
                      }
                      className="w-[100px]"
                    />
                  </TableCell>

                  <TableCell>
                    <CustomTooltip text="Edit Entry">
                      <CiEdit
                        size={20}
                        style={{ cursor: "pointer" }}
                        onClick={() => openModal(item, "edit")}
                      />
                    </CustomTooltip>
                  </TableCell>
                  <TableCell>
                    <CustomTooltip text="Discount Entered Hours">
                      <Discount
                        size={18}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDiscount(item._id)}
                      />
                    </CustomTooltip>
                  </TableCell>
                  <TableCell>
                    <CustomTooltip text="Complete WriteOff">
                      <TbDiscount2Off
                        size={18}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleWriteoffSingle(item._id)}
                      />
                    </CustomTooltip>
                  </TableCell>
                  <TableCell className="table-cell">
                    <CustomTooltip text="Delete Entry">
                      <RiDeleteBin6Line
                        size={18}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSoftDeleteSingle(item._id)}
                      />
                    </CustomTooltip>
                  </TableCell>

                  <TableCell className="table-cell">
                    <CustomTooltip text="Duplicate Entry">
                      <BiDuplicate
                        size={18}
                        style={{ cursor: "pointer" }}
                        onClick={() => openModal(item, "duplicate")}
                      />
                    </CustomTooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          {totalWorkHours > 0 && (
            <React.Fragment>
              <TableRow style={{ backgroundColor: "black" }}>
                <TableCell
                  colSpan={4}
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                >
                  Total Work Hours of {employee}:
                </TableCell>
                <TableCell
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                >
                  {totalWorkHours.toFixed(2)}
                </TableCell>
                <TableCell colSpan={7}></TableCell>
              </TableRow>
            </React.Fragment>
          )}
        </React.Fragment>
      );
    });
  };

  const columns = [
    "Date",
    "Associate",
    "Matter",
    "Description",
    "Time",
    "Discount hrs",
    "Edit",
    "DIS",
    "W/O",
    "Delete",
    "Duplicate",
  ];

  return (
    <>
      <ToastContainer />

      {/* Single Workhour  */}
      <Snackbar
        open={singleDeleteSnackbarOpen}
        autoHideDuration={10000} // Hide after 10 seconds
        onClose={() => setSingleDeleteSnackbarOpen(false)}
        message="Single entry deleted"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }} // Change the position here
        action={
          <Button color="secondary" size="small" onClick={handleUndoSingle}>
            UNDO
          </Button>
        }
      />

      {/* Multiple Workhour  */}

      <Snackbar
        open={multipleDeleteSnackbarOpen}
        autoHideDuration={10000} // Hide after 10 seconds
        onClose={() => setMultipleDeleteSnackbarOpen(false)}
        message="Multiple entries deleted"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }} // Change the position here
        action={
          <Button color="secondary" size="small" onClick={handleUndoMultiple}>
            UNDO
          </Button>
        }
      />

      {/* Snackbar for Single Write-off */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }} // Change the position here
        open={singleWriteoffSnackbarOpen}
        autoHideDuration={10000} // Hide after 10 seconds
        onClose={() => setSingleWriteoffSnackbarOpen(false)}
        message="Single entry written off"
        action={
          <Button
            color="secondary"
            size="small"
            onClick={handleUndoWriteoffSingle}
          >
            UNDO
          </Button>
        }
      />

      {/* Snackbar for Multiple Write-offs */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }} // Change the position here
        open={multipleWriteoffSnackbarOpen}
        autoHideDuration={10000} // Hide after 10 seconds
        onClose={() => setMultipleWriteoffSnackbarOpen(false)}
        message="Multiple entries written off"
        action={
          <Button
            color="secondary"
            size="small"
            onClick={handleUndoWriteoffMultiple}
          >
            UNDO
          </Button>
        }
      />

      {/* Search Bar  */}
      <Paper elevation={0} className="rounded-full">
        <Grid container spacing={1} alignItems="center" marginBottom="1rem">
          {/* Main Heading  */}
          <Grid item container spacing={1} xs={12} sm={7} md={3}>
            <Grid item>
              <Typography variant="h5">Timespent</Typography>
            </Grid>
            <Grid item>
              <CustomTooltip text="Add Time">
                <CustomButton
                  style={{
                    borderRadius: "50%",
                    minWidth: "5px",
                    height: "30px",
                    transition:
                      "transform 0.3s ease-in-out, background-color 0.3s ease",
                    "&:hover": {
                      backgroundColor: "#886a47",
                      transform: "scale(1.1)",
                    },
                  }}
                  onClick={() => openModal(null, "add")}
                >
                  <AddIcon />
                </CustomButton>
              </CustomTooltip>
            </Grid>
          </Grid>

          {/* Matters  */}
          <Grid item xs={12} sm={7} md={2.5}>
            <Select
              options={filteredMatters}
              value={filteredMatters.find(
                (option) => option.label === `${selectedMatterName} | ${client}`
              )}
              onChange={(e) => {
                setMatter(e.value);
                setSelectedMatterName(e.label.split("|")[0]);
                setClient(e.label.split("|")[1]);
              }}
              isSearchable
              placeholder="Select Matter"
              styles={customStyles}
            />
          </Grid>

          {/* Resources  */}
          <Grid item xs={12} sm={5} md={1.5}>
            <Select
              options={filteredEmployees}
              value={filteredEmployees.find(
                (resOption) => resOption.label === `${selectedLawyer}`
              )}
              onChange={(e) => {
                setSelectedLawyer(e.value);
              }}
              isSearchable
              placeholder="Resource"
              styles={customStyles}
            />
          </Grid>

          {/* Date Range Picker */}
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            display="flex"
            alignItems="center"
            gap={1}
          >
            <CustomTooltip text={"Select Dates"}>
              <CustomDateRangePicker
                startDate={selectedDateRange.start}
                endDate={selectedDateRange.end}
                onDateRangeChange={handleDateRangeChange}
                iconSize={30}
              />
            </CustomTooltip>

            <Typography>
              {formatMonthDate(startSelectedDate)} -{" "}
              {formatMonthDate(endSelectedDate)}
            </Typography>
          </Grid>

          {/* Search Icon Button */}
          <Grid item xs={4} sm={2} md={0.4}>
            <CustomTooltip text="Search workhours">
              <IconButton
                onClick={() => filterBetweenDates()}
                className=" text-black hover:bg-gray-800"
                style={{
                  padding: 5,
                  borderRadius: "50%",
                }}
              >
                <Search style={{ fontSize: "20px" }} /> {/* Custom icon size */}
              </IconButton>
            </CustomTooltip>
          </Grid>

          {/* Show/Hide Comments Icon Button */}
          <Grid item xs={4} sm={2} md={0.4}>
            <CustomTooltip
              text={showComment ? "Hide Comments" : "Show Comments"}
            >
              <IconButton
                onClick={() => setShowComment(!showComment)}
                className=" text-black hover:bg-gray-800"
                style={{
                  padding: 5,
                  // color: "white",
                  // backgroundColor: "black",
                  borderRadius: "50%",
                }}
              >
                {showComment ? (
                  <BiSolidHide style={{ fontSize: "20px" }} />
                ) : (
                  <IoMdEye style={{ fontSize: "20px" }} />
                )}
              </IconButton>
            </CustomTooltip>
          </Grid>

          {/* Download Icon Button */}
          <Grid item xs={4} sm={2} md={0.4}>
            <CustomTooltip text="Download Report">
              <IconButton
                onClick={generateTimeSpent}
                className=" text-black hover:bg-gray-800"
                style={{
                  padding: 5,
                  borderRadius: "50%",
                }}
              >
                <Download style={{ fontSize: "20px" }} />
              </IconButton>
            </CustomTooltip>
          </Grid>
        </Grid>

        {selectedEntries.length > 1 && (
          <Grid container spacing={1} alignItems="baseline" marginY="1rem">
            <Grid item xs={12} sm={7} md={3}>
              <Select
                options={filteredMatters}
                value={filteredMatters.find(
                  (option) =>
                    option.label === `${newSelectedMatterName} | ${newClient}`
                )}
                onChange={(e) => {
                  setNewMatter(e.value);
                  setNewSelectedMatterName(e.label.split("|")[0]);
                  setNewClient(e.label.split("|")[1]);
                }}
                isSearchable
                placeholder="Select New Matter"
                styles={customStyles}
              />
            </Grid>

            <Grid item xs={4} sm={4} md={0.5}>
              <CustomTooltip text="Move all">
                <IconButton onClick={() => handleUpdateEntries()}>
                  <DriveFileMoveOutlinedIcon style={{ fontSize: "25px" }} />
                </IconButton>
              </CustomTooltip>
            </Grid>

            <Grid item xs={12} sm={4} md={0.5}>
              <CustomTooltip text="Delete all">
                <IconButton onClick={() => handleSoftDeleteMultiple()}>
                  <RiDeleteBin6Line style={{ fontSize: "25px" }} />
                </IconButton>
              </CustomTooltip>
            </Grid>

            <Grid item xs={12} sm={4} md={0.5}>
              <CustomTooltip text="Write-off all">
                <IconButton onClick={() => handleWriteoffMultiple()}>
                  <TbDiscount2Off style={{ fontSize: "25px" }} />
                </IconButton>
              </CustomTooltip>
            </Grid>
          </Grid>
        )}
      </Paper>

      {/* Modal  */}
      {showModal && (
        <CustomModal
          showModal={showModal}
          closeModal={() => setShowModal(false)}
          title="Add Time" // Dynamic title
          onSubmit={handleSubmit}
        >
          <div className="flex gap-4 w-full">
            <div style={{ width: "50%" }}>
              <input
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>

            <div style={{ width: "50%" }}>
              <Select
                options={filteredEmployees}
                value={filteredEmployees.find(
                  (resOption) =>
                    resOption.label === `${modalSelectedLawyerName}`
                )}
                onChange={(e) => {
                  setModalSelectedLawyerId(e.value);
                  setModalSelectedLawyerName(e.label);
                }}
                isSearchable
                placeholder="Select Resource"
                styles={customStyles}
              />
            </div>
          </div>

          <div className="flex gap-4 w-full">
            <div style={{ width: "100%" }}>
              {modalSelectedMatterName}

              <Select
                options={filteredMatters}
                value={filteredMatters.find(
                  (option) =>
                    option.label ===
                    `${modalSelectedMatterName} | ${modalClient}`
                )}
                onChange={(e) => {
                  setModalMatter(e.value);
                  setModalSelectedMatterName(e.label.split("|")[0]);
                  setModalClient(e.label.split("|")[1]);
                }}
                isSearchable
                placeholder="Select Matter"
                styles={customStyles}
              />
            </div>
          </div>

          <div className="flex gap-4 w-full">
            <div style={{ width: "50%" }}>
              <select
                value={workHour}
                name="workHour"
                onChange={(event) => {
                  setWorkHour(event.target.value);
                }}
              >
                <option value="">Select Work Hour *</option>
                {Array.from({ length: 19 }, (_, i) => (
                  <option key={i} value={i}>
                    {i}
                  </option>
                ))}
              </select>
            </div>

            <div style={{ width: "50%" }}>
              <select
                value={workMin}
                name="workMin"
                onChange={(event) => {
                  setWorkMin(event.target.value);
                }}
              >
                <option value="">Select Work Minutes *</option>
                {Array.from({ length: 10 }, (_, i) => (
                  <option key={i * 6} value={i * 6}>
                    {i * 6}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex gap-4 w-full">
            <textarea
              type="text"
              placeholder="Work description"
              value={workDescription}
              onChange={(e) => setWorkDescription(e.target.value)}
            />
          </div>

          <div className="flex gap-4 w-full">
            <input
              type="text"
              style={{ width: "100%" }}
              placeholder="Comments for the reviewer"
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            />
          </div>
        </CustomModal>
      )}

      {/* Main Table  */}
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100, 500, 1000]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <TableContainer sx={{ maxHeight: "68vh" }}>
          <Table aria-label="reusable table">
            <TableHead>
              <TableRow sx={{ height: "5px" }}>
                <TableCell colSpan={12}>
                  <div className="middle">
                    <div style={{ display: "flex", gap: "10px" }}>
                      <label>Total amount for above period :</label>
                      <p>
                        {currency}
                        {teamTotalBilledAmount
                          ?.toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </p>
                    </div>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <label>Total Hours for above period: </label>
                      <p>{teamTotalHours?.toFixed(2)}</p>
                    </div>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <label> Total unbilled amount : </label>
                      <p>
                        {currency}
                        {teamTotalUnbilledAmount
                          ?.toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </p>
                    </div>
                  </div>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell id="table-header">
                  <CustomTooltip text="Select All">
                    <input
                      type="checkbox"
                      className="custom-checkbox"
                      checked={selectAll}
                      onChange={(e) => handleSelectAll(e)}
                    />
                  </CustomTooltip>
                </TableCell>
                {columns.map((column, index) => (
                  <TableCell key={index} id="table-header">
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {filteredWorkHour.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={11}>
                    <NoDataFound message="Oops! No workhours found." />
                  </TableCell>
                </TableRow>
              ) : (
                renderRows()
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default TimeSpent;
