import React, { useState, useEffect } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import formatDate, {
  API,
  errorAlert,
  formatLetterDate,
  formatMonthDate,
  holidays,
  unBilledDesignations,
} from "../../utils/services";
import { useDispatch, useSelector } from "react-redux";
import { filterTimeByUserIdForAdmin } from "../../actions/admin.time.action";
import {
  fetchWriteoffsByAdminId,
  getAllEmployeesByAdminId,
  getAllMatters,
} from "../../actions";
import { Paper, Grid, Typography, IconButton } from "@mui/material";
import { Download, Search } from "@mui/icons-material";
import axiosInstance from "../../helpers/axios";
import CustomButton from "../../components/ui/CustomButton";
import CustomTooltip from "../../components/ui/CustomTootltip";
import CustomDateRangePicker from "../../components/ui/CustomDateRangePicker";

const Financial = () => {
  //constants
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const Matter = useSelector((state) => state.matter);
  const adminTime = useSelector((state) => state.adminTime);
  const adminEmployees = useSelector((state) => state.adminEmployees);
  const writeoff = useSelector((state) => state.writeoff);

  const {
    workHours,
    financialWorkHours,
    totalAmountLast4Months,
    averageAmountLast4Months,
    totalAmountLast4Weeks,
    averageAmountLast4Weeks,
  } = adminTime.filteredWorkHour;

  const { filterLoading } = adminTime;
  const { writeOffHours } = writeoff;
  const { matters } = Matter;
  const { employees } = adminEmployees;

  // console.log(workHours);

  useEffect(() => {
    fetchCompanyDetails();
    filterBetweenDates();
    fetchAllOpexes();
  }, []);

  // console.log("Financial Amount", financialWorkHours);

  //States
  const [startSelectedDate, setStartSelectedDate] = useState(() => {
    const today = new Date();
    const pastDate = new Date(today.setDate(today.getDate() - 7));
    return formatDate(pastDate);
  });
  const [endSelectedDate, setEndSelectedDate] = useState(
    formatDate(new Date())
  );
  const matter = "";
  const selectedLawyer = "";
  const [leaves, setLeaves] = useState([]);
  const [opexes, setOpexes] = useState([]);
  const expType = "";
  const [invoices, setInvoices] = useState([]);
  const [needFin, setNeedFin] = useState(true);
  const [reserves, setReserves] = useState([]);
  const sendMail = false;
  const [companyData, setCompanyData] = useState({
    companyName: "",
    companyEmail: "",
    companyAddress: "",
    companyLogo: null,
  });

  const fetchCompanyDetails = async () => {
    try {
      const response = await axiosInstance.get(
        `${API}/company/details/${adminId}`
      );

      // console.log("Company details", response.data);

      // Set other company details as well
      setCompanyData({
        ...companyData,
        companyName: response.data.companyName || "",
        companyEmail: response.data.companyEmail || "",
        companyAddress: response.data.companyAddress || "",
        companyLogo: response.data.companyLogo || "",
        companyWebsite: response.data.companyWebsite || "",
        companyAccountsEmail: response.data.companyAccountsEmail || "",
      });
    } catch (error) {
      // console.log("Error in fetching company details", error);
      errorAlert("Error in fetching company details!!");
    }
  };

  //constants for pdf
  const link1Text = companyData.companyAccountsEmail;
  const link1URL = `mailto:${companyData.companyAccountsEmail}`;
  const link2Text = companyData.companyWebsite;
  const link2URL = companyData.companyWebsite;

  //   Filtered Matters
  const sortedMatters = matters
    .filter(
      (item) =>
        (item.pricePerHour?.$numberDecimal !== "0" ||
          item.resourceSpecificPrice?.length > 0) &&
        item.status === "enable"
    )
    .sort((a, b) => {
      const clientA = a.client.name.toLowerCase();
      const clientB = b.client.name.toLowerCase();

      if (clientA < clientB) {
        return -1; // a comes before b
      }
      if (clientA > clientB) {
        return 1; // a comes after b
      }
      return 0; // a and b are equal
    });

  // Filtered Resources
  const uniqueNames =
    employees.length > 0
      ? employees
          .filter((item) => !unBilledDesignations.includes(item.designation))
          .map((item) => item?.fullName)
      : [];

  const filteredNames = workHours
    ? [...new Set(workHours.map((item) => item.user?.fullName))]
    : [];

  const commonNames = uniqueNames.filter((name) =>
    filteredNames.includes(name)
  );

  //Calculation of allMatterTotalBillableAmount

  const adminId = auth.user._id;
  let allMatterTotalBillableAmount = 0;
  let allMatterTotalBilledAmount = 0;
  let teamTotalDiscount = 0;
  let totalHoursClocked = 0;

  if (startSelectedDate !== "" && commonNames !== "") {
    // console.log("CommonNAmes------", commonNames);
    sortedMatters.forEach((matter) => {
      let matterTotalBilledAmount = 0;
      let persontotalHours = 0;
      let teamTotalHours = 0;
      let teamTotalBilledHours = 0;
      let teamTotalBilledAmount = 0;
      let teamTotalBillableAmount = 0;

      if (commonNames) {
        commonNames.forEach((name) => {
          // console.log(name);
          let personWorkHours = 0;
          let personWriteoffHours = 0;

          workHours
            .filter(
              (result) =>
                result.user?.fullName === name &&
                result.role !== "OPE" &&
                result.matter &&
                result.matter?.name === matter?.name && // Add null check
                result.workDate >= startSelectedDate &&
                result.workDate <= endSelectedDate
            )
            .forEach((result) => {
              const workHour = parseFloat(result.workHour);
              personWorkHours += workHour;
            });

          if (writeOffHours) {
            writeOffHours
              .filter(
                (result) =>
                  result.user?.fullName === name &&
                  result.matter._id === matter._id
              )
              .forEach((result) => {
                const workHour = parseFloat(result.workHour);
                personWriteoffHours += workHour;
              });
          }

          persontotalHours =
            parseFloat(personWorkHours) + parseFloat(personWriteoffHours);

          const newMatter = matters.find(
            (matterItem) => matterItem?.name.trim() === matter?.name
          );

          let price;
          if (newMatter) {
            if (newMatter.pricePerHour) {
              price = newMatter.pricePerHour.$numberDecimal;
            } else if (newMatter.resourceSpecificPrice.length !== 0) {
              const employeeEntry = newMatter.resourceSpecificPrice.find(
                (entry) => entry.includes(name)
              );

              if (employeeEntry) {
                const [, hours] = employeeEntry.split(":");
                price = hours.trim();
              }
            } else {
              price = 0;
            }
          }

          // console.log("Price ---------", price, persontotalHours);

          if (price) {
            const personTotalBilledAmount =
              matter.currency === "USD"
                ? price * personWorkHours
                : (price * personWorkHours) / 80;
            const personTotalBillableAmount =
              matter.currency === "USD"
                ? price * (personWorkHours + personWriteoffHours)
                : (price * (personWorkHours + personWriteoffHours)) / 80;
            teamTotalHours += personWorkHours + personWriteoffHours;
            teamTotalBilledHours += personWorkHours;
            teamTotalBillableAmount += personTotalBillableAmount;
            teamTotalBilledAmount += personTotalBilledAmount;
          }
        });
      }

      allMatterTotalBillableAmount += teamTotalBillableAmount;
      allMatterTotalBilledAmount += teamTotalBilledAmount;
      teamTotalDiscount += teamTotalBillableAmount - teamTotalBilledAmount;
      totalHoursClocked += teamTotalHours;

      // console.log(
      //   matter?.name,
      //   teamTotalHours.toFixed(2),
      //   teamTotalBilledHours.toFixed(2),
      //   teamTotalBillableAmount.toFixed(2),
      //   teamTotalBilledAmount.toFixed(2),
      //   allMatterTotalBillableAmount.toFixed(2),
      //   allMatterTotalBilledAmount.toFixed(2)
      // );
    });
  }

  // filter function and Validation
  const isValidObjFieldForFilters = () => {
    if (startSelectedDate === "" || endSelectedDate === "") {
      return false;
    } else {
      return true;
    }
    // return Object.values(obj).every(value => value.trim())
  };

  const isValidFilters = () => {
    //all fields must be filled
    if (!isValidObjFieldForFilters()) return errorAlert("Enter the dates!!");

    return true;
  };

  function filterBetweenDates() {
    if (isValidFilters()) {
      setNeedFin(true);
      dispatch(
        filterTimeByUserIdForAdmin(
          selectedLawyer,
          startSelectedDate,
          endSelectedDate,
          matter,
          needFin,
          sendMail,
          adminId
        )
      );

      dispatch(
        fetchWriteoffsByAdminId(
          adminId,
          startSelectedDate,
          endSelectedDate,
          matter,
          selectedLawyer
        )
      );
      dispatch(getAllMatters(auth.user._id));
      dispatch(getAllEmployeesByAdminId());
      fetchAllInvoices();
      fetchAllLeaves();
      fetchAllOpexes();
      fetchAllReserves();
    }
  }

  //LEAVES
  const fetchAllLeaves = async () => {
    try {
      const response = await axiosInstance.get(`${API}/leaves`, {
        params: {
          adminId: auth.user._id,
          userId: selectedLawyer,
          startSelectedDate,
          endSelectedDate,
          limit: 0,
          page: 0,
        },
      });

      setLeaves(response.data.leavesWithDays);
      // console.log("Leaves data------", response.data);
    } catch (error) {
      errorAlert("Error fetching leaves. Try again...");
      // console.error("Error fetching leaves:", error);
    }
  };

  //   Leaves Calculations
  let totalDays = 0;
  let totalDaysForRPReport = 0;
  const currentDate = new Date(startSelectedDate);
  const endDate = new Date(endSelectedDate);

  while (currentDate <= endDate) {
    const dayOfWeek = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)
    totalDays++;
    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      totalDaysForRPReport++;
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }

  // console.log("totalDays", totalDays, totalDaysForRPReport);

  //OPEX
  const fetchAllOpexes = async () => {
    try {
      // console.log(startSelectedDate, endSelectedDate, expType, adminId);
      const response = await axiosInstance.get(`${API}/opex/filter`, {
        params: {
          startDate: startSelectedDate,
          endDate: endSelectedDate,
          expType,
          limit: 0,
          page: 0,
        },
      });

      setOpexes(response.data.opexes);
      // console.log("Opexes data------", response.data);
    } catch (error) {
      console.error("Error fetching opexes:", error);
    }
  };

  //INVOICES
  const fetchAllInvoices = async () => {
    try {
      const response = await axiosInstance.get(`${API}/invoices`);

      // Update state with fetched invoices
      setInvoices(response.data.invoices);
      // console.log("Invoices data------", invoices);
    } catch (error) {
      errorAlert("Error fetching Invoices. Try again...");
      // console.error("Error fetching invoices:", error.response.data);
    }
  };

  //RESERVES
  const fetchAllReserves = async () => {
    try {
      const response = await axiosInstance.get(
        `${API}/reserve/filter/${adminId}`,
        {
          params: {
            startSelectedDate,
            endSelectedDate,
            resType: "",
          },
        }
      );
      setReserves(response.data);
    } catch (error) {
      console.error("Error fetching Reserves:", error);
    }
  };

  const generateFinancialReport = () => {
    const doc = new jsPDF({
      orientation: "landscape",
    });

    const formattedStartDate = formatLetterDate(startSelectedDate);
    const formattedEndDate = formatLetterDate(endSelectedDate);

    const formattedMonthStartDate = formatMonthDate(startSelectedDate);
    const formattedMonthEndDate = formatMonthDate(endSelectedDate);

    const dateRangeLength =
      (297 - doc.getTextWidth(`${formattedStartDate} - ${formattedEndDate}`)) /
      2;

    //Header fixed for every new page
    const pageWidth = doc.internal.pageSize.getWidth();
    const rightMargin = 15; // You can adjust the right margin as needed
    const logoDesiredHeight = 20; // Set the desired height for the logo
    const logoYPosition = 10; // Set the Y position for the logo
    const logoXPosition = 13; // Set the X position for the logo

    function addHeader() {
      // Set the font style, size, and position of the header text
      const fontSize = 8;

      doc.setFont("helvetica", "normal");
      doc.setFontSize(fontSize);

      // Add the image to the header
      // doc.addImage(companyData.companyLogo, "PNG", 15, 10, 70, 9);
      doc.addImage(
        companyData.companyLogo,
        "PNG",
        logoXPosition,
        logoYPosition,
        0,
        logoDesiredHeight
      );

      // right header
      doc.setTextColor(46, 115, 176);

      const link2Width = doc.getTextWidth(link2Text);
      const link2X = pageWidth - link2Width - rightMargin;
      doc.textWithLink(link2Text, link2X, 19, {
        url: link2URL,
        underline: true,
      });

      // Set link color to blue and add underline
      const link1Width = doc.getTextWidth(link1Text);
      const link1X = pageWidth - link1Width - rightMargin;
      doc.textWithLink(link1Text, link1X, 22, {
        url: link1URL,
        underline: true,
      });

      doc.setTextColor(0);

      // Calculate text width and position to align it right
      const addressWidth = doc.getTextWidth(companyData.companyAddress);
      const addressX = pageWidth - addressWidth - rightMargin;
      doc.text(companyData.companyAddress, addressX, 26);

      // Reset text color to black
    }

    const FinancialReport = `/assets/FinancialReport.jpg`;

    doc.addImage(FinancialReport, "JPEG", 0, 0, 297, 210);
    addHeader();

    const fontSize = 20;
    doc.setFontSize(fontSize);

    // date range
    doc.setFont("helvetica", "italic");
    doc.setTextColor(0, 0, 0); // Black color
    doc.setFontSize(15);
    doc.text(
      `${formattedStartDate} - ${formattedEndDate}`,
      dateRangeLength,
      100
    );

    //horizontal line
    doc.setLineWidth(0.3);
    doc.setDrawColor(136, 106, 71);
    doc.line(90, 110, 200, 110);

    //--------------------2nd Page -- Billing Amounts---------------------------------
    doc.addPage();
    addHeader();

    doc.setFont("helvetica", "bold");
    doc.setTextColor(136, 106, 71);
    doc.setFontSize(15);
    doc.text(
      `Financial Report (${formattedMonthStartDate} - ${formattedMonthEndDate})`,
      (297 -
        doc.getTextWidth(
          `Billing Report (${formattedMonthStartDate} - ${formattedMonthEndDate})`
        )) /
        2,
      40
    );

    doc.setTextColor(0, 0, 0);
    doc.setFont("helvetica", "bold");
    doc.setFontSize(10);

    //Table Data
    const headerRow = [
      "Total Last 4 months billing",
      "Average last 4 monthly billing",
      "Total Last 4 weeks billing",
      "Average last 4 weekly billing",
      "Total Reserves",
      "Collections since April 2023",
      "Pending invoices",
      "Total billed since April 2023",
    ];

    const dataRow = [
      "INR " +
        parseFloat(totalAmountLast4Months)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      "INR " +
        parseFloat(averageAmountLast4Months)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      "INR " +
        parseFloat(totalAmountLast4Weeks)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      "INR " +
        parseFloat(averageAmountLast4Weeks)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      "INR" +
        parseFloat(
          reserves.reduce(
            (total, reserve) => parseFloat(reserve.purchasePrice) + total,
            0
          )
        )
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      "INR " +
        invoices
          .filter((invoice) => invoice.status === "collected")
          .reduce(
            (total, invoice) =>
              parseFloat(invoice.amount.$numberDecimal) + total,
            0
          )
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      "INR " +
        invoices
          .filter((invoice) => invoice.status === "pending")
          .reduce(
            (total, invoice) =>
              parseFloat(invoice.amount.$numberDecimal) + total,
            0
          )
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      "INR " +
        parseFloat(financialWorkHours)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    ];

    const financialTableColumns = ["Category", "Value"];
    const financialTableData = [];

    for (let i = 0; i < headerRow.length; i++) {
      const rowData = [headerRow[i], dataRow[i]];
      financialTableData.push(rowData);
    }

    doc.autoTable({
      body: financialTableData,
      columns: financialTableColumns,
      startY: 50,
      theme: "grid",
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.1,
      didParseCell: function (data) {
        if (data.row.index === 0 && data.row.section === "head") {
          data.cell.styles.fillColor = [136, 106, 71];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontStyle = "bold";
        }
        if (
          data.row.index === data.table.body.length - 1 &&
          data.row.section === "body"
        ) {
          data.cell.styles.fillColor = [97, 68, 58];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontStyle = "bold";
          // data.cell.styles.halign = "right";
        }
      },
    });

    //-------------------------3rd Page -- Matterwise Peformance Report---------------------

    doc.addPage();
    addHeader();

    doc.setFont("helvetica", "bold");
    doc.setTextColor(136, 106, 71);
    doc.setFontSize(15);
    doc.text(
      `Matterwise Performance Report (${formattedMonthStartDate} - ${formattedMonthEndDate})`,
      (297 -
        doc.getTextWidth(
          `Matterwise Performance Report (${formattedMonthStartDate} - ${formattedMonthEndDate})`
        )) /
        2,
      40
    );
    doc.setTextColor(0, 0, 0);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);

    //Table Data 1
    const matterTableColumns = [
      "Client",
      "Matter",
      "Actual Time Clocked",
      "Time Billed",
      "Discount",
      "Discount %",
      "Contribution To Total",
      "Hours Clocked",
      "ERPH",
    ];
    const matterTableData = [];

    if (sortedMatters.length > 0) {
      // console.log(sortedMatters);
      sortedMatters.forEach((matter, index) => {
        let currency;

        if (matter) {
          currency = matter.currency === "USD" ? "$" : "Rs. ";
          // console.log("Currency: ", currency);
          // Use the currency value as needed
        } else {
          currency = "";
          // console.log("Matter not found.");
        }

        let matterTotalBilledAmount = 0;
        let persontotalHours = 0;
        let teamTotalHours = 0;
        let teamTotalBilledHours = 0;
        let teamTotalBilledAmount = 0;
        let teamTotalBillableAmount = 0;

        commonNames.forEach((name) => {
          let personWorkHours = 0;
          let personWriteoffHours = 0;

          workHours
            .filter(
              (result) =>
                result.user?.fullName === name &&
                result.role !== "OPE" &&
                result.matter?.name === matter?.name &&
                result.workDate >= startSelectedDate && // Add start date filter
                result.workDate <= endSelectedDate // Add end date filter
            )
            .forEach((result) => {
              const workHour = parseFloat(result.workHour);
              personWorkHours += workHour;
            });

          if (writeOffHours) {
            writeOffHours
              .filter(
                (result) =>
                  result.user?.fullName === name &&
                  result.matter._id === matter._id
                // &&
                // result.workDate >= startSelectedDate &&
                // result.workDate <= endSelectedDate
              )
              .forEach((result) => {
                const workHour = parseFloat(result.workHour);
                personWriteoffHours += workHour;
              });
          }

          persontotalHours =
            parseFloat(personWorkHours) + parseFloat(personWriteoffHours);

          const newMatter = matters.find(
            (matterItem) => matterItem?.name.trim() === matter?.name.trim()
          );

          let price;
          if (newMatter) {
            if (newMatter.pricePerHour) {
              price = newMatter.pricePerHour.$numberDecimal;
            } else if (newMatter.resourceSpecificPrice.length !== 0) {
              const employeeEntry = newMatter.resourceSpecificPrice.find(
                (entry) => entry.includes(name)
              );

              if (employeeEntry) {
                const [, hours] = employeeEntry.split(":");
                price = hours.trim();
              }
            } else {
              price = 0;
            }
          }

          // console.log("Price ---------", price, persontotalHours);

          if (price) {
            const personTotalBilledAmount =
              matter.currency === "USD"
                ? price * personWorkHours
                : (price * personWorkHours) / 80;
            const personTotalBillableAmount =
              matter.currency === "USD"
                ? price * (personWorkHours + personWriteoffHours)
                : (price * (personWorkHours + personWriteoffHours)) / 80;
            teamTotalHours += persontotalHours;
            teamTotalBilledHours += personWorkHours;
            teamTotalBillableAmount += personTotalBillableAmount;
            teamTotalBilledAmount += personTotalBilledAmount;
          }
        });

        const matterTableDataArray = [
          matter?.client?.name,
          matter?.name,
          teamTotalBillableAmount
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          teamTotalBilledAmount
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          (teamTotalBillableAmount - teamTotalBilledAmount)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          ((
            ((teamTotalBillableAmount - teamTotalBilledAmount) /
              teamTotalBillableAmount) *
            100
          ).toFixed(1) !== "NaN"
            ? (
                ((teamTotalBillableAmount - teamTotalBilledAmount) /
                  teamTotalBillableAmount) *
                100
              ).toFixed(1)
            : (0).toFixed(1)) + "%",
          ((teamTotalBilledAmount * 100) / allMatterTotalBilledAmount).toFixed(
            2
          ) + "%",
          teamTotalHours.toFixed(2),
          (teamTotalBilledAmount / teamTotalHours).toFixed(2) !== "NaN"
            ? (teamTotalBilledAmount / teamTotalHours).toFixed(2)
            : "0.00",
        ];

        matterTableData.push(matterTableDataArray);
      });
    }

    const matterFooterRow = [
      {
        content: "Total Amount  (billed in USD)",
        colSpan: 2,
        styles: {
          fillColor: [97, 68, 58],
          textColor: [255, 255, 255],
          fontStyle: "bold",
        },
      },
      {
        content: allMatterTotalBillableAmount
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        // colSpan: 3,
        styles: {
          fillColor: [97, 68, 58],
          textColor: [255, 255, 255],
          fontStyle: "bold",
        },
      },
      {
        content: allMatterTotalBilledAmount
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        // colSpan: 3,
        styles: {
          fillColor: [97, 68, 58],
          textColor: [255, 255, 255],
          fontStyle: "bold",
        },
      },
    ];

    const matterFooterRow1 = [
      {
        content: "Total Amount (billed in INR)",
        colSpan: 2,
        styles: {
          fillColor: [97, 68, 58],
          textColor: [255, 255, 255],
          fontStyle: "bold",
        },
      },
      (allMatterTotalBillableAmount * 80)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      (allMatterTotalBilledAmount * 80)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      teamTotalDiscount
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      "",
      "",
      totalHoursClocked.toFixed(2),
    ];

    matterTableData.push(matterFooterRow);
    matterTableData.push(matterFooterRow1);

    doc.autoTable(matterTableColumns, matterTableData, {
      startY: 50,
      theme: "grid", // Set the theme to 'grid' to display internal lines
      tableLineColor: [0, 0, 0], // Color of the table lines (black in this example)
      tableLineWidth: 0.1, // Width of the table lines in units (default is 0.1)
      didParseCell: function (data) {
        if (data.row.index === 0 && data.row.section === "head") {
          data.cell.styles.fillColor = [136, 106, 71];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
        }
        if (
          data.row.index === data.table.body.length - 1 &&
          data.row.section === "body"
        ) {
          data.cell.styles.fillColor = [97, 68, 58];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontStyle = "bold";
        }
        if (data.row.section === "body") {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
        }
        if (
          data.row.section === "body" &&
          data.column.index !== 0 &&
          data.column.index !== 1
        ) {
          data.cell.styles.halign = "right";
          data.cell.styles.cellWidth = 27;
        }
        if (data.column.index === 1 && data.column.index === 0) {
          data.cell.styles.cellWidth = 80;
        }
      },
    });

    // Table Data 2 - Comparison Table

    //-------------------------4th Page -- Resourcewise Performance Report--------------------
    doc.addPage();
    addHeader();

    doc.setFont("helvetica", "bold");
    doc.setTextColor(136, 106, 71);
    doc.setFontSize(15);
    doc.text(
      `Efficiency and Utilization Report (${formattedMonthStartDate} - ${formattedMonthEndDate})`,
      (297 -
        doc.getTextWidth(
          `Efficiency and Utilization Report (${formattedMonthStartDate} - ${formattedMonthEndDate})`
        )) /
        2,
      40
    );
    doc.setTextColor(0, 0, 0);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);

    const resourceTableColumns = [
      "Resource",
      "No. of days worked",
      "Avail Billable Hours",
      "Billable Hours Utilized",
      "Actual Billed",
      "Discounting",
      "Avg Billable hrs/day",
      "Avg Billed hrs/day",
      "Avail Non-Billable Hours",
      "Non-Billable Hours Utilized",
      "Missed Billing Potential(7 hrs/day)",
    ];

    const resourceTableData = [];

    let totalBillableHours = 0;
    let totalBilledTime = 0;
    let totalNonBilledTime = 0;
    let totalDiscount = 0;
    const sortedData = [];
    let leaveDays;
    let finalDays;

    if (commonNames.length > 0) {
      commonNames.forEach((name, index) => {
        const personWorkHours =
          workHours &&
          workHours.filter((result) => result.user?.fullName === name);

        //personWriteoffHours
        const personWriteoffEntries = writeOffHours.filter(
          (result) =>
            result.user?.fullName === name &&
            result.workDate >= startSelectedDate &&
            result.workDate <= endSelectedDate
        );

        const personWriteoffHours =
          personWriteoffEntries.reduce(
            (total, result) => total + parseFloat(result.workHour),
            0
          ) || 0.0;

        //personBillableTime
        const billableEntries = personWorkHours.filter(
          (item) =>
            item.matter?.pricePerHour?.$numberDecimal !== "0" ||
            item.matter?.resourceSpecificPrice?.length > 0
        );

        const personBillableTime =
          billableEntries.reduce(
            (total, item) => total + parseFloat(item.workHour),
            0
          ) || 0.0;

        //personNonBillableTime
        const nonBillableEntries = personWorkHours.filter(
          (item) => item.matter?.pricePerHour?.$numberDecimal === "0"
        );

        const personNonBillableTime =
          nonBillableEntries.reduce(
            (total, item) => total + parseFloat(item.workHour),
            0
          ) || 0.0;

        //days calcs
        leaveDays = leaves
          .filter(
            (leave) =>
              leave.user.fullName === name &&
              leave.leaveStartDate >= startSelectedDate &&
              leave.leaveEndDate <= endSelectedDate
          )
          .reduce((total, item) => (total += parseFloat(item.leaveDays)), 0);

        // console.log("Leave Days", name, leaveDays);

        const holidaysWithinLeavePeriod = holidays.filter((holiday) => {
          return holiday >= startSelectedDate && holiday <= endSelectedDate;
        });

        const holidayDays = holidaysWithinLeavePeriod.length;
        const totalLeaveDaysWithHolidays = leaveDays + holidayDays;

        finalDays = totalDaysForRPReport - totalLeaveDaysWithHolidays;

        const tableDataArray = [
          name,
          finalDays,
          7 * finalDays,
          parseFloat(personBillableTime + personWriteoffHours).toFixed(1),
          parseFloat(personBillableTime).toFixed(1),
          ((
            (1 -
              personBillableTime / (personBillableTime + personWriteoffHours)) *
            100
          ).toFixed(1) !== "NaN"
            ? (
                (1 -
                  personBillableTime /
                    (personBillableTime + personWriteoffHours)) *
                100
              ).toFixed(1)
            : 0.0) + "%",
          parseFloat(
            (personBillableTime + personWriteoffHours) / finalDays
          ).toFixed(1),
          parseFloat(personBillableTime / finalDays).toFixed(1),
          2 * finalDays,
          personNonBillableTime.toFixed(1),
          parseFloat((1 - personBillableTime / (7 * finalDays)) * 100).toFixed(
            1
          ) + "%",
        ];

        totalBillableHours += parseFloat(
          personBillableTime + personWriteoffHours
        );

        totalBilledTime += parseFloat(personBillableTime);
        totalNonBilledTime += parseFloat(personNonBillableTime.toFixed(1));

        const rowData = {
          data: tableDataArray,
          value: parseFloat(
            (personBillableTime + personWriteoffHours) / finalDays
          ).toFixed(1),
        };

        sortedData.push(rowData);
      });
    }

    sortedData.sort((a, b) => b.value - a.value);

    sortedData.forEach((rowData) => {
      resourceTableData.push(rowData.data);
    });

    const resourceFooterRow = [
      "Total Hours",
      "",
      commonNames.length * 7 * finalDays,
      totalBillableHours.toFixed(1),
      totalBilledTime.toFixed(1),
      parseFloat(
        ((1 - totalBilledTime / totalBillableHours) * 100).toFixed(1)
      ) + "%",
      "",
      "",
      commonNames.length * 2 * finalDays,
      totalNonBilledTime.toFixed(1),
      "",
    ];

    resourceTableData.push(resourceFooterRow);

    //Efficiency and Utilization Table
    doc.autoTable(resourceTableColumns, resourceTableData, {
      startY: 50,
      theme: "grid", // Set the theme to 'grid' to display internal lines
      tableLineColor: [0, 0, 0], // Color of the table lines (black in this example)
      tableLineWidth: 0.1, // Width of the table lines in units (default is 0.1)
      didParseCell: function (data) {
        if (data.row.index === 0 && data.row.section === "head") {
          data.cell.styles.fillColor = [136, 106, 71];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
        }
        if (
          data.row.index === data.table.body.length - 1 &&
          data.row.section === "body"
        ) {
          data.cell.styles.fillColor = [97, 68, 58];
          data.cell.styles.textColor = [255, 255, 255];
        }
        if (data.row.section === "body") {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
          data.cell.styles.halign = "right";
          data.cell.styles.cellWidth = 23.5; // Set the width to 50 units
        }
        if (data.column.index === 0) {
          data.cell.styles.halign = "left";
          data.cell.styles.cellWidth = 33.7; // Set the width to 50 units
        }
      },
    });

    //----------------------5th Page -- Compensation to revenue multiples----------------------
    // doc.addPage();
    // addHeader();

    // doc.setFont("helvetica", "bold");
    // doc.setTextColor(136, 106, 71);
    // doc.setFontSize(15);
    // doc.text(
    //   `Compensation To Revenue Multiples (${formattedMonthStartDate} - ${formattedMonthEndDate})`,
    //   (297 -
    //     doc.getTextWidth(
    //       `Compensation To Revenue Multiples (${formattedMonthStartDate} - ${formattedMonthEndDate})`
    //     )) /
    //     2,
    //   40
    // );
    // doc.setTextColor(0, 0, 0);
    // doc.setFont("helvetica", "normal");
    // doc.setFontSize(10);

    //Table

    //----------------------6th Page -- Resourcewise revenue report----------------------
    doc.addPage();
    addHeader();

    doc.setFont("helvetica", "bold");
    doc.setTextColor(136, 106, 71);
    doc.setFontSize(15);
    doc.text(
      `Resourcewise Revenue Report (${formattedMonthStartDate} - ${formattedMonthEndDate})`,
      (297 -
        doc.getTextWidth(
          `Resourcewise Revenue Report (${formattedMonthStartDate} - ${formattedMonthEndDate})`
        )) /
        2,
      40
    );
    doc.setTextColor(0, 0, 0);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);

    //RESOURCE REVENUE REPORT DATA

    const revenueTableColumns = [
      // "Period / Day",
      "Resource Name",
      "Matter Names",
      "Billed Hours",
      "Total Amount",
      "Total Amount (All Matters)",
      "Compensation",
      "Multiple",
    ];

    const revenueTableData = [];

    const revenueSortedData = commonNames.map((name, index) => {
      const personWorkHours = workHours.filter(
        (item) =>
          item.user?.fullName === name &&
          item.role !== "OPE" &&
          item.workDate >= startSelectedDate &&
          item.workDate <= endSelectedDate
      );

      const billableEntries = personWorkHours.filter(
        (item) =>
          item.matter?.pricePerHour?.$numberDecimal !== "0" ||
          item.matter?.resourceSpecificPrice?.length > 0
      );
      // console.log("billableHours", name, billableEntries);

      const matterNames = [
        ...new Set(
          billableEntries
            .filter((entry) => entry.matter?.name)
            .map((entry) => entry.matter.name)
        ),
      ];

      const employeeData = employees.find(
        (employee) => employee.fullName === name
      );
      const compAmount = employeeData.compensation;
      const compAmountPerDay = compAmount / 31;
      // console.log("employeeData", compAmountPerDay);

      // console.log("MatterNames", name, matterNames);

      const matterTimeAndAmounts = matterNames.map((matterName) => {
        const filteredEntries = billableEntries.filter(
          (entry) => entry.matter?.name === matterName
        );

        const totalTime = filteredEntries.reduce(
          (total, entry) => total + parseFloat(entry.workHour),
          0
        );

        const totalAmount = filteredEntries.reduce((total, entry) => {
          let price;

          if (entry.matter.pricePerHour) {
            price = parseFloat(entry.matter.pricePerHour.$numberDecimal);
          } else if (entry.matter.resourceSpecificPrice) {
            const resourcePriceEntry = entry.matter.resourceSpecificPrice.find(
              (data) => {
                const [resourceName, resourcePrice] = data
                  .split(":")
                  .map((item) => item.trim());
                return resourceName === name;
              }
            );
            if (resourcePriceEntry) {
              const [, resourcePrice] = resourcePriceEntry
                .split(":")
                .map((item) => item.trim());
              price = parseFloat(resourcePrice);
            }
          }

          if (typeof price === "number") {
            total +=
              price *
              entry.workHour *
              (entry.matter.currency === "USD" ? 80 : 1);
          }

          return total;
        }, 0);

        return { totalAmount, totalTime, matterName }; // Return an object containing both values
      });

      // console.log(
      //   startSelectedDate,
      //   endSelectedDate,
      //   name,
      //   matterNames,
      //   matterTimeAndAmounts
      // );

      return {
        startSelectedDate,
        endSelectedDate,
        name,
        matterNames,
        matterTimeAndAmounts,
        compAmount,
        compAmountPerDay,
      };
    });

    // Sort the data based on the total amount of all matters
    revenueSortedData.sort((a, b) => {
      const totalAmountA = a.matterTimeAndAmounts.reduce(
        (total, entry) => total + entry.totalAmount,
        0
      );
      const totalAmountB = b.matterTimeAndAmounts.reduce(
        (total, entry) => total + entry.totalAmount,
        0
      );
      return totalAmountB - totalAmountA;
    });

    revenueSortedData.forEach((data) => {
      let isFirstRowForEmployee = true;
      let totalAmountForEmployee = 0;

      data.matterTimeAndAmounts.forEach((entry) => {
        if (isFirstRowForEmployee) {
          revenueTableData.push([data.name, "", "", "", ""]);
          isFirstRowForEmployee = false;
        }

        revenueTableData.push([
          "",
          entry.matterName,
          entry.totalTime.toFixed(2),
          "INR " +
            entry.totalAmount
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          "",
        ]);

        totalAmountForEmployee += entry.totalAmount;
      });

      // Add summary row for the employee's total amount
      revenueTableData.push([
        {
          content: "Total Amount for " + data.name,
          styles: { fillColor: [207, 207, 207] },
          colSpan: 4,
        },
        {
          content: `INR ${totalAmountForEmployee
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
          styles: { fillColor: [207, 207, 207] },
        },
        {
          content: `INR ${parseFloat(
            data.compAmountPerDay ? data.compAmountPerDay * totalDays : 0
          )
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
          styles: { fillColor: [207, 207, 207] },
        },
        {
          content: `${(
            Math.round(
              parseFloat(
                totalAmountForEmployee / (data.compAmountPerDay * totalDays)
              ) * 10
            ) / 10
          )
            .toFixed(1)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}X`,
          styles: { fillColor: [207, 207, 207] },
        },
      ]);
    });

    // Calculate the total amount across all employees
    const totalAmountAllEmployees = revenueSortedData.reduce((total, data) => {
      data.matterTimeAndAmounts.forEach((entry) => {
        total += entry.totalAmount;
      });
      return total;
    }, 0);

    // Add summary row for the total amount across all employees
    revenueTableData.push([
      {
        content: "Total Amount (All Employees)",
        styles: { fillColor: [207, 207, 207] },
        colSpan: 4,
      },
      {
        content:
          "INR " +
          totalAmountAllEmployees
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        styles: { fillColor: [207, 207, 207] },
      },
    ]);

    doc.autoTable(revenueTableColumns, revenueTableData, {
      startY: 50,
      theme: "grid", // Set the theme to 'grid' to display internal lines
      tableLineColor: [0, 0, 0], // Color of the table lines (black in this example)
      tableLineWidth: 0.1, // Width of the table lines in units (default is 0.1)
      didParseCell: function (data) {
        if (data.row.index === 0 && data.row.section === "head") {
          data.cell.styles.fillColor = [136, 106, 71];
          data.cell.styles.textColor = [255, 255, 255];
        }
        if (
          data.row.index === data.table.body.length - 1 &&
          data.row.section === "body"
        ) {
          data.cell.styles.fillColor = [97, 68, 58];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontStyle = "bold";
        }
      },
    });

    //-------------------------6th Page -- OPEX report----------------------------------
    doc.addPage();
    addHeader();

    doc.setFont("helvetica", "bold");
    doc.setTextColor(136, 106, 71);
    doc.setFontSize(15);
    doc.text(
      `OPEX Report (${formattedMonthStartDate} - ${formattedMonthEndDate})`,
      (297 -
        doc.getTextWidth(
          `OPEX Report (${formattedMonthStartDate} - ${formattedMonthEndDate})`
        )) /
        2,
      40
    );
    doc.setTextColor(0, 0, 0);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);

    //OPEX Table
    const opexTableColumns = ["Date", "Amount", "Description", "Expense Type"];
    const opexTableData = [];

    if (opexes.length > 0) {
      opexes.forEach((opex, index) => {
        const opexRowData = [
          formatMonthDate(opex.date),
          "INR " +
            parseFloat(opex.amount)
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          opex.opexDescription,
          opex.expenseType,
        ];
        opexTableData.push(opexRowData);
      });
    }

    const footerRow = [
      "Total",
      "INR " +
        opexes
          .reduce((total, opex) => parseFloat(opex.amount) + total, 0)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    ];

    opexTableData.push(footerRow);

    doc.autoTable(opexTableColumns, opexTableData, {
      startY: 50,
      theme: "grid", // Set the theme to 'grid' to display internal lines
      tableLineColor: [0, 0, 0], // Color of the table lines (black in this example)
      tableLineWidth: 0.1, // Width of the table lines in units (default is 0.1)
      didParseCell: function (data) {
        if (data.row.index === 0 && data.row.section === "head") {
          data.cell.styles.fillColor = [136, 106, 71];
          data.cell.styles.textColor = [255, 255, 255];
        }
        if (
          data.row.index === data.table.body.length - 1 &&
          data.row.section === "body"
        ) {
          data.cell.styles.fillColor = [97, 68, 58];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontStyle = "bold";
        }
        if (data.row.section === "body" && data.column.index === 1) {
          data.cell.styles.halign = "right";
          data.cell.styles.cellWidth = 35; // Set the width to 50 units
        }
      },
    });

    //-----------------------7th Page -- Invoices Tracker complete with reminders--------------
    doc.addPage();
    addHeader();

    //COMMON IN BOTH PENDING AND COLLECTED INVOICES

    //COLLECTED INVOICES DATA
    const invoiceCollectedTableColumns = [
      "Raised Date",
      "Invoice No.",
      "Invoice Period",
      "Matter",
      "Collection Date",
      "Amount",
    ];

    doc.setFont("helvetica", "bold");
    doc.setTextColor(136, 106, 71);
    doc.setFontSize(15);
    doc.text(
      `Collections in this period (${formattedMonthStartDate} - ${formattedMonthEndDate})`,
      (297 -
        doc.getTextWidth(
          `Collections in this period (${formattedMonthStartDate} - ${formattedMonthEndDate})`
        )) /
        2,
      40
    );

    const invoiceCollectedTableData = [];

    if (invoices.length > 0) {
      invoices
        .filter((invoice) => invoice.status === "collected")
        .filter(
          (invoice) =>
            invoice.collectionDate >= startSelectedDate &&
            invoice.collectionDate <= endSelectedDate
        )
        .forEach((invoice) => {
          const invoiceRowData = [
            formatMonthDate(invoice.invoiceDate),
            invoice.invoiceNumber,
            `${formatMonthDate(invoice.startDate)} - ${formatMonthDate(
              invoice.endDate
            )}`,
            invoice.matter.name,
            formatMonthDate(invoice.collectionDate),
            "INR " +
              parseFloat(invoice.amount.$numberDecimal)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          ];
          invoiceCollectedTableData.push(invoiceRowData);
        });
    }

    const invoiceCollectedFooterRow = [
      {
        content: "Total Collected Amount",
        colSpan: 5,
      },
      "INR " +
        invoices
          .filter((invoice) => invoice.status === "collected")
          .filter(
            (invoice) =>
              invoice.collectionDate >= startSelectedDate &&
              invoice.collectionDate <= endSelectedDate
          )
          .reduce(
            (total, invoice) =>
              parseFloat(invoice.amount.$numberDecimal) + total,
            0
          )
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    ];

    invoiceCollectedTableData.push(invoiceCollectedFooterRow);

    doc.autoTable(invoiceCollectedTableColumns, invoiceCollectedTableData, {
      startY: 50,
      theme: "grid", // Set the theme to 'grid' to display internal lines
      tableLineColor: [0, 0, 0], // Color of the table lines (black in this example)
      tableLineWidth: 0.1, // Width of the table lines in units (default is 0.1)
      didParseCell: function (data) {
        if (data.row.index === 0 && data.row.section === "head") {
          data.cell.styles.fillColor = [136, 106, 71];
          data.cell.styles.textColor = [255, 255, 255];
        }
        if (
          data.row.index === data.table.body.length - 1 &&
          data.row.section === "body"
        ) {
          data.cell.styles.fillColor = [97, 68, 58];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontStyle = "bold";
        }
        if (data.row.section === "body" && data.column.index === 5) {
          data.cell.styles.halign = "right";
          data.cell.styles.cellWidth = 35; // Set the width to 50 units
        }
      },
    });

    //PENDING INVOICES DATA
    doc.text(
      `Pending Invoices Tracker (${formattedMonthStartDate} - ${formattedMonthEndDate})`,
      (297 -
        doc.getTextWidth(
          `Invoices Tracker (${formattedMonthStartDate} - ${formattedMonthEndDate})`
        )) /
        2,
      doc.lastAutoTable.finalY + 20
    );

    doc.setTextColor(0, 0, 0);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);

    const invoicePendingTableData = [];
    const invoicePendingTableColumns = [
      "Raised Date",
      "Invoice No.",
      "Invoice Period",
      "Matter",
      "Amount (INR)",
    ];

    if (invoices.length > 0) {
      invoices
        .filter((invoice) => invoice.status === "pending")
        .filter(
          (invoice) =>
            invoice.invoiceDate >= startSelectedDate &&
            invoice.invoiceDate <= endSelectedDate
        )
        .forEach((invoice) => {
          const invoiceRowData = [
            formatMonthDate(invoice.invoiceDate),
            invoice.invoiceNumber,
            `${formatMonthDate(invoice.startDate)} - ${formatMonthDate(
              invoice.endDate
            )}`,
            invoice.matter.name,
            parseFloat(invoice.amount.$numberDecimal)
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          ];
          invoicePendingTableData.push(invoiceRowData);
        });
    }

    const invoicePendingFooterRow = [
      {
        content: "Total Pending Amount",
        colSpan: 4,
      },
      invoices
        .filter((invoice) => invoice.status === "pending")
        .reduce(
          (total, invoice) =>
            parseFloat(
              // invoice.matter.currency === "USD"
              //   ? invoice.amount.$numberDecimal * 80
              //   :
              invoice.amount.$numberDecimal
            ) + total,
          0
        )
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    ];

    invoicePendingTableData.push(invoicePendingFooterRow);

    const startY1 = doc.lastAutoTable.finalY + 30;

    doc.autoTable(invoicePendingTableColumns, invoicePendingTableData, {
      startY: startY1,
      theme: "grid", // Set the theme to 'grid' to display internal lines
      tableLineColor: [0, 0, 0], // Color of the table lines (black in this example)
      tableLineWidth: 0.1, // Width of the table lines in units (default is 0.1)
      didParseCell: function (data) {
        if (data.row.index === 0 && data.row.section === "head") {
          data.cell.styles.fillColor = [136, 106, 71];
          data.cell.styles.textColor = [255, 255, 255];
        }
        if (
          data.row.index === data.table.body.length - 1 &&
          data.row.section === "body"
        ) {
          data.cell.styles.fillColor = [97, 68, 58];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontStyle = "bold";
        }
        if (data.row.section === "body" && data.column.index === 4) {
          data.cell.styles.halign = "right";
          data.cell.styles.cellWidth = 35; // Set the width to 50 units
        }
      },
    });

    //END TABLES

    doc.save(
      `Financial_Report ${formattedMonthStartDate} - ${formattedMonthEndDate}.pdf`
    );
  };

  // states and functions for date range picker
  const [selectedDateRange, setSelectedDateRange] = useState({
    start: "",
    end: "",
  });

  // Function to receive selected dates from DateRangePickerTst
  const handleDateRangeChange = (startDate, endDate) => {
    setSelectedDateRange({ start: startDate, end: endDate });
    setStartSelectedDate(startDate);
    setEndSelectedDate(endDate);
    console.log(startDate, endDate);
  };

  return (
    <>
      <ToastContainer />
      <div>
        {/* Search Bar  */}
        <Paper elevation={0} className="rounded-full">
          <Grid container spacing={1} alignItems="center" marginBottom="1rem">
            {/* Main Heading  */}
            <Grid item container spacing={1} xs={12} sm={7} md={2}>
              <Grid item>
                <Typography variant="h5">Financial Report</Typography>
              </Grid>
            </Grid>

            {/* Date Range Picker */}
            <Grid
              item
              xs={2}
              sm={2}
              md={2}
              display="flex"
              alignItems="center"
              gap={1}
            >
              <CustomTooltip text={"Select Dates"}>
                <CustomDateRangePicker
                  startDate={selectedDateRange.start}
                  endDate={selectedDateRange.end}
                  onDateRangeChange={handleDateRangeChange}
                  iconSize={30}
                />
              </CustomTooltip>

              <Typography>
                {formatMonthDate(startSelectedDate)} -{" "}
                {formatMonthDate(endSelectedDate)}
              </Typography>
            </Grid>

            {/* Search Icon Button */}
            <Grid item xs={4} sm={4} md={0.4}>
              <CustomTooltip text="Search workhours">
                <IconButton
                  onClick={filterBetweenDates}
                  className=" text-black hover:bg-gray-800"
                  style={{
                    padding: 5, // Adjust padding as needed
                    // color: "white",
                    // backgroundColor: "black",
                    borderRadius: "50%", // Full rounded for circular button
                  }}
                >
                  <Search style={{ fontSize: "20px" }} />{" "}
                  {/* Custom icon size */}
                </IconButton>
              </CustomTooltip>
            </Grid>

            {/* Download Icon Button */}
            <Grid item xs={4} sm={4} md={0.4}>
              <CustomTooltip text="Download Report">
                <IconButton
                  onClick={generateFinancialReport}
                  className=" text-black hover:bg-gray-800"
                  style={{
                    padding: 5,
                    // color: "white",
                    // backgroundColor: "black",
                    borderRadius: "50%",
                  }}
                >
                  <Download style={{ fontSize: "20px" }} />
                </IconButton>
              </CustomTooltip>
            </Grid>
          </Grid>
        </Paper>

        <div
          style={{
            textAlign: "center",
            margin: "1rem",
            fontSize: "30px",
            fontFamily: "sans-serif",
            fontWeight: "bold",
          }}
        >
          {workHours && workHours.length > 0 && (
            <Typography variant="h5" align="center">
              {filterLoading ? "Generating...." : "You can download now"}
            </Typography>
          )}
        </div>
      </div>
    </>
  );
};

export default Financial;
